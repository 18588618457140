const en = {
  LOGO: 'Dascue',
  NAVBAR: {
    DASHBOARD: 'Dashboard',
    HISTORY: 'History',
    PROFILE: 'Profile',
    EMAIL_TOOLTIP: 'Contact Customer Support at ',
  },
  DASHBOARD: {
    MOBILE_TITLE: 'Dascue',
    NOTIFICATION: {
      SERVICE_NOT_AVAILABLE: 'Sorry, our service is currently not available in',
    },
    CARD: {
      BLUE: {
        TITLE: 'Open Credit Line',
        SUBTITLE: 'Verification process is not started. Complete it to open your credit line.',
        BUTTON: 'Start now',
      },
      PURPLE: {
        TITLE: 'Change requested amount',
        SUBTITLE: 'Verification process is not completed. Complete it to be able to open your credit line.',
        BUTTON_LEFT: 'Credit',
        BUTTON_RIGHT: 'Change amount',
      },
      GREEN: {
        TITLE: 'Transfer funds',
        SUBTITLE: 'Your credit line is now ready. Click to start money transfers.',
        BUTTON_LEFT: 'Credit',
        BUTTON_RIGHT: 'Open credit line',
      },
      ORANGE: {
        TITLE: 'Resubmit data',
        SUBTITLE: 'Verification failed. Please re-submit data to get your credit line opened.',
        BUTTON: 'Resubmit',
      },
      ORANGE_NOT_CONFIRM_BTC: {
        TITLE: 'Your loan application is not finished',
        SUBTITLE_1:
          'Please transfer your collateral and select a bank account to receive your loan. Collateral needed: ',
        SUBTITLE_2: ' BTC.',
        BUTTON: 'Transfer collateral',
      },
      ORANGE_NOT_CONFIRM_BANK: {
        TITLE: 'Your loan application is not finished',
        SUBTITLE: 'Please select a bank account where you wish to receive your loan.',
        BUTTON: 'Select bank account',
      },
      ORANGE_NOT_ENOUGH: {
        TITLE: 'Not enough BTC collateral transferred',
        SUBTITLE_1: 'Please transfer at least ',
        SUBTITLE_2: ' BTC to satisfy the agreement or consider borrowing the amount available. You deposited: ',
        BUTTON: 'Deposit collateral',
      },
      RED: {
        TITLE: 'Service is unavailable',
        SUBTITLE: 'Sorry, we cannot open a credit line for you.',
      },
      RED_LTV_HIGH: {
        TITLE: 'High Loan to value ',
        SUBTITLE:
          'Please deposit additional collateral to lower your BTC liquidation price and ensure that your collateral is not liquidated to cover your loan position.',
        BUTTON: 'Deposit collateral',
      },
    },
    CREDIT_GRID: {
      AVAILABLE_CREDIT: 'Available credit',
      LIQUIDATION_PRICE: 'Liquidation price',
      BORROWED: 'Borrowed',
      COLLATERAL: 'Collateral',
      MAY_INTEREST_FEE: 'Interest rate',
      AVAILABLE_COLLATERAL: 'Available',
      BUTTONS: {
        BORROW: 'Borrow',
        BORROW_MORE: 'Borrow more',
        REPAY: 'Repay',
        DEPOSIT: 'Deposit',
        WITHDRAW: 'Withdraw',
        PAY_INTEREST: 'Pay interest',
      },
    },
  },
  VERIFICATION_BLOCKS: {
    TITLE_GREY: 'Let’s start!',
    TITLE_BLACK: 'Verification process',
    SUBTITLE: 'You need to complete every step to open your credit line.',
    DENIED_NOTIFICATION: 'Sorry, we cannot open a credit line for you.',
    DATA_ACCESS_NOTIFICATION: 'You must setup Two-factor authentication to get access to view your personal data.',
    IDENTITY: {
      TITLE: 'Identity verification',
      SUBTITLE: {
        NOTSTARTED: 'Add identity information and document',
        PENDING: 'Identity verification in process. It can take up to 24 hours to finish', // TODO: Identity verification completed. May take up to 5 minutes.
        FAILED: 'Identity verification failed. Please try again',
        DENIED: 'Identity verification denied. You can’t try again',
        SUCCESS: 'Your identity is verified',
        UNKNOWN: 'Please contact the support',
        UNAVAILABLE: '',
      },
      BUTTON: {
        NOTSTARTED: 'Start process',
        PENDING: '',
        FAILED: 'Try again',
        DENIED: '',
        SUCCESS: '',
      },
    },
    ADDRESS: {
      TITLE: 'Address verification',
      SUBTITLE: {
        NOTSTARTED: 'Add address information and document',
        PENDING: 'Address verification in process. It can take up to 24 hours to finish', // TODO: Address verification completed. May take up to 5 minutes.
        FAILED: 'Address verification failed. Please try again',
        DENIED: 'Address verification denied. You can’t try again',
        SUCCESS: 'Your address is verified',
        UNKNOWN: 'Please contact the support',
        UNAVAILABLE: '',
      },
      BUTTON: {
        NOTSTARTED: 'Start process',
        PENDING: '',
        FAILED: 'Try again',
        DENIED: '',
        SUCCESS: '',
      },
    },
    BANK: {
      TITLE: 'Add bank account',
      SUBTITLE: {
        NOTSTARTED: 'Add your bank account to receive your loan',
        PENDING:
          'Bank account verification in process. It is usually completed within 1 day, but it may take up to 3 days.',
        FAILED: 'Adding bank account failed. Please try again',
        DENIED: 'Adding bank account denied. You can’t try again',
        SUCCESS: 'Bank account is confirmed',
        UNKNOWN: 'Please contact the support',
        UNAVAILABLE: 'Our service is currently not available in',
      },
      BUTTON: {
        NOTSTARTED: 'Add bank account',
        PENDING: '',
        FAILED: 'Try again',
        DENIED: '',
        SUCCESS: '',
      },
    },
    TFA: {
      TITLE: 'Two-factor authentication',
      SUBTITLE: {
        NOTSTARTED: 'Configure two-factor authentication to protect your account.',
        PENDING: '',
        FAILED: 'Two-factor authentication failed. Please try again',
        DENIED: 'Adding two-factor authentication denied. You can’t try again',
        SUCCESS: 'Your account is protected with two-factor authentication',
        UNKNOWN: 'Please contact the support',
        UNAVAILABLE: '',
      },
      BUTTON: {
        NOTSTARTED: 'Configure',
        PENDING: '',
        FAILED: 'Try again',
        DENIED: '',
        SUCCESS: '',
      },
    },
  },
  IDENTITY_VERIFICATION: {
    FORM: {
      TITLE_GREY: 'Verification process',
      TITLE_BLACK: 'Identity verification',
      FIRST_NAME: {
        LABEL: 'First name',
        PLACEHOLDER: 'Your first name',
      },
      LAST_NAME: {
        LABEL: 'Last name',
        PLACEHOLDER: 'Your last name',
      },
      BIRTHDAY: {
        LABEL: 'Date of birth',
        TITLE: 'Enter your birth date',
        SUBTITLE: 'You have to be at least 18 years old.',
      },
      DAY: {
        LABEL: 'Day',
        PLACEHOLDER: '01',
      },
      MONTH: {
        LABEL: 'Month',
        PLACEHOLDER: 'January',
      },
      YEAR: {
        LABEL: 'Year',
        PLACEHOLDER: '1990',
      },
      SOURCE_OF_FUNDS: {
        LABEL: 'Source of funds',
        PLACEHOLDER: 'Select your source of funds',
      },
      OCCUPATION: {
        LABEL: 'Occupation',
        PLACEHOLDER: 'Select your occupation',
      },
      CHECKBOX_1: 'I’m using this account for my own benefit',
      CHECKBOX_2: 'I’m not a politically exposed person',
    },
    VERIFF: {
      TITLE_GREY: 'Verification process',
      TITLE_BLACK: 'Identity verification',
      DESCRIPTION: 'Please verify your identity using Veriff.',
      NUMERIC_LIST: {
        TITLE: 'This process has 3 steps:',
        POINTS: [
          'Choose the identity document that you want to use',
          'Photograph your identity document',
          'Take a photo of yourself',
        ],
      },
      BUTTON: 'Start identity verification',
      NOTIFICATION_ERROR: 'Something went wrong. Please refresh the page and try again',
    },
  },
  ADDRESS_VERIFICATION: {
    TITLE_GREY: 'Verification process',
    TITLE_BLACK: 'Address verification',
    NOTIFICATION: {
      FILE_EXCEEDS: 'File exceeds the allowed size limit of 10MB',
      ERROR: 'Seems you lost session connection, try to refresh the page and fill the data again.',
    },
    COUNTRY: {
      LABEL: 'Country',
      PLACEHOLDER: '',
    },
    CITY: {
      LABEL: 'City',
      PLACEHOLDER: 'Select your city',
    },
    ADDRESS: {
      LABEL: 'Address',
      PLACEHOLDER: 'Enter your address',
    },
    ZIP_CODE: {
      LABEL: 'Zip code',
      PLACEHOLDER: 'Enter your zip code',
    },
    PROOF: {
      TITLE: 'Proof of address',
      HINT: 'The proof of address document has to include your name as well as address. It has to be issued by a third party (e.g. bank or utility provider).',
    },
    DOCUMENT: {
      TITLE: {
        NO_UPLOAD_FILE: 'Upload document',
        HAVE_FILE: 'Uploaded',
      },
      DESCRIPTION: 'Maximum file size 10MB',
      BUTTON: {
        NO_UPLOAD_FILE: 'Upload',
        HAVE_FILE: 'Replace',
      },
    },
  },
  BANK_VERIFICATION: {
    TITLE_GREY: 'Let’s start!',
    TITLE_BLACK: 'Add your bank account',
    AMOUNT: {
      TITLE: 'Amount to transfer',
      PRICE: '0,10€',
    },
    NOTIFICATION:
      "To confirm your bank account please transfer 0,10€ to Dascue's bank account using information below. It can take up to 3 days to confirm the transfer.",
    TRANSFER_INFO: {
      TITLE: 'Transfer information',
      HINT: 'Please make sure that you make the transfer from a bank account opened in your name and you use information below for your transfer. We may not receive transfers with incorrect information.',
    },
    IBAN: {
      LABEL: 'iban',
    },
    BIC: {
      LABEL: 'Bank Identifier Code',
    },
    BENEFICIARY_NAME: {
      LABEL: 'Beneficiary name',
    },
    REFERENCE_CODE: {
      LABEL: 'Reference code',
    },
    UNDER_FORM_DESCRIPTION: 'Add the reference code in the explanation field',
    CHECKBOX: 'I confirm I have made the transfer from a bank account opened in my name',
  },
  TFA_VERIFICATION: {
    BACKUP_CODES: {
      TITLE_GREY: 'Two-factor authentication',
      TITLE_BLACK: 'Back-up codes',
      DESCRIPTION:
        'Write these codes down and keep them in a safe space so you don’t lose them nor no one could access them. These back-up codes could be used to restore access to your account.',
      BUTTON_DOWNLOAD: 'Download',
      BUTTON_PRINT: 'Print',
      CHECKBOX: 'I confirm that I have saved the back-up codes provided to me',
    },
    TITLE_GREY: 'Two-factor authentication',
    TITLE_BLACK: 'App authenticator',
    NUMERIC_LIST: {
      TITLE: 'In order to configure the two-factor authentication you need to set up an authenticator app:',
      POINTS: [
        'Download an authenticator app (e.g. Google Authenticator), then follow the app’s instructions to set up an account with them.',
        'Copy the token and paste it into the app.',
        'Enter verification code displayed in your app.',
      ],
    },
    TOKEN: {
      LABEL: 'Token',
    },
  },
  NAVIGATION: {
    BACK_TO_DASHBOARD: 'Back to dashboard',
    BUTTON: {
      DEFAULT: 'Confirm',
      NEXT: 'Next',
      DONE: 'Done',
    },
  },
  CREDIT_LINE: {
    CALC_STEP: {
      TITLE_GREY: 'Let’s start!',
      TITLE_BLACK: {
        OPEN_CREDIT: 'Open credit line',
        DEPOSIT_COLLATERAL: 'Deposit collateral',
      },
      TITLE_BLACK_HINT: {
        OPEN_CREDIT:
          'Enter the EUR amount you would like to receive on your bank account. You have to deposit BTC as a collateral. The larger your collateral, the lower the liquidation price.',
        DEPOSIT_COLLATERAL:
          'Deposit BTC as your loan collateral. Your LTV determines the liquidation price, i.e. when your collateral is sold to cover your loan position.',
      },
    },
    TFA_STEP: {
      TITLE_GREY: {
        OPEN_CREDIT: 'Open credit line',
        DEPOSIT_COLLATERAL: 'Deposit collateral',
      },
      TEXT: {
        OPEN_CREDIT:
          'Please conclude two factor authentication to processed with the BTC collateral transfer and finalize the open credit line process.',
        DEPOSIT_COLLATERAL:
          'Please conclude two factor authentication to processed with the deposit collateral process.',
      },
    },
    TRANSFER_STEP: {
      TITLE_GREY: {
        OPEN_CREDIT: 'Open credit line',
        DEPOSIT_COLLATERAL: 'Deposit collateral',
      },
      TITLE_BLACK: 'Transfer collateral',
      TITLE_BLACK_HINT: {
        OPEN_CREDIT: 'Transfer collateral hint',
        DEPOSIT_COLLATERAL: 'Transfer collateral hint Deposit collateral',
      },
      SUBTITLE_1: 'Amount to transfer',
      SUBTITLE_2: 'Transfer information',
      SUBTITLE_3: 'Or use the QR code',
      SUBTITLE_2_HINT: 'Hint for Transfer information',
      NOTIFICATION:
        'The transaction will be automatically cancelled should the first transfer fail to be confirmed in 24h.',
      NOTIFICATION_NO_ADDRESS:
        'The system could not assign a deposit address for you. Please, contact the support at hello@dascue.com',
      INPUT_LABEL: 'Deposit address',
    },
    TRANSACTION_INFO: {
      TITLE: 'Transferred collateral',
      NOTIFICATION: 'The transaction has been confirmed at least once.',
      INPUT_TITLE: 'Transaction ID',
      INPUT_LABEL: 'HASH',
    },
    BANK_STEP: {
      TITLE_GREY: 'Open credit line',
      TITLE_BLACK: 'Select bank account',
    },
    BTC_COLLATERAL_LABEL: 'BTC collateral',
    MIN_COLLATERAL: 'Min collateral',
    AGREEMENT_1: 'I have read, understand and agree to the ',
    AGREEMENT_2: ' and ',
    BUTTON: {
      0: 'Confirm',
      1: 'Next',
      CONFIRM: 'Confirm',
      NEXT: 'Next',
    },
    SUMMARY: {
      TITLE: 'Summary',
      BORROW: {
        TITLE: 'Loan amount',
        HINT: '',
      },
      AMOUNT_TO_TRANSFER: {
        TITLE: {
          OPEN_CREDIT: 'Collateral',
        },
        TITLE_GREY: 'New collateral',
        HINT: '',
      },
      NEW_LOAN: {
        TITLE: {
          OPEN_CREDIT: 'Loan to Value (LTV)',
        },
        TITLE_GREY: 'Current Loan to Value',
        HINT: {
          OPEN_CREDIT: 'Loan to value is calculated by dividing the loan amount by the collateral value.',
        },
      },
      CURRENT_BTC: {
        TITLE: {
          OPEN_CREDIT: 'Collateral value',
        },
        HINT: {
          OPEN_CREDIT: 'Collateral value equals the amount of BTC deposit multiplied by the current BTC price.',
        },
      },
      LIQUIDATION_PRICE: {
        TITLE: {
          OPEN_CREDIT: 'Liquidation price',
        },
        TITLE_GREY: 'Current liquidation price',
        HINT: {
          OPEN_CREDIT:
            'Dascue will sell the collateral to cover the loan value when BTC price drops to the liquidation price.',
        },
      },
      LIQUIDATION_THRESHOLD: {
        TITLE: {
          OPEN_CREDIT: 'LTV liquidation threshold',
        },
        HINT: {
          OPEN_CREDIT: 'Loan to Value ratio when Dascue sells the collateral to cover the loan value.',
        },
      },
      INTEREST_FEE: {
        TITLE: 'Monthly interest',
        HINT: 'Monthly interest is calculated by multiplying the outstanding loan amount and annual interest rate divided by 12.',
      },
      INTEREST_RATE: {
        TITLE: 'Annual interest rate',
        HINT: '',
      },
      INTEREST_PAYMENT_DAY: {
        TITLE: 'Interest payment day',
        HINT: 'The interest has to be paid by this day of each month. If the month has less days than indicated here, the interest has to be paid on the last day of the month.',
      },
      INTEREST_MONTHLY_FEE: {
        TITLE: 'Interest monthly fee',
        HINT: 'Interest is calculated based on the outstanding loan.',
      },
    },
  },
  LOAN: {
    DEPOSIT: {
      TITLE_BLACK: 'Deposit collateral',
      TITLE_GREY: 'Let’s start!',
      TITLE_BLACK_HINT:
        'Deposit BTC as your loan collateral. Your LTV determines the liquidation price, i.e. when your collateral is sold to cover your loan position.',
      RECOMMENDED: 'Recommended',
      RECOMMENDED_BUTTON: 'Add recommended',
      SUMMARY: {
        TITLE: 'Summary',
        COLLATERAL_BTC: {
          AMOUNT_TO_TRANSFER: 'Amount to transfer',
          NEW: 'New collateral',
        },
        LTV: {
          NEW: 'New Loan to Value (LTV)',
          CURRENT: 'Current Loan to Value',
          HINT: '',
        },
        COLLATERAL_EUR: {
          CURRENT: 'Current BTC value',
          HINT: '',
        },
        LIQUIDATION_PRICE: {
          NEW: 'New liquidation price',
          CURRENT: 'Current liquidation price',
          HINT: '',
        },
        LIQUIDATION_THRESHOLD: {
          TEXT: 'LTV Liquidation threshold',
          HINT: '',
        },
      },
      CONFIRM_STEP: {
        TITLE_GREY: 'Confirmation',
        TITLE_BLACK: 'Deposit collateral requested',
        TEXT_1: `We don't guarantee that the transfer is going to be confirmed in time to not change your ltv ratio or before the amount reaches the liquidation point.`,
        BUTTON: 'Back to dashboard',
      },
    },
    BORROW: {
      TITLE_BLACK: 'Borrow',
      TITLE_GREY: 'Let’s start!',
      AVAILABLE_VALUE: 'Available credit ',
      AVAILABLE_BUTTON: 'Add available',
      NOTIFICATION:
        'To ask for that amount you need to increase your available credit by transferring more BTC collateral.',
      SUMMARY: {
        TITLE: 'Summary',
        BORROW: {
          INPUT: 'Borrow',
          NEW: 'New borrowed',
        },
        LTV: {
          NEW: 'New Loan to Value (LTV)',
          CURRENT: 'Current Loan to Value',
          HINT: '',
        },
        COLLATERAL_BTC: {
          CURRENT: 'Current BTC value',
          HINT: '',
        },
        LIQUIDATION_PRICE: {
          NEW: 'New liquidation price',
          CURRENT: 'Current liquidation price',
          HINT: '',
        },
        LIQUIDATION_THRESHOLD: {
          TEXT: 'LTV Liquidation threshold',
          HINT: '',
        },
        INTEREST_FEE: {
          NEW: 'New interest fee',
          CURRENT: 'Current interest fee',
          HINT: '',
        },
        INTEREST_RATE: {
          TEXT: 'Interest rate',
        },
        PAYMENT_DAY: {
          TEXT: 'Interest payment day',
          HINT: '',
        },
      },
      CONFIRM_STEP: {
        TITLE_GREY: 'Confirmation',
        TITLE_BLACK: 'Borrow requested!',
        TEXT_1_COLLATERAL: ['As soon as we receive the collateral BTC transfer we will transfer the '],
        TEXT_1: ['We will transfer the '],
        TEXT_2: [
          ' to your bank account within 7 days. If you don’t receive it please contact us or go to our help center.',
        ],
        BUTTON: 'Back to dashboard',
      },
    },
    NAV_BUTTON: {
      0: 'Confirm',
      1: 'Next',
      CONFIRM: 'Confirm',
      NEXT: 'Next',
    },
  },
  SELECT_COUNTRY: {
    TITLE_GREY: 'Welcome!',
    TITLE_BLACK: 'Select your country',
    LABEL: 'country',
    PLACEHOLDER: 'Select your country of residence',
  },
  HISTORY: {
    TITLE: 'Not found',
    MOBILE_TITLE: 'History',
    TEXT: ['No transactions found in history.', 'Please open your credit line.'],
    BUTTON: 'Open credit line',
    ADDITIONAL_BUTTON: 'Download contract',
  },
  UNAVAILABLE_PAGE: {
    TITLE_GREY: 'Game over!',
    TITLE_BLACK: 'We are sorry, but...',
    TEXT: 'we cannot currently provide the service in your country',
  },
  START_VERIFICATION: {
    TITLE_GREY: 'Let’s start!',
    TITLE_BLACK: 'Start verification process',
    TITLE: 'You are awesome, but...',
    TEXT: [
      'We cannot provide you with a full access to our services now. However, you can pass verification process so that you could get full access faster when the service becomes available in your jurisdiction.',
    ],
    BUTTON: 'Start verification process',
  },
  CREDIT_CONFIRMATION: {
    TITLE_GREY: 'Confirmation',
    TITLE_BLACK: 'Your credit line is opened',
    TEXT_1: ['As soon as we receive the collateral BTC transfer we will transfer the'],
    TEXT_2: ['to your bank account within 7 days. If you don’t receive it please contact us or go to our help center.'],
    BUTTON: 'Back to dashboard',
  },
  PROFILE: {
    TITLE: 'Profile',
    LOGOUT_TITLE: 'Logout',
    SECTION_TITLE_1: 'Account',
    SECTION_TITLE_2: 'Settings',
  },
  ACCOUNT: {
    BACK_TO_ACCOUNT: 'Back to account',
    BUTTON_SHOW: 'View information',
    BUTTON_HIDE: 'Hide information',
    IDENTITY: {
      TITLE: 'Identity',
      SUBTITLE: 'See all your identity information',
      TFA_TEXT: 'Please conclude two factor authentication to see identity information.',
      TFA_GREY_TITLE: 'Identity information',
      FORM: {
        NAME: 'Name',
        BIRTH_DATE: 'Date of birth',
        SOURCE_OF_FUNDS: 'Source of funds',
        OCCUPATION: 'Occupation',
      },
    },
    ADDRESS: {
      TITLE: 'Address',
      SUBTITLE: 'See all your address information',
      TFA_TEXT: 'Please conclude two factor authentication to see address information.',
      TFA_GREY_TITLE: 'Address information',
      FORM: {
        COUNTRY: 'Country',
        CITY: 'City',
        ADDRESS: 'Address',
        ZIP_CODE: 'Zip code',
        ADDRESS_PROOF: 'Proof of address',
      },
    },
    BANK: {
      TITLE: 'Bank account',
      BUTTON: 'Add bank account',
      SUBTITLE: 'Add a new bank account to your account',
    },
    TFA: {
      TITLE: 'Two-factor authentication',
      SUBTITLE: 'Have the safest account with your two-factor authentication',
      BACK_TO_TFA: 'Back to two-factor authentication',
      TFA_TEXT: 'Please conclude two factor authentication to see back up codes.',
      TFA_GREY_TITLE: 'Back up codes',
    },
  },
  AUTHORIZATION: {
    FORM: {
      BUTTON: {
        RE_SUBMIT_EMAIL: 'Re-submit email',
      },
      EMAIL: {
        LABEL: 'email',
        PLACEHOLDER: 'Enter your email...',
      },
      POLICY: {
        TEXT_1: 'View Dascue’s',
        TEXT_2: 'and',
        PRIVACY: 'Privacy Policy',
        TERMS: 'Terms of Use',
      },
      LOGIN: {
        TITLE: 'Log in',
        BUTTON: 'Send log in link',
        UNDER_BUTTON: 'Do you want to create an account?',
        LINK_TEXT: 'Sign up',
        LINK: '/signup',
      },
      SIGNUP: {
        TITLE: 'Sign up',
        BUTTON: 'Send sign up link',
        UNDER_BUTTON: 'Already have an account?',
        LINK_TEXT: 'Log in',
        LINK: '/login',
      },
    },
    INFO_PAGE: {
      TITLE: {
        INTERNAL_ERROR: 'Something went wrong!',
        INVALID_EMAIL: 'Invalid email',
        INVALID_CODE: 'Invalid action code',
      },
      NOTIFICATION: {
        INTERNAL_ERROR:
          'We don’t recognize the browser as one where you started the authentication process. For security reasons, please confirm the email address.',
        INVALID_EMAIL:
          'We weren’t able to authenticate you. It looks like the email link expired. Please re-submit your email again.',
        INVALID_CODE: 'We weren’t able to authenticate you. The email you provided doesn’t match the login link.',
        LINK_SEND: 'We just sent you a one-time link. Please check your inbox and log in from there.',
        HAS_ERROR:
          'Something went wrong and we cannot send you the email to authenticate you. Please, try to refresh the page and submit the form again.',
      },
      UNDER_NOTIFICATION: {
        TEXT_1: 'Didn’t receive the link?',
        TEXT_2: 'Resend link',
        LINK: 'login again.',
      },
    },
    POLICY_TERMS: {
      TITLE: 'Hello again!',
      SUBTITLE: 'You need to confirm that you have read and agree with the following terms:',
      PRIVACY: 'Privacy Policy',
      TERMS: 'Terms of Use',
      CHECKBOX: 'I have read and agree to the the Privacy Policy and the Terms of Use.',
      BUTTON: 'Confirm',
    },
  },
  NOT_FOUND: {
    TITLE: 'Page not found or maybe you don’t have rights to view this page',
    SUBTITLE: 'But hey, don’t worry, just click button down below :)',
    LINK: 'Back to dashboard',
  },
  TFA_WIDGET: {
    TITLE_BLACK: 'Two-factor authentication',
    TITLE_CODE: 'Verification code',
    SUBTITLE_CODE: 'Enter 6 digits verification code',
    COUNTDOWN: {
      TEXT_1: 'You have ',
      TEXT_2: ' left to finish the setup.',
    },
    HINT_CODE: 'You will receive the verification code from your app once you have set it up.',
    ERROR_TEXT: 'Sorry, but the code you entered is not valid. Try again.',
  },
  CONFIRMATIONS: {
    SUBTITLE: 'confirmations',
    TAG: 'Available during 24h00',
  },
  EVENTS: {
    NOT_ACKNOWLEDGED_TITLE: 'New actions',
    ACKNOWLEDGED_TITLE: 'Latest actions',
    IDENTITY_VERIFICATION: 'Identity verification',
    ADDRESS_VERIFICATION: 'Address verification',
    BANK_VERIFICATION: 'Bank account verification',
    TFA_APP_VERIFICATION: 'Setup 2FA app',
    TFA_CODES_VERIFICATION: 'Generate back up codes',
    TFA_RESET_CODES: 'Reset back up codes',
    BTC_TRANSFER: 'BTC collateral transfer ',
    BTC_TRANSFER_SUBTITLE: 'Waiting all confirmations',
    CREDIT_PENDING: 'Waiting for euro transaction',
    CREDIT: 'Open credit line',
  },
  FILTER: {
    DROPDOWN_TITLE: 'Filters',
    DROPDOWN_SECTION_TITLE_1: 'Transaction',
    APPLY_BUTTON: 'Apply',
    RESET_BUTTON: 'Reset',
  },
}
export default en
