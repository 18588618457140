import React from 'react'

import Radio from '../Radio'

import { SelectBankWrap, SelectBankTitle, SelectBankText, SelectBankRadio } from './style'

const SelectBank = ({ checked, title, text, onClick }) => {
  return (
    <SelectBankWrap checked={checked} onClick={onClick}>
      <SelectBankTitle>{title}</SelectBankTitle>
      <SelectBankText>{text}</SelectBankText>
      <SelectBankRadio>
        <Radio checked={checked} />
      </SelectBankRadio>
    </SelectBankWrap>
  )
}

export default SelectBank
