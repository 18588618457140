import { createGlobalStyle } from 'styled-components/macro'
import { device } from 'constants/media'

export const GlobalStyle = createGlobalStyle`
  body {
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    line-height: 1.4;
    padding: 0;
    margin: 0;
    position: relative;
    min-width: 375px;

    @media ${device.mobile} {
      overflow-x: hidden;
    }
  }
  a {
    text-decoration: none;
  }
  * {
    box-sizing: border-box;

    &:focus {
      outline: none;
    }
  }
  input::-ms-clear {
    display: none;
  }
  input[type=number] {
    -moz-appearance: textfield;
  }
  h2, h3, h4 {
    font-weight: bold;
  }
`
