import React from 'react'
import Button from 'ui-kit/components/Button'
import { Row, Col } from 'ui-kit/components/Grid'
import Input from 'ui-kit/components/Input'

import { useDevice } from 'hooks/media'

import ContentMobile from 'components/ContentMobile'

import {
  ActionButton,
  AdditionalData,
  ButtonWrapper,
  Description,
  DescriptionItem,
  DescriptionLabel,
  DescriptionWrapper,
  FormHeader,
  FormTitle,
  Icon,
  InputForm,
} from './styles'
import { TitleBlack } from 'layouts/SubLayout/style'

export const Form = ({ isAddressData, data, additionalData, formTitle, buttonIcon, buttonText, onToggleShow }) => {
  const isMobile = useDevice()

  const ShowDataButton = ({ variant }) => (
    <ButtonWrapper mr={isMobile ? 16 : ''}>
      <Button variant={variant} icon={buttonIcon} text={buttonText} onClick={onToggleShow} padding="5px 16px" />
    </ButtonWrapper>
  )

  const handleDownload = () => {
    const link = document.createElement('a')
    link.setAttribute('href', additionalData.url)
    link.setAttribute('download', additionalData.name)
    link.setAttribute('target', '_blank')
    document.body.appendChild(link)
    link.click()
    link.remove()
  }

  return (
    <>
      {!isMobile && <TitleBlack>{formTitle}</TitleBlack>}
      <ContentMobile>
        <Row justifyContent="center">
          {isMobile && (
            <FormHeader>
              <FormTitle>{formTitle}</FormTitle>
              <ShowDataButton variant="text" />
            </FormHeader>
          )}
          {data.map((item, index) => (
            <Col
              key={item?.label}
              lg={50}
              sm={100}
              p={0}
              order={!isMobile ? (index === 2 ? 1 : index === 1 ? 2 : index) : ''}
            >
              <InputForm>
                <Input label={item?.label} value={item?.value || ''} onChange={() => {}} readOnly disableClearable />
              </InputForm>
            </Col>
          ))}
        </Row>

        {isAddressData && (
          <Row justifyContent="center" m={0}>
            <AdditionalData disabled={!additionalData.url} onClick={handleDownload}>
              <DescriptionWrapper>
                <Icon />
                <Description>
                  <DescriptionLabel>Proof of address</DescriptionLabel>
                  <DescriptionItem>{additionalData.name || 'File name'}</DescriptionItem>
                </Description>
              </DescriptionWrapper>
              <ActionButton />
            </AdditionalData>
          </Row>
        )}
      </ContentMobile>
      {!isMobile && <ShowDataButton variant="outlined" />}
    </>
  )
}
