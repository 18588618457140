import { useState, useEffect } from 'react'
import { useLangContext } from 'context/lang'
import locale from 'locale'

export function useLang() {
  const { lang } = useLangContext()
  const [isDevMode, setIsDevMode] = useState(false)

  useEffect(() => {
    if (window.location.search === '?dev') {
      setIsDevMode(true)
    }
  }, [window.location.search])

  const translate = (key) => {
    const namespace = locale[lang]
    const arr = key.split('.')
    return arr.reduce((acc, cur) => (isDevMode ? key : acc[cur] || ''), namespace)
  }

  return { translate }
}
