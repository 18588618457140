import styled from 'styled-components/macro'
import { device } from 'constants/media'

import { colors } from 'constants/colors'

export const NavBarWrapper = styled.div`
  padding: 16px 0;
  margin-bottom: 24px;

  @media ${device.mobile} {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    margin: 0;
    z-index: 1;
    background-color: ${colors.white};
  }
`

export const NavBarLogo = styled.div`
  font-weight: 500;
  font-size: 20px;
  color: ${colors.gray950};
`

export const NavItem = styled.div`
  color: ${(p) => (p.activeNav ? colors.gray950 : colors.gray400)};
  font-size: 16px;
  display: flex;

  &:hover {
    color: ${colors.gray950};
  }

  svg {
    margin-right: 10px;

    @media ${device.mobile} {
      margin-right: 0;
      width: 24px;
      height: 24px;
    }
  }
`

export const NavBarLang = styled.div`
  text-transform: uppercase;
  display: inline-block;
  vertical-align: middle;
  margin: 0 3px;
  cursor: pointer;
  color: ${(p) => (p.active ? colors.gray950 : colors.gray400)};
`
