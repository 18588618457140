import React from 'react'

import NumberFormat from '../NumberFormat'

import {
  CardWrap,
  CardTitle,
  CardDescription,
  CardMobileDescription,
  CardMobileAmount,
  CardImage,
  CardButton,
  CardButtonWrap,
  CardButtonAdditional,
  CardArrow,
  CardLoanAmount,
} from './style'

const Card = ({ color, isMobile, onClick, text, loanAmount, mode }) => {
  const { title, subtitle, button, buttonLeft, buttonRight } = text
  const haveButton = Boolean(button) || Boolean(buttonLeft) || Boolean(buttonRight)

  const AmountNumber = () => (
    <>
      {buttonLeft}
      <CardLoanAmount>
        <NumberFormat value={loanAmount} displayType="text" suffix=" €" />
      </CardLoanAmount>
    </>
  )

  return (
    <CardWrap data-testid={`${color}-card`} variant={color} onClick={onClick} mode={mode} isMobile={isMobile}>
      <div>
        <CardTitle data-testid="card-title" mode={mode} isMobile={isMobile}>
          {title}
        </CardTitle>
        {haveButton && isMobile && <CardArrow />}
        {!isMobile && <CardDescription>{subtitle}</CardDescription>}
        <CardImage variant={color} mode={mode} isMobile={isMobile} />
      </div>
      {isMobile ? (
        <CardMobileDescription mode={mode}>
          {loanAmount && (
            <CardMobileAmount>
              <AmountNumber />
            </CardMobileAmount>
          )}
          {subtitle}
        </CardMobileDescription>
      ) : (
        <div>
          {Boolean(button) && (
            <CardButton data-testid="card-cta-button" variant={color}>
              {button}
            </CardButton>
          )}
          {loanAmount && (
            <CardButtonWrap>
              <CardButtonAdditional>
                <AmountNumber />
              </CardButtonAdditional>
              <CardButton data-testid="card-cta-button" variant={color}>
                {buttonRight}
              </CardButton>
            </CardButtonWrap>
          )}
        </div>
      )}
    </CardWrap>
  )
}

export default Card
