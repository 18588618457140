import styled from 'styled-components/macro'
import { ContentTemplate } from 'pages/commonStyle'

export const Wrapper = styled.div``

export const Content = styled(ContentTemplate)``

export const MobileHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: space-between;
`

export const MobileTitle = styled.div`
  font-weight: 500;
  font-size: 20px;
  padding-top: 12px;
  margin-bottom: 24px;
`
