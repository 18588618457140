import styled from 'styled-components/macro'
import { colors } from 'constants/colors'

import imgBlueDesktop from './assets/card-blue-desktop.png'
import imgGreenDesktop from './assets/card-green-desktop.png'
import imgOrangeDesktop from './assets/card-orange-desktop.png'
import imgOrange2Desktop from './assets/card-orange-2-desktop.png'
import imgPurpleDesktop from './assets/card-purple-desktop.png'
import imgRedDesktop from './assets/card-red-desktop.png'
import imgRed2Desktop from './assets/card-red-2-desktop.png'
import imgBlueMobile from './assets/card-blue.png'
import imgGreenMobile from './assets/card-green.png'
import imgOrangeMobile from './assets/card-orange.png'
import imgOrange2Mobile from './assets/card-orange-2.png'
import imgPurpleMobile from './assets/card-purple.png'
import imgRedMobile from './assets/card-red.png'
import imgRed2Mobile from './assets/card-red-2.png'
import arrowRight from './assets/arrow-right-white.svg'

const isSmall = (p) => p.mode === 'small'
const isPurple = (p) => p.variant === 'purple'

const colorChooser = (variant) => {
  switch (variant) {
    case 'blue':
      return colors.blue500
    case 'green':
      return colors.green500
    case 'orange':
    case 'orange_not_confirm_btc':
    case 'orange_not_confirm_bank':
    case 'orange_not_enough':
      return colors.orange400
    case 'purple':
      return colors.purple400
    case 'red':
    case 'red_ltv_high':
      return colors.red500
    default:
      return ''
  }
}

export const CardWrap = styled.div`
  height: ${(p) => (p.isMobile ? (isSmall(p) ? 'max-content' : '344px') : isSmall(p) ? '172px' : '230px')};
  min-height: ${(p) => (p.isMobile ? (isSmall(p) ? '82px' : '344px') : isSmall(p) ? '148px' : '230px')};
  padding: ${(p) => (p.isMobile ? (isSmall(p) ? '14px' : '16px') : isSmall(p) ? '14px' : '24px')};
  background: ${(p) => colorChooser(p.variant)};
  color: ${colors.white};
  border-radius: 12px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: ${(p) => (p.onClick ? 'pointer' : 'default')};
  margin-bottom: 50px;
`

export const CardTitle = styled.div`
  font-weight: 500;
  font-size: ${(p) => (p.isMobile ? isSmall(p) && '10px' : isSmall(p) ? '12px' : '20px')};
  text-transform: ${(p) => (isSmall(p) ? 'uppercase' : 'initial')};
  margin-bottom: ${(p) => (isSmall(p) ? '4px' : '8px')};
  z-index: 1;
  position: relative;
`

export const CardDescription = styled.div`
  font-size: 16px;
  line-height: 20px;
  width: 526px;
  position: relative;
  z-index: 1;
`

export const CardMobileDescription = styled.div`
  font-size: 14px;
  width: ${(p) => isSmall(p) && '250px'};
  position: relative;
`

export const CardLoanAmount = styled.div`
  display: inline;
  padding-left: 6px;
`

export const CardMobileAmount = styled.div`
  font-weight: 500;
  margin-bottom: 3px;
`

const desktopImageChooser = (variant) => {
  switch (variant) {
    case 'blue':
      return imgBlueDesktop
    case 'green':
      return imgGreenDesktop
    case 'orange':
      return imgOrangeDesktop

    case 'orange_not_confirm_btc':
    case 'orange_not_confirm_bank':
    case 'orange_not_enough':
      return imgOrange2Desktop
    case 'purple':
      return imgPurpleDesktop
    case 'red':
      return imgRedDesktop
    case 'red_ltv_high':
      return imgRed2Desktop
    default:
      return ''
  }
}
const mobileImageChooser = (variant) => {
  switch (variant) {
    case 'blue':
      return imgBlueMobile
    case 'green':
      return imgGreenMobile
    case 'orange':
      return imgOrangeMobile
    case 'orange_not_confirm_btc':
    case 'orange_not_confirm_bank':
    case 'orange_not_enough':
      return imgOrange2Mobile
    case 'purple':
      return imgPurpleMobile
    case 'red':
      return imgRedMobile
    case 'red_ltv_high':
      return imgRed2Mobile
    default:
      return ''
  }
}

export const CardImage = styled.div`
  width: ${(p) => (p.isMobile ? '100%' : '360px')};
  height: ${(p) => (p.isMobile ? (isPurple(p) ? '250px' : !isSmall(p) && '280px') : '100%')};
  position: absolute;
  right: 0;
  bottom: 0;
  ${(p) => p.isMobile && `top: 0`};
  background-image: url(${(p) => (p.isMobile ? mobileImageChooser(p.variant) : desktopImageChooser(p.variant))});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right;
`

export const CardButtonWrap = styled.div`
  border-radius: 8px;
  padding: 4px;
  background: rgba(255, 255, 255, 0.15);
  display: inline-block;
  position: relative;
`

export const CardButtonAdditional = styled.div`
  padding: 0 16px;
  font-size: 16px;
  display: inline-block;
`

export const CardButton = styled.button`
  background: ${colors.gray50};
  border-radius: 4px;
  padding: 10px 16px;
  color: ${(p) => colorChooser(p.variant)};
  font-size: 16px;
  border: none;
  cursor: pointer;
`

export const CardArrow = styled.div`
  width: 24px;
  height: 24px;
  background-image: url(${arrowRight});
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  right: 16px;
  top: 20px;
  z-index: 1;
`
