import React from 'react'

import PolicyAndTermsLayout from 'layouts/PolicyAndTermsLayout'
import './style.css'

const TermsOfUse = () => {
  return (
    <PolicyAndTermsLayout pageTitle="Terms of Use" pdfFileName="dascue-terms-of-use">
      <article>
        <p style={{ textAlign: 'end' }}>Effective from February 28, 2023</p>
        <p>
          <b>
            These Terms of Use set forth the terms and conditions that apply to your access to your account at
            Dascue.com (“Website”). By accessing your account, you agree to comply with all of the Terms of Use set
            forth herein.
          </b>
        </p>
        <p>By entering into these terms and conditions you represent and warrant that you:</p>
        <ul>
          <li>are aged 18 or over and have full legal capacity;</li>
          <li>have read and agree to be bound by these terms of use;</li>
          <li>will use the account only for your own benefit and not share it with anyone else;</li>
          <li>shall use our account in good faith and not use the account for any illegal activities.</li>
        </ul>

        <ol>
          <li id="our-service-list">
            <h3>Our Service</h3>
            <ol>
              <li>
                <p>
                  Dascue OÜ (“<b>Dascue”</b>, “<b>us</b>”, “<b>we</b>”) operates the Website. We provide you access to
                  your account at the Website and may provide you different services depending on your jurisdiction.
                  These services are subject to terms and conditions that apply to that specific service in your
                  jurisdiction. The relevant terms and conditions are available on your account at the Website.
                </p>
              </li>
              <li>
                <p>
                  You are the only person allowed to you use your account at Dascue. You may not share your account,
                  login details or two-factor authentication with anyone else, and shall take reasonable steps to
                  prevent unauthorized persons from gaining access thereto or using the account. You acknowledge that
                  any communication or action done on the Website using your credentials is deemed to be done by you. If
                  you confirm an action using two-factor authentication on the Website, then such action will be
                  classified as your legally binding expression of will (e.g. offer to conclude a loan agreement).
                </p>
              </li>
              <li>
                <p>
                  You are responsible for your use of the account as well as your own communications and for any
                  consequences thereof. You will use the account in compliance with all applicable local, state,
                  national, and international laws, rules and regulations.
                </p>
              </li>
            </ol>
          </li>
          <li id="duration-termination-tou">
            <h3>Duration, Termination and Amendment of these terms of use</h3>
            <ol>
              <li>
                <p>This agreement will remain in force until terminated by you or us.</p>
              </li>
              <li>
                <p>
                  We may modify the terms and conditions in order to reflect new legal requirements, changes in our
                  Website or correct errors that are discovered. When we do so we will immediately inform you about such
                  changes through our Website. You have the right to immediately cancel the agreement.
                </p>
              </li>
              <li>
                <p>
                  You may terminate this agreement at any time. This does not affect any other agreements concluded
                  between you and Dascue, unless agreed otherwise in that specific agreement.
                </p>
              </li>
            </ol>
          </li>
          <li id="limitation-of-liability">
            <h3>Limitation of Liability</h3>
            <ol>
              <li>
                <p>
                  The Website is provided on an “AS-IS” basis. We do not warrant the Website or access thereto will be
                  uninterrupted or error-free.
                </p>
              </li>
              <li>
                <p>
                  We do not make any express or implied warranties, representations or endorsements whatsoever
                  (including, without limitation, the implied warranties) with regard to the Website or our services,
                  and will not be liable for any cost or damage arising either directly or indirectly from your use of
                  the Website or your account.
                </p>
              </li>
              <li>
                <p>
                  We do not under any circumstances assume liability to you in excess of the amount of money you have
                  paid to us. We will not be liable to you in any form for any consequential damages or loss that you
                  may suffer as a result of use of the Website or any of our obligations under this agreement. You may
                  not make any claim against us for but not limited to; loss of business, loss of opportunity or loss of
                  interest on funds.
                </p>
              </li>
            </ol>
          </li>
          <li id="miscellaneous">
            <h3>Miscellaneous</h3>
            <ol>
              <li>
                <p>
                  All official communication by Dascue will be sent you your e-mail address you have provided to us.
                  Such communication are deemed to be delivered and received on the day following the e-mail has been
                  sent. Changes to the terms of use are published on the Website.
                </p>
              </li>
              <li>
                <p>
                  These terms and conditions shall be governed and construed in accordance with the laws of the Republic
                  of Estonia, without giving effect to conflicts-of-law principles thereof. All claims arising out of or
                  related to these terms and conditions shall be settled in Harju County Court as a court of first
                  instance.
                </p>
              </li>
              <li>
                <p>
                  If any provision of the terms and conditions is found invalid or unenforceable, that provision will be
                  enforced to the maximum extent permissible, and the other provisions of these terms and conditions
                  will remain in force.
                </p>
              </li>
            </ol>
          </li>
        </ol>
      </article>
    </PolicyAndTermsLayout>
  )
}

export default TermsOfUse
