import React from 'react'
import { Link } from 'react-router-dom'

import Checkbox from 'ui-kit/components/Checkbox'
import { useLang } from 'hooks/lang'
import routes from 'constants/routes'
import ContentMobile from 'components/ContentMobile'

import { LinkHighlights } from './style'

const Agreement = ({ checked, onChange }) => {
  const { translate: t } = useLang()
  return (
    <ContentMobile>
      <Checkbox checked={checked} onChange={() => onChange(!checked)}>
        {t('CREDIT_LINE.AGREEMENT_1')}
        <LinkHighlights>
          <Link to={routes.termsOfUse}>{' Terms and Conditions '}</Link>
        </LinkHighlights>
        {t('CREDIT_LINE.AGREEMENT_2')}
        <LinkHighlights>
          <Link to={routes.termsOfLoan}>{'General Terms of the Loan Agreement.'}</Link>
        </LinkHighlights>
      </Checkbox>
    </ContentMobile>
  )
}

export default Agreement
