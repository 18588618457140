import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Button from 'ui-kit/components/Button'
import Checkbox from 'ui-kit/components/Checkbox'

import { useProfileContext } from 'context/profile'
import { setTermsTimestamp } from 'api/profile'
import { useLang } from 'hooks/lang'

import routes from 'constants/routes'

import AuthPageLayout from 'layouts/AuthPageLayout'

import { PolicySubtitle, PolicyDocumentLink, PolicyDocumentIcon, PolicyCheckbox, PolicyButton } from './style'

const PolicyAndTerms = () => {
  const navigate = useNavigate()
  const { translate: t } = useLang()
  const { uid, isTermsAccepted, country } = useProfileContext()

  const [isChecked, setIsChecked] = useState(false)

  useEffect(() => {
    setIsChecked(isTermsAccepted)
  }, [isTermsAccepted])

  const handelTermsAccept = async () => {
    await setTermsTimestamp({ uid })
    if (!country?.code) navigate(routes.country)
  }

  const handleCheckbox = () => {
    setIsChecked(!isChecked)
  }

  return (
    <AuthPageLayout bgImageColor="purple" title={t('AUTHORIZATION.POLICY_TERMS.TITLE')}>
      <PolicySubtitle>{t('AUTHORIZATION.POLICY_TERMS.SUBTITLE')}</PolicySubtitle>
      <PolicyDocumentLink withBorder onClick={() => navigate(routes.privacyPolicy)}>
        <PolicyDocumentIcon type="privacy" />
        {t('AUTHORIZATION.POLICY_TERMS.PRIVACY')}
      </PolicyDocumentLink>
      <PolicyDocumentLink onClick={() => navigate(routes.termsOfUse)}>
        <PolicyDocumentIcon type="terms" />
        {t('AUTHORIZATION.POLICY_TERMS.TERMS')}
      </PolicyDocumentLink>
      <PolicyCheckbox>
        <Checkbox checked={isChecked} label={t('AUTHORIZATION.POLICY_TERMS.CHECKBOX')} onChange={handleCheckbox} />
      </PolicyCheckbox>
      <PolicyButton>
        <Button
          disabled={!isChecked}
          onClick={handelTermsAccept}
          text={t('AUTHORIZATION.POLICY_TERMS.BUTTON')}
          variant="filled"
          width="100%"
        />
      </PolicyButton>
    </AuthPageLayout>
  )
}

export default PolicyAndTerms
