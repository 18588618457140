import React from 'react'
import { RowEl } from './style'

const Row = ({ children, m, justifyContent, alignItems, display, flexWrap, flexDirection }) => {
  return (
    <RowEl
      m={m}
      flexWrap={flexWrap}
      justifyContent={justifyContent}
      alignItems={alignItems}
      display={display}
      flexDirection={flexDirection}
    >
      {children}
    </RowEl>
  )
}

export default Row
