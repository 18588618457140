import styled from 'styled-components/macro'
import { device } from 'constants/media'
import { colors } from 'constants/colors'

export const ProfileVerification = styled.div`
  margin-bottom: 18px;

  @media ${device.mobile} {
    margin-bottom: 16px;
  }
`

export const ProfileH1 = styled.h1`
  font-weight: 500;
  font-size: 20px;
  margin-bottom: 24px;
`

export const ProfileH2 = styled.h2`
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 16px;

  @media ${device.mobile} {
    text-transform: uppercase;
    font-size: 10px;
    color: ${colors.gray500};
  }
`

export const ProfileInfoWrapper = styled.div`
  margin-bottom: 32px;
`
