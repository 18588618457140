import React, { useState, useEffect } from 'react'
import _ from 'lodash'
import { Row, Col } from 'ui-kit/components/Grid'

import { useLang } from 'hooks/lang'
import { checkUserAuthToken } from 'hooks/auth'
import { useProfileContext } from 'context/profile'
import { getIdentityData } from 'api/identity'
import routes from 'constants/routes'

import SubLayout from 'layouts/SubLayout'
import TwofaWidget from 'widgets/TwofaWidget'

import Form from '../components/Form'

const moment = require('moment')

const Identity = () => {
  const { uid } = useProfileContext()
  const { translate: t } = useLang()

  const initPersonalData = {
    name: '*************',
    birthDate: '*************',
    sourceOfFunds: '*************',
    occupation: '*************',
  }

  const [isLoading, setLoading] = useState(false)
  const [personalData, setPersonalData] = useState(initPersonalData)
  const [isFormVisible, setFormVisible] = useState(true)
  const [isDataVisible, setDataVisible] = useState(false)

  useEffect(() => {
    const isInitData = personalData.name === initPersonalData.name
    if (isDataVisible && isInitData) {
      getPersonalData()
    }
  }, [isDataVisible])

  const getPersonalData = async () => {
    const data = await getIdentityData(uid)
    const SOF = _(data.sourceOfFunds || [])
      .map(_.trim)
      .join(', ')

    setPersonalData({
      name: `${data.firstName} ${data.lastName}`,
      birthDate: moment.unix(data.birthDate.seconds).format('MM.DD.YYYY'),
      sourceOfFunds: SOF,
      occupation: data.occupation,
    })
  }

  const toggleVisibility = async () => {
    const isTokenClaimed = await checkUserAuthToken({ claim: 'accessPersonalDataUntil' })
    if (!isTokenClaimed) {
      setFormVisible(false)
    } else {
      setDataVisible(!isDataVisible)
    }
  }

  const hideTwofaWidget = () => {
    setDataVisible(true)
    setFormVisible(true)
  }

  const formData = [
    {
      label: t('ACCOUNT.IDENTITY.FORM.NAME'),
      value: isDataVisible ? personalData.name : initPersonalData.name,
    },
    {
      label: t('ACCOUNT.IDENTITY.FORM.BIRTH_DATE'),
      value: isDataVisible ? personalData.birthDate : initPersonalData.birthDate,
    },
    {
      label: t('ACCOUNT.IDENTITY.FORM.SOURCE_OF_FUNDS'),
      value: isDataVisible ? personalData.sourceOfFunds : initPersonalData.sourceOfFunds,
    },
    {
      label: t('ACCOUNT.IDENTITY.FORM.OCCUPATION'),
      value: isDataVisible ? personalData.occupation : initPersonalData.occupation,
    },
  ]

  return (
    <SubLayout
      isLoading={isLoading}
      hiddenNavigation
      customRoute={routes.profile}
      customBackToText={t('ACCOUNT.BACK_TO_ACCOUNT')}
    >
      {isFormVisible ? (
        <Form
          data={formData}
          formTitle={t('ACCOUNT.IDENTITY.TITLE')}
          buttonIcon={isDataVisible ? 'eye-closed' : 'eye'}
          buttonText={isDataVisible ? t('ACCOUNT.BUTTON_HIDE') : t('ACCOUNT.BUTTON_SHOW')}
          onToggleShow={toggleVisibility}
        />
      ) : (
        <Row justifyContent="center">
          <Col lg={50} sm={100}>
            <TwofaWidget
              onSuccess={hideTwofaWidget}
              onSetPageLoading={setLoading}
              accessType="personalData"
              twofaText={t('ACCOUNT.IDENTITY.TFA_TEXT')}
              titleGrey={t('ACCOUNT.IDENTITY.TFA_GREY_TITLE')}
            />
          </Col>
        </Row>
      )}
    </SubLayout>
  )
}

export default Identity
