import React, { useState } from 'react'

import { confirmCalculation } from 'api/credit'
import { useProfileContext } from 'context/profile'
import { useLang } from 'hooks/lang'
import { useDevice } from 'hooks/media'

import { errorCapture } from 'utils/errorCapture'

import TwofaWidget from 'widgets/TwofaWidget'
import PositionSummary from '../PositionSummary'
import { CalcResultTitle } from 'pages/Loan/style'
import { Row, Col } from 'ui-kit/components/Grid'
import SubLayout from 'layouts/SubLayout'

const ConfirmCalculation = ({ dots, onClickBack, onConfirm, borrowMore }) => {
  const isMobile = useDevice()
  const { translate: t } = useLang()
  const { position, creditStatus } = useProfileContext()

  const [isLoading, setLoading] = useState(false)
  const [disable, setDisable] = useState(true)

  const isHighLtv = position?.isLtvHigh
  const isDenied = creditStatus === 'denied'
  const creditState = isDenied || isHighLtv ? 'DEPOSIT_COLLATERAL' : 'OPEN_CREDIT'

  const onSuccessTwofa = () => {
    confirmCalculation()
      .then(() => {
        setLoading(true)
        onConfirm()
      })
      .catch((error) => errorCapture(error))
  }

  const onInputCode = (code) => {
    if (code.length === 0) setDisable(false)
  }

  return (
    <SubLayout
      onClickBack={onClickBack}
      dots={dots}
      // Local
      isLoading={isLoading}
      confirmDisabled={disable}
      infoText={t(`CREDIT_LINE.TFA_STEP.TITLE_BLACK_HINT.${creditState}`)}
      forwardText={t(`CREDIT_LINE.BUTTON.CONFIRM`)}
      onClickForward={onSuccessTwofa}
    >
      <Row justifyContent="center">
        <Col lg={50} sm={100}>
          <TwofaWidget
            titleGrey={t(`CREDIT_LINE.TFA_STEP.TITLE_GREY.${creditState}`)}
            twofaText={t(`CREDIT_LINE.TFA_STEP.TEXT.${creditState}`)}
            onInput={(code) => onInputCode(code)}
            onSuccess={onSuccessTwofa}
            onSetPageLoading={setLoading}
            accessType="creditLineConfirm"
          />
        </Col>
        {!isMobile && (
          <Col lg={50}>
            <CalcResultTitle>{t('CREDIT_LINE.SUMMARY.TITLE')}</CalcResultTitle>
            <PositionSummary borrowSummary={borrowMore} />
          </Col>
        )}
      </Row>
    </SubLayout>
  )
}

export default ConfirmCalculation
