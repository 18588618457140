import styled from 'styled-components/macro'

import { device } from 'constants/media'

export const PageTitle = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;

  @media ${device.mobile} {
    margin-top: 36px;
    font-size: 18px;
  }
`

export const ListWrapper = styled.div`
  margin: 24px 0;
`

export const ButtonWrapper = styled.div`
  width: ${(p) => (p.isMobile ? '100vw' : 'fit-content')};
  position: ${(p) => (p.isMobile ? 'absolute' : 'relative')};
  bottom: ${(p) => (p.isMobile ? '16px' : '')};
  padding: ${(p) => (p.isMobile ? '0 16px' : '')};
  margin: ${(p) => (p.isMobile ? '0' : '16px 0')};
`
