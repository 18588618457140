import React from 'react'

import { ContentMobileWrap, ContentMobileEl } from './style'

const ContentMobile = ({ children, noPadding }) => {
  return (
    <ContentMobileWrap>
      <ContentMobileEl noPadding={noPadding}>{children}</ContentMobileEl>
    </ContentMobileWrap>
  )
}

export default ContentMobile
