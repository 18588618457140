import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Notification from 'ui-kit/components/Notification'
import InfoBlock from 'ui-kit/components/InfoBlock'
import bn from 'bignumber.js'

import routes from 'constants/routes'
import { colors } from 'constants/colors'
import { useProfileContext } from 'context/profile'
import { useDevice } from 'hooks/media'
import { useLang } from 'hooks/lang'
import { useEventLog } from 'hooks/events'
import { useVerification } from 'hooks/verification'
import { makeEventAcknowledged } from 'api/events'

import MainLayout from 'layouts/MainLayout'
import Confirmations from './Confirmations'

import { DashboardBlockTitle, DashboardVerification, EventLogTitle, EventLogWrapper } from './style'

import PositionInfo from './CreditGrid/PositionInfo'
import MinLoanInfo from './components/MinLoanInfo'
import CTA from './components/CTA'

const Dashboard = () => {
  const { isLoaded, uid, country, isServiceAvailable, isOnboardingFinished, balances, contractDraft, position } =
    useProfileContext()

  const { eventLog: eventLogNotAcknowledged } = useEventLog({ acknowledged: false })
  const { eventLog: eventLogAcknowledged } = useEventLog()
  const navigate = useNavigate()
  const isMobile = useDevice()
  const { processingVerifications, isVerificationComplete, verificationDenied } = useVerification()
  const { translate: t } = useLang()

  // Loan states
  const isBtcReceived = bn(balances?.btcVerified).gt(0)

  useEffect(() => {
    if (country?.code !== undefined && !country?.code) navigate(routes.country)
  }, [])

  const handleAcknowledge = (id) => {
    makeEventAcknowledged({ uid, id })
  }

  return (
    <MainLayout activeNav="dashboard" mobileTitle={t('DASHBOARD.MOBILE_TITLE')}>
      {isLoaded && (
        <>
          {isServiceAvailable !== undefined && !isServiceAvailable && (
            <Notification variant="red">
              {t('DASHBOARD.NOTIFICATION.SERVICE_NOT_AVAILABLE')} <u>{country?.label}</u>
            </Notification>
          )}

          {isServiceAvailable && (
            <>
              {verificationDenied && (
                <Notification variant="red">{t('VERIFICATION_BLOCKS.DENIED_NOTIFICATION')}</Notification>
              )}
              {/* NEW EVENTS */}
              {eventLogNotAcknowledged.length > 0 && (
                <>
                  <DashboardBlockTitle>{t('EVENTS.NOT_ACKNOWLEDGED_TITLE')}</DashboardBlockTitle>
                  <EventLogWrapper>
                    {eventLogNotAcknowledged.map((data) => (
                      <InfoBlock
                        key={data.id}
                        testId="not-acknowledged-event"
                        infoData={data}
                        mode="row"
                        bgColor="transparent"
                        alignItems="center"
                        border={colors.gray200}
                        iconBgColor={colors.gray150}
                        showStatusInSubtitle={!!data.status}
                        handlerType="cross"
                        variant="united"
                        onClickButton={() => handleAcknowledge(data.id)}
                      />
                    ))}
                  </EventLogWrapper>
                </>
              )}

              {/* VERIFICATION PROCESS */}
              {isOnboardingFinished && !isVerificationComplete && (
                <>
                  <DashboardBlockTitle>{t('VERIFICATION_BLOCKS.TITLE_BLACK')}</DashboardBlockTitle>
                  <DashboardVerification data-testid="verification-block">
                    {processingVerifications.map((data, index) => (
                      <InfoBlock
                        key={index}
                        id={`${data.id}-step`}
                        testId="verification-step"
                        infoData={data}
                        mode="row"
                        variant="united"
                        bgColor="transparent"
                        alignItems="center"
                        border={colors.gray200}
                        iconBgColor={colors.gray150}
                        cursor={data.route && !verificationDenied ? 'pointer' : 'default'}
                        handlerType={data.status?.icon ? 'statusIcon' : isMobile ? 'arrow' : 'button'}
                        onClickBlock={() => !verificationDenied && navigate(data.route)}
                        isBlockDisabled={verificationDenied && data.status?.code !== 'denied'}
                        showStatusInSubtitle
                      />
                    ))}
                  </DashboardVerification>
                </>
              )}

              <CTA />

              {/* CREDIT LINE PROCESS */}
              <Confirmations />
              {!Boolean(position) && isBtcReceived && <MinLoanInfo contractDraft={contractDraft} balances={balances} />}
              <PositionInfo />

              {/* ACKNOWLEDGED EVENTS */}
              {eventLogAcknowledged !== undefined && eventLogAcknowledged.length > 0 && (
                <>
                  <EventLogTitle>{t('EVENTS.ACKNOWLEDGED_TITLE')}</EventLogTitle>
                  <EventLogWrapper>
                    {eventLogAcknowledged.map((data) => (
                      <InfoBlock
                        key={data.id}
                        testId="acknowledged-event"
                        infoData={data}
                        mode="row"
                        variant="united"
                        alignItems="center"
                        iconBgColor={colors.gray150}
                        borderMiddle={colors.gray150}
                        padding="16px 0"
                        bgColor="transparent"
                        showStatusInSubtitle={isMobile && !!data.status}
                        handlerType={!isMobile && 'statusText'}
                        cursor={data.route && !isMobile ? 'cursor' : 'default'}
                      />
                    ))}
                  </EventLogWrapper>
                </>
              )}
            </>
          )}
        </>
      )}
    </MainLayout>
  )
}

export default Dashboard
