import React from 'react'
import Checkbox from 'ui-kit/components/Checkbox'
import Select from 'ui-kit/components/Select'
import { Row, Col } from 'ui-kit/components/Grid'
import Input from 'ui-kit/components/Input'

import { useDevice } from 'hooks/media'
import { useLang } from 'hooks/lang'

import ContentMobile from 'components/ContentMobile'

import { constants } from './constants'

import { TitleGrey, TitleBlack } from 'layouts/SubLayout/style'
import { InputNameWrapper, DateBlockSubtitle, DateBlockTitle, CheckboxWrapper, InputBlock } from './style'

const PersonalDataForm = ({ onFormChange, formData }) => {
  const isMobile = useDevice()
  const { translate: t } = useLang()
  const { days, months, years, fundsSource, occupations } = constants

  return (
    <>
      <ContentMobile>
        {!isMobile && <TitleGrey>{t('IDENTITY_VERIFICATION.FORM.TITLE_GREY')}</TitleGrey>}
        <TitleBlack>{t('IDENTITY_VERIFICATION.FORM.TITLE_BLACK')}</TitleBlack>
        <InputBlock>
          <Row>
            <Col lg={50} sm={100}>
              <InputNameWrapper>
                <Input
                  label={t('IDENTITY_VERIFICATION.FORM.FIRST_NAME.LABEL')}
                  placeholder={t('IDENTITY_VERIFICATION.FORM.FIRST_NAME.PLACEHOLDER')}
                  value={formData.firstName}
                  autoFocus
                  onChange={(e) => onFormChange('firstName', e.target.value)}
                />
              </InputNameWrapper>
            </Col>
            <Col lg={50} sm={100}>
              <InputNameWrapper>
                <Input
                  label={t('IDENTITY_VERIFICATION.FORM.LAST_NAME.LABEL')}
                  placeholder={t('IDENTITY_VERIFICATION.FORM.LAST_NAME.PLACEHOLDER')}
                  value={formData.lastName}
                  onChange={(e) => onFormChange('lastName', e.target.value)}
                />
              </InputNameWrapper>
            </Col>
          </Row>
        </InputBlock>
      </ContentMobile>

      <ContentMobile>
        <InputBlock>
          <DateBlockTitle>{t('IDENTITY_VERIFICATION.FORM.BIRTHDAY.TITLE')}</DateBlockTitle>
          <DateBlockSubtitle>{t('IDENTITY_VERIFICATION.FORM.BIRTHDAY.SUBTITLE')}</DateBlockSubtitle>
          <Row>
            <Col lg={30} sm={30}>
              <Select
                label={t('IDENTITY_VERIFICATION.FORM.DAY.LABEL')}
                placeholder={t('IDENTITY_VERIFICATION.FORM.DAY.PLACEHOLDER')}
                value={formData.birthDay}
                onChange={(value) => onFormChange('birthDay', value)}
                options={days}
              />
            </Col>
            <Col lg={40} sm={40}>
              <Select
                label={t('IDENTITY_VERIFICATION.FORM.MONTH.LABEL')}
                placeholder={t('IDENTITY_VERIFICATION.FORM.MONTH.PLACEHOLDER')}
                value={formData.birthMonth}
                onChange={(value) => onFormChange('birthMonth', value)}
                options={months}
              />
            </Col>
            <Col lg={30} sm={30}>
              <Select
                label={t('IDENTITY_VERIFICATION.FORM.YEAR.LABEL')}
                placeholder={t('IDENTITY_VERIFICATION.FORM.YEAR.PLACEHOLDER')}
                value={formData.birthYear}
                onChange={(value) => onFormChange('birthYear', value)}
                options={years}
              />
            </Col>
          </Row>
        </InputBlock>
      </ContentMobile>

      <ContentMobile>
        <InputBlock>
          <Select
            label={t('IDENTITY_VERIFICATION.FORM.SOURCE_OF_FUNDS.LABEL')}
            placeholder={t('IDENTITY_VERIFICATION.FORM.SOURCE_OF_FUNDS.PLACEHOLDER')}
            value={formData.fundsSource}
            onChange={(value) => onFormChange('fundsSource', value)}
            options={fundsSource}
            isMulti
          />
        </InputBlock>
      </ContentMobile>

      <ContentMobile>
        <InputBlock>
          <Select
            label={t('IDENTITY_VERIFICATION.FORM.OCCUPATION.LABEL')}
            placeholder={t('IDENTITY_VERIFICATION.FORM.OCCUPATION.PLACEHOLDER')}
            value={formData.occupation}
            onChange={(value) => onFormChange('occupation', value)}
            options={occupations}
          />
        </InputBlock>
      </ContentMobile>

      <ContentMobile>
        <InputBlock>
          <CheckboxWrapper>
            <Checkbox
              checked={formData.isBeneficiary}
              label={t('IDENTITY_VERIFICATION.FORM.CHECKBOX_1')}
              onChange={() => onFormChange('isBeneficiary', !formData.isBeneficiary)}
            />
          </CheckboxWrapper>
          <CheckboxWrapper>
            <Checkbox
              checked={formData.isNotPEP}
              label={t('IDENTITY_VERIFICATION.FORM.CHECKBOX_2')}
              onChange={() => onFormChange('isNotPEP', !formData.isNotPEP)}
            />
          </CheckboxWrapper>
        </InputBlock>
      </ContentMobile>
    </>
  )
}

export default PersonalDataForm
