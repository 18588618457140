import styled from 'styled-components/macro'

export const RowEl = styled.div`
  display: ${(p) => (p.display !== undefined ? p.display : 'flex')};
  flex-wrap: ${(p) => (p.flexWrap ? p.flexWrap : 'wrap')};
  margin: ${(p) => (p.m !== undefined ? `0 ${p.m}px` : '0 -8px')};
  justify-content: ${(p) => (p.justifyContent ? p.justifyContent : null)};
  align-items: ${(p) => (p.alignItems ? p.alignItems : null)};
  flex-direction: ${(p) => (p.flexDirection ? p.flexDirection : 'row')};
`
