import { useProfileContext } from 'context/profile'
import { useLang } from 'hooks/lang'

import routes from 'constants/routes'
import statuses from 'constants/statuses'

import personIcon from 'assets/icons/verification/person.svg'
import locationIcon from 'assets/icons/verification/location.svg'
import cardIcon from 'assets/icons/verification/card.svg'
import keyIcon from 'assets/icons/verification/key.svg'

export function useVerification() {
  const { translate: t } = useLang()
  const { country, currency, verification, isServiceAvailable } = useProfileContext()
  const isStablecoin = currency !== 'EUR'
  const identityStatus = verification?.identity || { code: '' }
  const addressStatus = verification?.address || { code: '' }
  const bankStatus = verification?.bank || { code: '' }
  const TwoFAStatus = verification?.twofa || { code: '' }

  const stepsStatus = verification
    ? Object.keys(verification).reduce((acc, cur) => {
        acc[cur] = verification[cur].code.toUpperCase()
        return acc
      }, {})
    : ''

  const renderRoute = (status) => {
    return status.code === statuses.failed.code || status.code === statuses.notStarted.code
  }

  const bank = {
    id: 'bank',
    title: t('VERIFICATION_BLOCKS.BANK.TITLE'),
    subtitle:
      t(`VERIFICATION_BLOCKS.BANK.SUBTITLE.${stepsStatus.bank}`) +
      (stepsStatus.bank === 'UNAVAILABLE' ? ` ${country.label}` : ''),
    status: bankStatus,
    icon: cardIcon,
    buttonText: t(`VERIFICATION_BLOCKS.BANK.BUTTON.${[stepsStatus.bank]}`),
    route: renderRoute(bankStatus) && isServiceAvailable ? routes.bankStep : null,
    disabled: !isServiceAvailable,
  }

  const data = [
    {
      id: 'identity',
      title: t('VERIFICATION_BLOCKS.IDENTITY.TITLE'),
      subtitle: t(`VERIFICATION_BLOCKS.IDENTITY.SUBTITLE.${stepsStatus.identity}`),
      status: identityStatus,
      icon: personIcon,
      buttonText: t(`VERIFICATION_BLOCKS.IDENTITY.BUTTON.${[stepsStatus.identity]}`),
      route: renderRoute(identityStatus) ? routes.identityStep : null,
      disabled: false,
    },
    {
      id: 'address',
      title: t('VERIFICATION_BLOCKS.ADDRESS.TITLE'),
      subtitle: t(`VERIFICATION_BLOCKS.ADDRESS.SUBTITLE.${stepsStatus.address}`),
      status: addressStatus,
      icon: locationIcon,
      buttonText: t(`VERIFICATION_BLOCKS.ADDRESS.BUTTON.${[stepsStatus.address]}`),
      route: renderRoute(addressStatus) ? routes.addressStep : null,
      disabled: false,
    },
    ...(isStablecoin ? [] : [bank]),
    {
      id: '2fa',
      title: t('VERIFICATION_BLOCKS.TFA.TITLE'),
      subtitle: t(`VERIFICATION_BLOCKS.TFA.SUBTITLE.${stepsStatus.twofa}`),
      status: TwoFAStatus,
      icon: keyIcon,
      buttonText: t(`VERIFICATION_BLOCKS.TFA.BUTTON.${[stepsStatus.twofa]}`),
      route: renderRoute(TwoFAStatus) ? routes.twoFactor : null,
      disabled: false,
    },
  ]

  const processingVerifications = data.filter((data) => data.status.code !== statuses.success.code)
  const isVerificationComplete = data.every((data) => data.status.code === statuses.success.code)
  const verificationFailed = data.some((data) => data.status.code === statuses.failed.code)
  const verificationDenied = data.some((data) => data.status.code === statuses.denied.code)

  return { data, processingVerifications, isVerificationComplete, verificationFailed, verificationDenied }
}
