import React from 'react'

import PolicyAndTermsLayout from 'layouts/PolicyAndTermsLayout'
import './style.css'

const TermsOfLoan = () => {
  return (
    <PolicyAndTermsLayout pageTitle="General Terms of the Loan Agreement." pdfFileName="dascue-terms-of-loan-agreement">
      <article>
        <p style={{ textAlign: 'end' }}>Effective from February 28, 2023</p>

        <ol>
          <li id="definitions">
            <h3>Definitions</h3>
            <p>Terms with capital initials have the meaning as set forth in the Special Terms or below.</p>
            <ol>
              <li>
                <p>
                  Collateral – bitcoins transferred to a Bitcoin address designated by Us and that are used to secure
                  Your obligations arising from this Loan Agreement
                </p>
              </li>
              <li>
                <p>General Terms – this document which sets forth the general terms of the Loan Agreement</p>
              </li>
              <li>
                <p>Loan – sum of money given to You by Us under the Loan Agreement.</p>
              </li>
              <li>
                <p>
                  Loan Agreement – the loan agreement under which You received the loan. The Loan Agreement consists of
                  three parts: the Special Terms, General Terms and Terms of Use. The Loan Agreement and all of its
                  parts are accessible on the Website.
                </p>
              </li>
              <li>
                <p>Loan to Value – Loan / value of Collateral in EUR.</p>
              </li>
              <li>
                <p>Liquidation Threshold – 90%.</p>
              </li>
              <li>
                <p>
                  Required Collateral – minimum amount of Collateral that has to be deposited by You to cover your Loan
                  position. Required Collateral is calculated based on following formula: Loan / 0.66 / (BTC/EUR market
                  exchange rate).
                </p>
              </li>
              <li>
                <p>
                  Special Terms – The special terms of the Loan Agreement which sets the specific terms (e.g. Loan
                  amount, interest rate and interest payment day) for you.
                </p>
              </li>
              <li>
                <p>Terms of Use – Terms of Use of the Website.</p>
              </li>
              <li>
                <p>
                  Us, We – Dascue OÜ, Estonian registry code 16158298, address: Narva mnt 5, 10117 Tallinn, Estonia.
                </p>
              </li>
              <li>
                <p>
                  Website – our website located at{' '}
                  <a href="https://dascue.com." style={{ color: 'black' }}>
                    https://dascue.com.
                  </a>
                </p>
              </li>
              <li>
                <p>You – A person who has concluded a Loan Agreement with us.</p>
              </li>
            </ol>
          </li>

          <li id="conclusion-and-entry-into-force">
            <h3>Conclusion and entry into force</h3>
            <ol>
              <li>
                <p>
                  The Loan Agreement is concluded when You confirm your Loan application at the Website with 2 factor
                  authentication. You can see the time of conclusion at the Website. The Loan Agreement enters into
                  force on the condition that We receive the Required Collateral on time. The Loan Agreement enters into
                  force when We transfer the Loan to an account designate by You at the Website.
                </p>
              </li>
            </ol>
          </li>

          <li id="payout-of-loan">
            <h3>Payout of Loan</h3>
            <ol>
              <li>
                <p>
                  We will pay out the Loan as soon as possible but not later than 7 calendar days on the condition that:
                  i) You have successfully passed our know-your-customer procedures, ii) You have deposited the Required
                  Collateral on time, and iii) there are no other legal restriction for such transfer. We will pay out
                  the Loan only in EUR and to the bank account that You have verified as your own during the onboarding.
                </p>
              </li>
              <li>
                <p>
                  We may refuse to pay out the Loan if at least one of the following circumstances is present: a) You
                  have not submitted the required documents or information to Us or You have submitted false documents
                  or information; b) Your identity, the authenticity of the documents submitted or the origin of
                  Collateral requires further verification; c) the material circumstances on the basis of which the Loan
                  is paid out have changed (e.g. if the value of Collateral has fallen below Required Collateral).
                </p>
              </li>
            </ol>
          </li>

          <li id="collateral">
            <h3>Collateral</h3>
            <ol>
              <li>
                <p>
                  You must deposit the Required Collateral within 24 hours from the moment You confirmed your Loan
                  Application with 2 factor authentication. Indication of Required Collateral is displayed in the
                  Special Terms.
                </p>
              </li>
              <li>
                <p>
                  Your Loan to Value must always be higher than the Liquidation Threshold. You have a right to deposit
                  additional Collateral to ensure your Loan to Value doesn’t fall below the Liquidation Threshold. You
                  have a right to withdraw Collateral which value exceeds Required Collateral amount to You own Bitcoin
                  wallet.
                </p>
              </li>
              <li>
                <p>
                  We have to right to transfer the Collateral between different wallets on Bitcoin network and use third
                  party services for management of Collateral and liquidation thereof. However, We will never give the
                  ownership to third parties, unless necessary to perform our rights or obligations under this Loan
                  Agreement.
                </p>
              </li>
              <li>
                <p>
                  We have a right to sell part or all of the Collateral if You are in default of your obligations under
                  this Loan Agreement. All the proceeds from selling Collateral will first be used to cover your
                  obligations under this Loan Agreement. You may withdraw any amounts left over from the sale of
                  Collateral to your bank account registered with Us as your own or if part of the Collateral has not
                  been sold after satisfying all your obligations under the Loan Agreement then You may withdraw it to
                  your Bitcoin wallets.
                </p>
              </li>
            </ol>
          </li>

          <li id="payment-and-repayment">
            <h3>Payment and repayment</h3>
            <ol>
              <li>
                <p>
                  We will deem a payment is made as of the receipt of the payment on our current account provided the
                  reference number of the payment matches your reference number provided at the Website. You must make
                  payment from a bank account opened in your own name.
                </p>
              </li>
              <li>
                <p>
                  If You would like to repay the Loan early in part or in full, You can transfer the amount to our
                  current account as indicated at the Website. If You repay the Loan early in part or in full, You will
                  not have to pay Interest or other expenses for the time when You no longer use the Loan. We will then
                  inform You about the total amount of the payments arising from the Loan Agreement as of the moment of
                  repayment.
                </p>
              </li>
              <li>
                <p>
                  Any payments We receive will be counted towards outstanding amounts in the following order: (1) costs
                  incurred on the collection of the debt that has fallen due; (2) the overdue Loan principal, starting
                  from the earliest debt; (3) the overdue Interest, starting from the earliest debt; (4) any other
                  overdue payables
                </p>
              </li>
              <li>
                <p>
                  If You fail to make the payments arising from the Loan Agreement when due and We extraordinarily
                  cancel the Loan Agreement. You must compensate Us for any and all costs incurred in connection with
                  inquiries made by Us if the inquiry was caused by your non-performance or improper performance of the
                  Loan Agreement.
                </p>
              </li>
            </ol>
          </li>

          <li id="interest">
            <h3>Interest</h3>
            <ol>
              <li>
                <p>
                  You must pay the interests every month on the day specified in the Special Terms. The interest rate
                  and indicative monthly interest payment is specified in the Special Terms.
                </p>
              </li>
              <li>
                <p>
                  If You pay part of the Loan back, then the monthly interest payment will be recalculated according to
                  the interest rate specified in the Loan Agreement on the outstanding portion of the Loan placed at
                  Your disposal according to the time of its use unless the Parties have agreed otherwise in the Loan
                  Agreement. We calculate the Interest as of the date the Loan Agreement entered into force (inclusive)
                  until the Loan is fully repaid. We calculate the Interest on the basis of the actual number of days in
                  a month and a 360-day year.
                </p>
              </li>
            </ol>
          </li>

          <li id="default-interest">
            <h3>Default interest</h3>
            <ol>
              <li>
                <p>
                  If You fail to make the payments arising from the Loan Agreement when due or fail to pay them in full,
                  You will have pay Us default interest at the same rate as your interest rate. Default interest is
                  calculated on the outstanding amount for each day of delay as of the day following the due date until
                  the day of accrual of the sum owed. No default interest is charged on the Default Interest itself,
                  interest or other fees for using the money.
                </p>
              </li>
            </ol>
          </li>

          <li id="communication-of-important-facts">
            <h3>Communication of important facts</h3>
            <ol>
              <li>
                <p>
                  During the term of the Loan Agreement, You must inform Us immediately but not later than within 5
                  (five) working days of any circumstances that have become known to You which may damage the
                  performance of your obligations under the Loan Agreement by you, including but not limited to: (1) if
                  You have become insolvent or a bankruptcy petition has been filed against You with a court or
                  enforcement proceedings have been brought against you; (2) if a court judgement where the claim upheld
                  against You exceeds €100 has entered into force with regard to you; (3) if circumstances or events
                  emerge that affect or may affect the performance of the Loan Agreement significantly in any other
                  manner or impede or render impossible the performance of the Loan Agreement by you; (4) if your
                  contact details set out in the Loan Agreement change.
                </p>
              </li>
            </ol>
          </li>

          <li id="types-of-contract-cancellation">
            <h3>Ament, expiry and early cancellation of contracts</h3>
            <ol>
              <li>
                <p>
                  The Loan Agreement can only be amended by concluding a new Loan Agreement using our Website or in a
                  written form, unless otherwise agreed in the Loan Agreement.
                </p>
              </li>
              <li>
                <p>
                  We may amend the Loan Agreement unilaterally if the laws governing Loan Agreement or credit or the
                  relationships arising from the Loan Agreement are amended and the unilateral amendment of the Loan
                  Agreement is justified by bringing it into compliance with effective law. We also have the right to
                  amend the Loan Agreement if our business environment changes materially and we need to change the Loan
                  Agreement to reflect it. In such case, You will have the right to cancel the Loan Agreement if you
                  don’t accept with such changes.
                </p>
              </li>
              <li>
                <p>
                  The Loan Agreement will expire with the full performance of your obligations arising from the Loan
                  Agreement, the withdrawal of one Party from the Loan Agreement or the cancellation of the Loan
                  Agreement.
                </p>
              </li>
              <li>
                <p>
                  You have the right to withdraw from the Loan Agreement without disclosing the reason within 14
                  (fourteen) days of entering into the Loan Agreement. To withdraw just transfer back the borrowed Loan
                  to our bank account designated at the Website. In the event of withdrawal from the Loan Agreement, You
                  must repay the Loan amount to Us and pay the interest calculated as of the payout of the Loan until
                  the repayment of the Loan.
                </p>
              </li>
              <li>
                <p>
                  We have the right to cancel the Loan Agreement without notice and demand that the payments arising
                  from the Loan Agreement be made on the grounds and pursuant to the procedure arising from law and/or
                  the Loan Agreement. We also have the right to cancel the Loan Agreement without notice and demand that
                  the all of the payments arising from the Loan Agreement are made in the case of the occurrence of even
                  one of the following events, which the Parties consider a good reason and which You have failed to
                  eliminate or remedy within an additional term of 14 (fourteen) calendar days: (1) You have failed to
                  pay at least three (3) consecutive payments in part or in full; (2) You have breached other
                  obligations set out in the Loan Agreement. We also have the right to unilaterally cancel the Loan
                  Agreement without reason by notifying You two months in advance.
                </p>
              </li>
              <li>
                <p>
                  Upon the expiry of the Loan Agreement on any grounds (excl. if You withdraw from the Loan Agreement
                  pursuant to clause 9.4), You must pay all of the amounts You owe to Us according to the Loan Agreement
                  to Us immediately. If you have not paid the amounts within 5 working days, We have the right to sell
                  the Collateral to cover your obligations.
                </p>
              </li>
              <li>
                <p>
                  We have the right to assign the claims that We have against You under the Loan Agreement and will
                  inform You about the assignment of a claim pursuant to law. We have the right to disclose information
                  regarding the Loan Agreement and You to third parties if the respective right or duty arise from law
                  or the Loan Agreement.
                </p>
              </li>
            </ol>
          </li>

          <li id="resolution-of-complaints">
            <h3>Resolution of complaints</h3>
            <ol>
              <li>
                <p>
                  Our aim is to provide You with the best quality of service. If You are not satisfied with our service,
                  please contact Us through the Website. Please file Your complaint in a format of Your choice to our
                  contact details. In the complaint, please describe the circumstances of Your dissatisfaction as
                  accurately as possible and enclose the documents serving as the basis for Your statements.
                </p>
              </li>
              <li>
                <p>
                  Our aim is to resolve any possible disputes by means of negotiations. Should negotiations fail to
                  resolve the dispute, You as a consumer have the right to turn to the court or to the Consumer
                  Protection and Technical Regulatory Authority (Endla 10A, 10122 Tallinn, tel. 620 1707) or the
                  Consumer Dispute Committee operating under said authority (tel. 620 1707, e-mail: avaldus@komisjon.ee)
                  to protect Your rights. You can find information at www.ttja.ee. Complaints arising from a contract
                  entered into via means of communication may be submitted at ec.europa.eu/odr. Court actions are
                  resolved in a court of Our location, unless the Parties have agreed otherwise or the law stipulates
                  otherwise.
                </p>
              </li>
            </ol>
          </li>
        </ol>
      </article>
    </PolicyAndTermsLayout>
  )
}

export default TermsOfLoan
