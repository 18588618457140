import styled from 'styled-components/macro'
import { device } from 'constants/media'

export const ContentTemplate = styled.div`
  max-width: 768px;
  padding: 0 12px;
  margin: auto;
  position: relative;

  @media ${device.mobile} {
    max-width: 375px;
  }
`
