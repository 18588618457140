import { useProfileContext } from 'context/profile'
import { useLang } from 'hooks/lang'

import routes from 'constants/routes'

import personIcon from 'assets/icons/verification/person.svg'
import locationIcon from 'assets/icons/verification/location.svg'
import cardIcon from 'assets/icons/verification/card.svg'
import keyIcon from 'assets/icons/verification/key.svg'

export function useAccount() {
  const { currency, verification } = useProfileContext()
  const { translate: t } = useLang()
  const isStablecoin = currency !== 'EUR'

  const bankData = {
    title: t('ACCOUNT.BANK.TITLE'),
    subtitle: t('ACCOUNT.BANK.SUBTITLE'),
    status: verification?.bank,
    icon: cardIcon,
    buttonText: '',
    route: routes.accountBank,
    disabled: false,
  }

  return [
    {
      title: t('ACCOUNT.IDENTITY.TITLE'),
      subtitle: t('ACCOUNT.IDENTITY.SUBTITLE'),
      status: verification?.identity,
      icon: personIcon,
      buttonText: '',
      route: routes.accountIdentity,
      disabled: false,
    },
    {
      title: t('ACCOUNT.ADDRESS.TITLE'),
      subtitle: t('ACCOUNT.ADDRESS.SUBTITLE'),
      status: verification?.address,
      icon: locationIcon,
      buttonText: '',
      route: routes.accountAddress,
      disabled: false,
    },
    ...(isStablecoin ? [] : [bankData]),
    {
      title: t('ACCOUNT.TFA.TITLE'),
      subtitle: t('ACCOUNT.TFA.SUBTITLE'),
      status: verification?.twofa,
      icon: keyIcon,
      buttonText: '',
      route: routes.accountTwoFactor,
      disabled: false,
    },
  ]
}
