import { useEffect, useState } from 'react'
import { onSnapshot } from 'firebase/firestore'

import { useLang } from 'hooks/lang'
import { buildQuery } from 'api/events'
import { useAuth } from './auth'
import { prepareEventData } from 'utils/prepareEventData'

export function useEventLog({ limitEvents = 5, acknowledged = true, filter = [], sortOrder = 'desc' } = {}) {
  const { translate: t } = useLang()
  const { uid } = useAuth()

  const [eventLog, setEventLog] = useState([])

  useEffect(() => {
    let unsubscribe = () => {}
    if (!uid) return unsubscribe()

    let eventRef = buildQuery({ uid, acknowledged, sortOrder, limitEvents, filter })

    unsubscribe = onSnapshot(eventRef, (snap) => {
      setEventLog(snap.docs.map((snap) => ({ ...prepareEventData({ data: snap.data(), t }), id: snap.id })))
    })

    return () => {
      unsubscribe()
    }
  }, [uid])

  return { eventLog }
}
