import styled from 'styled-components/macro'
import { device } from 'constants/media'
import { colors } from 'constants/colors'

export const HistoryH2 = styled.h2`
  font-weight: 500;
  font-size: 12px;
  margin-bottom: 16px;
  text-transform: uppercase;
  color: ${colors.gray500};

  @media ${device.mobile} {
    font-size: 10px;
    margin-bottom: 10px;
  }
`
