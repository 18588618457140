import bn from 'bignumber.js'
import { formatCalculationData } from 'utils/formatCalculationData'

const filterMobileData = (data) => {
  const result = []
  const filterData = data
    .map((arr) => {
      return arr.filter((item) => item.isCompact)
    })
    .filter((data) => data.length > 0)

  filterData.forEach((arr) => {
    arr.forEach((item) => {
      result.push(item)
    })
  })

  return result
}

const ordinalDate = (day) => {
  var s = ['th', 'st', 'nd', 'rd']
  var v = day % 100
  return s[(v - 20) % 10] || s[v] || s[0]
}

const todayDate = new Date().getDate()

export const prepareOpenCreditData = ({ calculationData, differenceCalc, currencyIcon, t, compact }) => {
  const formatCalculation = formatCalculationData(calculationData)
  const formatDifference = formatCalculationData(differenceCalc)

  const data = [
    [
      {
        testId: 'loan-amount',
        title: t('CREDIT_LINE.SUMMARY.BORROW.TITLE'),
        value: formatCalculation?.loanAmount,
        greenValue: null,
        infoText: '',
        suffix: currencyIcon,
      },
      {
        testId: 'collateral-btc',
        title: t(`CREDIT_LINE.SUMMARY.AMOUNT_TO_TRANSFER.TITLE.OPEN_CREDIT`),
        value: formatCalculation?.collateralBTC || differenceCalc?.collateralBTC,
        greenValue: null,
        infoText: '',
        suffix: 'BTC',
        hasChanged: !!differenceCalc?.collateralBTC,
      },
    ],
    [
      {
        title: t(`CREDIT_LINE.SUMMARY.NEW_LOAN.TITLE.OPEN_CREDIT`),
        value:
          formatCalculation?.ltv !== 'N/A' || !formatDifference?.ltv ? formatCalculation?.ltv : formatDifference?.ltv,
        greenValue: null,
        infoText: t(`CREDIT_LINE.SUMMARY.NEW_LOAN.HINT.OPEN_CREDIT`),
        suffix: '%',
        hasChanged: !!differenceCalc?.ltv,
        isCompact: true,
      },
      {
        title: t(`CREDIT_LINE.SUMMARY.CURRENT_BTC.TITLE.OPEN_CREDIT`),
        value: formatCalculation?.collateralEUR || formatDifference?.collateralEUR,
        greenValue: null,
        infoText: t(`CREDIT_LINE.SUMMARY.CURRENT_BTC.HINT.OPEN_CREDIT`),
        suffix: currencyIcon,
        hasChanged: !!differenceCalc?.collateralEUR,
        isCompact: true,
      },
      {
        title: t(`CREDIT_LINE.SUMMARY.LIQUIDATION_PRICE.TITLE.OPEN_CREDIT`),
        value:
          formatCalculation?.liquidationPrice !== 'N/A' || !formatDifference?.liquidationPrice
            ? formatCalculation?.liquidationPrice
            : formatDifference?.liquidationPrice,
        greenValue: formatCalculation?.liquidationPriceChange,
        infoText: t(`CREDIT_LINE.SUMMARY.LIQUIDATION_PRICE.HINT.OPEN_CREDIT`),
        suffix: currencyIcon,
        hasChanged: !!differenceCalc?.liquidationPrice,
        isCompact: true,
      },
      {
        title: t(`CREDIT_LINE.SUMMARY.LIQUIDATION_THRESHOLD.TITLE.OPEN_CREDIT`),
        value: formatCalculation?.liquidationThreshold === '0' ? '90' : formatCalculation?.liquidationThreshold,
        greenValue: null,
        infoText: t(`CREDIT_LINE.SUMMARY.LIQUIDATION_THRESHOLD.HINT.OPEN_CREDIT`),
        suffix: '%',
      },
    ],
    [
      {
        title: t('CREDIT_LINE.SUMMARY.INTEREST_FEE.TITLE'),
        value:
          formatCalculation?.monthlyFee === '0.00'
            ? 'N/A'
            : formatCalculation?.monthlyFee || formatDifference?.monthlyFee,
        greenValue: null,
        infoText: t('CREDIT_LINE.SUMMARY.INTEREST_FEE.HINT'),
        suffix: currencyIcon,
        hasChanged: !!differenceCalc?.monthlyFee,
        isCompact: true,
      },
      {
        title: t('CREDIT_LINE.SUMMARY.INTEREST_RATE.TITLE'),
        value: formatCalculation?.interestRate,
        greenValue: null,
        infoText: '',
        suffix: '%',
        hasChanged: false,
      },
      {
        title: t('CREDIT_LINE.SUMMARY.INTEREST_PAYMENT_DAY.TITLE'),
        value: calculationData?.paymentDay || todayDate,
        greenValue: null,
        infoText: t('CREDIT_LINE.SUMMARY.INTEREST_PAYMENT_DAY.HINT'),
        suffix: ordinalDate(calculationData?.paymentDay || todayDate),
        hasChanged: false,
      },
    ],
  ]

  return compact ? [filterMobileData(data)] : data
}

export const prepareDepositCollateral = ({ depositCollateral, liveCalc, position, currencyIcon, t, compact }) => {
  const formatLiveCalculation = formatCalculationData(liveCalc)
  const formatPosition = formatCalculationData(position)

  const data = [
    [
      {
        title: t('LOAN.DEPOSIT.SUMMARY.COLLATERAL_BTC.AMOUNT_TO_TRANSFER'),
        value: depositCollateral || 0.0,
        titleGray: t('LOAN.DEPOSIT.SUMMARY.COLLATERAL_BTC.NEW'),
        valueGray: Number(
          bn(formatPosition?.collateralBTC)
            .plus(depositCollateral || 0)
            .toFixed(8)
        ), // new collateral BTC
        greenValue: null,
        infoText: '',
        suffix: 'BTC',
      },
    ],
    [
      {
        title: t('LOAN.DEPOSIT.SUMMARY.LTV.NEW'),
        value: formatLiveCalculation?.ltv,
        titleGray: t('LOAN.DEPOSIT.SUMMARY.LTV.CURRENT'),
        valueGray: formatPosition?.ltv,
        greenValue: null,
        infoText: t('LOAN.DEPOSIT.SUMMARY.LTV.HINT'),
        suffix: '%',
        isCompact: true,
      },
      {
        title: t('LOAN.DEPOSIT.SUMMARY.COLLATERAL_EUR.CURRENT'),
        value: bn(formatLiveCalculation?.collateralEUR).eq(0)
          ? formatPosition?.collateralEUR
          : formatLiveCalculation?.collateralEUR,
        titleGray: null,
        valueGray: null,
        greenValue: null,
        infoText: t('LOAN.DEPOSIT.SUMMARY.COLLATERAL_EUR.HINT'),
        suffix: currencyIcon,
        isCompact: true,
      },
      {
        title: t('LOAN.DEPOSIT.SUMMARY.LIQUIDATION_PRICE.NEW'),
        value: formatLiveCalculation?.liquidationPrice,
        titleGray: t('LOAN.DEPOSIT.SUMMARY.LIQUIDATION_PRICE.CURRENT'),
        valueGray: formatPosition?.liquidationPrice,
        greenValue: formatLiveCalculation?.liquidationPriceChange,
        infoText: t('LOAN.DEPOSIT.SUMMARY.LIQUIDATION_PRICE.HINT'),
        suffix: currencyIcon,
        isCompact: true,
      },
      {
        title: t('LOAN.DEPOSIT.SUMMARY.LIQUIDATION_THRESHOLD.TEXT'),
        value: formatLiveCalculation?.liquidationThreshold,
        titleGray: null,
        valueGray: null,
        greenValue: null,
        infoText: t('LOAN.DEPOSIT.SUMMARY.LIQUIDATION_THRESHOLD.HINT'),
        suffix: '%',
      },
    ],
  ]

  return compact ? [filterMobileData(data)] : data
}

export const prepareBorrowMoreData = ({ borrow, collateralBtc, liveCalc, position, currencyIcon, t, compact }) => {
  const formatLiveCalculation = formatCalculationData(liveCalc)
  const formatPosition = formatCalculationData(position)

  const data = [
    [
      {
        title: t('LOAN.BORROW.SUMMARY.BORROW.INPUT'),
        value: borrow || 0.0,
        titleGray: t('LOAN.BORROW.SUMMARY.BORROW.NEW'),
        valueGray: bn(formatPosition?.loanAmount)
          .plus(borrow || 0)
          .toFixed(0),
        greenValue: null,
        infoText: '',
        suffix: currencyIcon,
      },
    ],
    [
      {
        title: t('LOAN.BORROW.SUMMARY.LTV.NEW'),
        value: formatLiveCalculation?.ltv,
        titleGray: t('LOAN.BORROW.SUMMARY.LTV.CURRENT'),
        valueGray: formatPosition?.ltv,
        greenValue: null,
        infoText: t('LOAN.BORROW.SUMMARY.LTV.HINT'),
        suffix: '%',
        isCompact: true,
      },
      {
        title: t('LOAN.BORROW.SUMMARY.LIQUIDATION_PRICE.NEW'),
        value: formatLiveCalculation?.liquidationPrice,
        titleGray: t('LOAN.BORROW.SUMMARY.LIQUIDATION_PRICE.CURRENT'),
        valueGray: formatPosition?.liquidationPrice,
        greenValue: formatLiveCalculation?.liquidationPriceChange,
        infoText: t('LOAN.BORROW.SUMMARY.LIQUIDATION_PRICE.HINT'),
        suffix: currencyIcon,
        isCompact: true,
      },
      {
        title: t('LOAN.BORROW.SUMMARY.LIQUIDATION_THRESHOLD.TEXT'),
        value: formatLiveCalculation?.liquidationThreshold === '0' ? '90' : formatLiveCalculation?.liquidationThreshold,
        titleGray: null,
        valueGray: null,
        greenValue: null,
        infoText: t('LOAN.BORROW.SUMMARY.LIQUIDATION_THRESHOLD.HINT'),
        suffix: '%',
      },
    ],
    [
      {
        title: t('LOAN.BORROW.SUMMARY.INTEREST_FEE.NEW'),
        value: formatLiveCalculation?.monthlyFee === '0.00' ? 'N/A' : formatLiveCalculation?.monthlyFee,
        titleGray: t('LOAN.BORROW.SUMMARY.INTEREST_FEE.CURRENT'),
        valueGray: formatPosition?.monthlyFee,
        greenValue: null,
        infoText: t('LOAN.BORROW.SUMMARY.INTEREST_FEE.HINT'),
        suffix: currencyIcon,
        hasChanged: false,
        isCompact: true,
      },
      {
        title: t('LOAN.BORROW.SUMMARY.INTEREST_RATE.TEXT'),
        value: formatPosition?.interestRate,
        greenValue: null,
        infoText: '',
        suffix: '%',
        hasChanged: false,
      },
      {
        title: t('LOAN.BORROW.SUMMARY.PAYMENT_DAY.TEXT'),
        value: formatPosition?.paymentDay || todayDate,
        greenValue: null,
        infoText: t('CREDIT_LINE.SUMMARY.PAYMENT_DAY.HINT'),
        suffix: ordinalDate(formatPosition?.paymentDay || todayDate),
        hasChanged: false,
      },
    ],
  ]

  if (collateralBtc) {
    data[0].push({
      title: t('LOAN.DEPOSIT.SUMMARY.COLLATERAL_BTC.AMOUNT_TO_TRANSFER'),
      value: collateralBtc,
      titleGray: t('LOAN.DEPOSIT.SUMMARY.COLLATERAL_BTC.NEW'), // additional collateral BTC
      valueGray: Number(bn(formatPosition?.collateralBTC).plus(collateralBtc).toFixed(8)), // sum collateral BTC
      greenValue: null,
      infoText: '',
      suffix: 'BTC',
    })
    data[0].push({
      title: 'BTC price',
      value: formatPosition?.btcRate,
      greenValue: null,
      infoText: '',
      suffix: currencyIcon,
      hasChanged: false,
    })
  }

  return compact ? [filterMobileData(data)] : data
}
