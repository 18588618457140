import React, { useState, useEffect, useRef } from 'react'
import NumberFormat from '../NumberFormat'

import { InputWrap, InputEl, Label, InputBox, InputCopy, InputClear, FancyInput, FancyInputWithError } from './style'

const Input = ({
  type,
  label,
  placeholder,
  autoFocus,
  disabled,
  required,
  value,
  error,
  readOnly,
  onChange,
  onKeyDown,
  onKeyUp,
  enableCopy,
  btcCollateral,
  decimalScale,
  disableClearable,
  focus,
}) => {
  const [clearVisibility, setClearVisibility] = useState(false)
  const [inputVal, setInputVal] = useState(value)
  const field = useRef()

  useEffect(() => {
    setInputVal(value)

    if (value?.length > 0) {
      setClearVisibility(true)
    } else {
      setClearVisibility(false)
    }
  }, [value])

  const clearValue = () => {
    setInputVal('')
    setClearVisibility(false)
  }

  const copyValue = () => {
    field.current.select()
    field.current.setSelectionRange(0, 99999)
    document.execCommand('copy')
  }

  return (
    <InputWrap error={error}>
      <Label disabled={disabled}>{label}</Label>
      <InputBox>
        {btcCollateral ? (
          <NumberFormat
            testId="btc-collateral-input"
            displayType="input"
            value={inputVal}
            placeholder={placeholder}
            onValueChange={onChange}
            decimalScale={decimalScale}
            allowNegative={false}
            allowLeadingZeros={true}
            customInput={error ? FancyInputWithError : FancyInput}
            autoFocus={focus}
            inputMode="decimal"
          />
        ) : (
          <InputEl
            ref={field}
            label={label}
            type={type || 'text'}
            placeholder={placeholder}
            autoFocus={autoFocus}
            disabled={disabled}
            required={required}
            value={inputVal}
            error={error}
            readOnly={readOnly || enableCopy}
            onChange={onChange}
            onKeyDown={onKeyDown}
            onKeyUp={onKeyUp}
          />
        )}
        {enableCopy && <InputCopy onClick={copyValue} />}
        {clearVisibility && !enableCopy && !disableClearable && !disabled && <InputClear onClick={clearValue} />}
      </InputBox>
    </InputWrap>
  )
}

export default Input
