import React from 'react'
import { NumericFormat } from 'react-number-format'

const NumberFormat = ({
  customInput,
  placeholder,
  onValueChange,
  onMouseUp,
  onFocus,
  suffix,
  value,
  autoFocus,
  displayType,
  decimalSeparator,
  decimalScale,
  allowNegative,
  allowLeadingZeros,
  testId,
  thousandSeparator,
  pattern,
  inputMode,
}) => {
  const separator = thousandSeparator === undefined ? ' ' : thousandSeparator === false ? false : thousandSeparator

  return (
    <NumericFormat
      data-testid={testId}
      customInput={customInput}
      placeholder={placeholder}
      onValueChange={onValueChange}
      onMouseUp={onMouseUp}
      thousandSeparator={separator}
      suffix={suffix}
      displayType={displayType}
      value={value}
      autoFocus={autoFocus}
      decimalSeparator={decimalSeparator}
      decimalScale={decimalScale}
      allowNegative={allowNegative}
      allowLeadingZeros={allowLeadingZeros}
      allowedDecimalSeparators={[',']}
      onFocus={onFocus}
      inputMode={inputMode ? inputMode : 'numeric'}
      pattern={pattern}
    />
  )
}

export default NumberFormat
