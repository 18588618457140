export const colors = {
  white: '#ffffff',
  gray50: '#f9fafa',
  gray100: '#f3f4f5',
  gray150: '#e9eaeb',
  gray200: '#dadee1',
  gray300: '#c9cdd1',
  gray400: '#959ea6',
  gray500: '#777f87',
  gray600: '#5e666e',
  gray700: '#444C53',
  gray800: '#374149',
  gray900: '#2d353e',
  gray950: '#151f29',

  green50: '#f2faf8',
  green100: '#def1ec',
  green200: '#bce2da',
  green500: '#4b9c8a',
  green800: '#1a3731',

  yellow100: '#fdf2d5',
  yellow200: '#fce5aa',
  yellow500: '#e5b128',
  yellow800: '#4a390d',

  orange400: '#e59477',

  red50: '#faf2f2',
  red200: '#f3bdbd',
  red400: '#e15959',
  red500: '#bf4c4c',
  red600: '#9e3e3e',
  red700: '#712c2c',

  purple100: '#e5e5f6',
  purple200: '#cbcbed',
  purple400: '#7e7cd2',
  purpleDark: '#b2b0e4',
  purple800: '#26253f',

  blue500: '#77a0c7',
}
