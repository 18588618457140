import { useEffect, useState } from 'react'
import { onAuthStateChanged, signInWithCustomToken, getIdTokenResult } from 'firebase/auth'
import * as Sentry from '@sentry/react'

import { auth } from 'configs/firebase'
import { errorCapture } from 'utils/errorCapture'

const moment = require('moment')

export function useAuth() {
  const [uid, setUid] = useState(null)
  const [isLoggedIn, setIsLoggedIn] = useState(null)

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUid(user.uid)
        Sentry.setUser({ id: user.uid })
        setIsLoggedIn(true)
      } else {
        setUid(null)
        Sentry.configureScope((scope) => scope.setUser(null))
        setIsLoggedIn(false)
      }
    })
    // Cleanup subscription on unmount
    return () => unsubscribe()
  }, [])

  return { uid, isLoggedIn }
}

/**
 * function to define are user token is claimed for private actions
 * @param {string} claim
 * 'accessPersonalDataUntil' - for access to get personal data, ex. IdentityData or AddressData
 *
 * 'resetTwoFAUntil' - for access to reset user AuthApp (generate new 2fa secret version) or
 *  to generate new Back up codes
 * @returns {boolean} boolean
 */
export async function checkUserAuthToken({ claim }) {
  const user = auth.currentUser
  try {
    const idTokenResult = await getIdTokenResult(user)
    return idTokenResult.claims[claim] > moment.utc().valueOf()
  } catch (error) {
    errorCapture(error)
    return false
  }
}

export async function authWithCustomToken({ token }) {
  await signInWithCustomToken(auth, token)
}
