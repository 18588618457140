import React from 'react'

import { CheckboxWrap, CheckboxIcon, CheckboxText } from './style'

const Checkbox = ({ children, checked, label, onChange, disabled }) => {
  return (
    <CheckboxWrap disabled={disabled} onClick={disabled ? null : onChange}>
      <CheckboxIcon disabled={disabled} checked={checked} />
      <CheckboxText disabled={disabled}>{label || children}</CheckboxText>
    </CheckboxWrap>
  )
}

export default Checkbox
