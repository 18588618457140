import React from 'react'

import { useLang } from 'hooks/lang'
import { useProfileContext } from 'context/profile'
import { useLangContext } from 'context/lang'

import MainLayout from 'layouts/MainLayout'
import NotesPageTemplate from 'components/NotesPageTemplate'

import routes from 'constants/routes'
import { formatNumberByLocale } from 'utils/formatNumberByLocale'

const CreditConfirmation = () => {
  const { currency, contractDraft, haveAllConfirms } = useProfileContext()
  const { lang } = useLangContext()
  const { translate: t } = useLang()

  if (!haveAllConfirms) return

  const text_1 = t('CREDIT_CONFIRMATION.TEXT_1')
  const text_2 = t('CREDIT_CONFIRMATION.TEXT_2')

  const amount = formatNumberByLocale({ lang, currency, number: contractDraft?.loanAmount })

  // NOTE: To make texts an array with one string we join into one complete string and then
  // split by random symbol - &&
  const texts = text_1.concat(amount).concat(text_2).join(' ').split('&&')

  return (
    <MainLayout mobileTitle={t('CREDIT_CONFIRMATION.TITLE_BLACK')}>
      <NotesPageTemplate
        title={t('CREDIT_CONFIRMATION.TITLE_BLACK')}
        texts={texts}
        buttonText={t('CREDIT_CONFIRMATION.BUTTON')}
        link={routes.main}
        img="credit"
      />
    </MainLayout>
  )
}

export default CreditConfirmation
