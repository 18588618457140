import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { useProfileContext } from 'context/profile'
import { useDevice } from 'hooks/media'
import { useLang } from 'hooks/lang'

import { Row, Col } from 'ui-kit/components/Grid'
import Info from 'ui-kit/components/Info'
import Input from 'ui-kit/components/Input'
import Notification from 'ui-kit/components/Notification'

import ContentMobile from 'components/ContentMobile'

import SubLayout from 'layouts/SubLayout'
import { TitleGrey, TitleBlack, MarginWrap } from 'layouts/SubLayout/style'

import PositionSummary from 'pages/Loan/components/PositionSummary'
import TransferStep from 'pages/Loan/components/TransferStep'
import { CalcResultTitle } from 'pages/Loan/style'
import {
  TransferBtc,
  TransferInput,
  TransferNotification,
  TransferSubtitle,
  TransferTop,
} from 'pages/Loan/components/TransferStep/style'

import routes from 'constants/routes'

const TransactionInfo = () => {
  const isMobile = useDevice()
  const navigate = useNavigate()
  const { translate: t } = useLang()
  const { isLoaded, contractDraft, position, unconfirmedTx, creditStatus } = useProfileContext()

  const [loaded, setLoading] = useState(!isLoaded)

  const creditState = 'OPEN_CREDIT'
  const isTransactionPending = creditStatus === 'btcCollateralPending'
  const isWaitingTransaction = creditStatus === 'pending'
  const isStatusNotAllowed = creditStatus && !isWaitingTransaction && !isTransactionPending

  useEffect(() => {
    if (isStatusNotAllowed) navigate(routes.main)
  }, [])

  return (
    <SubLayout isLoading={loaded} onClickBack={() => navigate(routes.main)} hiddenNavigation>
      <Row justifyContent="center">
        {/* LEFT SIDE */}
        {isWaitingTransaction && (
          <Col lg={50} sm={100}>
            <TransferStep
              transferAmount={contractDraft?.collateralBTC || position?.collateralBTC}
              setLoading={setLoading}
              creditState={creditStatus}
            />
          </Col>
        )}

        {isTransactionPending && (
          <Col lg={50} sm={100}>
            {!isMobile && <TitleGrey>{t(`CREDIT_LINE.CALC_STEP.TITLE_BLACK.${creditState}`)}</TitleGrey>}
            <ContentMobile>
              <Row alignItems="baseline">
                <Col sm={100}>
                  <TitleBlack>{t('CREDIT_LINE.TRANSACTION_INFO.TITLE')}</TitleBlack>
                </Col>
                {!isMobile && (
                  <Col>
                    <Info text={t(`CREDIT_LINE.CALC_STEP.TITLE_BLACK_HINT.${creditState}`)} />
                  </Col>
                )}
              </Row>
              <MarginWrap margin="0 0 16px">
                <TransferTop>
                  <TransferSubtitle>{t('CREDIT_LINE.TRANSFER_STEP.SUBTITLE_1')}</TransferSubtitle>
                  <TransferBtc>
                    {unconfirmedTx?.amount} {'BTC'}
                  </TransferBtc>
                </TransferTop>
              </MarginWrap>
              <TransferNotification>
                <Notification variant="green">{t('CREDIT_LINE.TRANSACTION_INFO.NOTIFICATION')}</Notification>
              </TransferNotification>
            </ContentMobile>
            <ContentMobile>
              <MarginWrap margin="0 0 12px">
                <Row alignItems="center" m={-2}>
                  <Col p={2}>
                    <TransferSubtitle>{t('CREDIT_LINE.TRANSACTION_INFO.INPUT_TITLE')}</TransferSubtitle>
                  </Col>
                  <Col p={2}>
                    <Info text={t('CREDIT_LINE.TRANSFER_STEP.SUBTITLE_2_HINT')} />
                  </Col>
                </Row>
              </MarginWrap>
              <TransferInput>
                <Input
                  label={t('CREDIT_LINE.TRANSACTION_INFO.INPUT_LABEL')}
                  value={unconfirmedTx?.txHash || ''}
                  readOnly
                  enableCopy
                />
              </TransferInput>
            </ContentMobile>
          </Col>
        )}

        {/* RIGHT SIDE */}
        {!isMobile && (
          <Col lg={50}>
            <CalcResultTitle>{t('CREDIT_LINE.SUMMARY.TITLE')}</CalcResultTitle>
            <PositionSummary />
          </Col>
        )}
      </Row>
    </SubLayout>
  )
}

export default TransactionInfo
