import styled from 'styled-components/macro'
import { colors } from 'constants/colors'
import checkboxEmptyDisabled from './assets/checkbox-empty-disabled.svg'
import checkboxEmpty from './assets/checkbox-empty.svg'
import checkboxFilledDisabled from './assets/checkbox-filled-disabled.svg'
import checkboxFilled from './assets/checkbox-filled.svg'

export const CheckboxWrap = styled.div`
  display: grid;
  grid-auto-flow: column;
  cursor: default;

  &:after {
    content: '';
    clear: both;
    display: block;
  }
`
export const CheckboxIcon = styled.div`
  width: 20px;
  height: 20px;
  margin-right: 8px;
  background-repeat: no-repeat;
  background-image: url(${(p) =>
    p.checked && !p.disabled
      ? checkboxFilled
      : p.disabled && !p.checked
      ? checkboxEmptyDisabled
      : p.disabled && p.checked
      ? checkboxFilledDisabled
      : checkboxEmpty});
  float: left;
  cursor: pointer;
`

export const CheckboxText = styled.div`
  color: ${(p) => (p.disabled ? colors.gray300 : colors.gray600)};
`
