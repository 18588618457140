import styled from 'styled-components/macro'
import { colors } from 'constants/colors'
import { device } from 'constants/media'

export const SwitcherWrapper = styled.div`
  padding: 16px 0;
  margin: 12px 0;

  @media ${device.mobile} {
    padding: 0;
    margin: 0;
  }
`

export const SwitcherButtons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: 4px;
  border-radius: 4px;
  background-color: ${colors.gray100};
`

export const SwitcherButtonsItem = styled.div`
  width: 100%;
  text-align: center;
  padding: 6px 0;
  border-radius: 2px;
  background-color: ${(p) => (p.active ? colors.white : colors.gray100)};
  cursor: pointer;
`
