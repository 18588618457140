import React from 'react'
import NumericList from 'ui-kit/components/NumericList'
import { Row, Col } from 'ui-kit/components/Grid'
import Input from 'ui-kit/components/Input'

import { useDevice } from 'hooks/media'
import { useLang } from 'hooks/lang'

import ContentMobile from 'components/ContentMobile'
import TwofaWidget from 'widgets/TwofaWidget'

import { TitleBlack } from 'layouts/SubLayout/style'
import { QRCodeWrapper, QRCodeTitle, QRCodeImg } from '../style'

const VerificationCodeStep = ({
  secretData,
  qrCodeUrl,
  onSetSecretData,
  onSetPageLoading,
  showCountdown,
  countdownCounter,
}) => {
  const isMobile = useDevice()
  const { translate: t } = useLang()

  return (
    <>
      <Row justifyContent="start" m={-12} flexDirection={isMobile ? 'row' : 'row-reverse'}>
        <Col lg={50} sm={100} p={12} m={!isMobile && 88}>
          <ContentMobile>
            {isMobile && <TitleBlack>{t('TFA_VERIFICATION.TITLE_BLACK')}</TitleBlack>}
            <NumericList
              title={t('TFA_VERIFICATION.NUMERIC_LIST.TITLE')}
              text={t('TFA_VERIFICATION.NUMERIC_LIST.POINTS')}
            />
          </ContentMobile>
          <ContentMobile>
            <Input label={t('TFA_VERIFICATION.TOKEN.LABEL')} value={secretData.secret || ''} readOnly enableCopy />
            {qrCodeUrl && (
              <QRCodeWrapper>
                <QRCodeTitle>Or use the QR code</QRCodeTitle>
                <QRCodeImg src={qrCodeUrl} alt="qr-code" />
              </QRCodeWrapper>
            )}
          </ContentMobile>
        </Col>
        <Col lg={50} sm={100} p={12}>
          <TwofaWidget
            titleGrey={t('TFA_VERIFICATION.TITLE_GREY')}
            titleBlack={t('TFA_VERIFICATION.TITLE_BLACK')}
            hiddenTitle={isMobile}
            onSetSecretData={onSetSecretData}
            onSetPageLoading={onSetPageLoading}
            onInput={() => {}}
            hiddenTwofaText
            isTwoFactorPage
            showCountdown={showCountdown || false}
            countdownCounter={countdownCounter}
          />
        </Col>
      </Row>
    </>
  )
}

export default VerificationCodeStep
