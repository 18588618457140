import styled from 'styled-components/macro'
import { device } from 'constants/media'

export const ColEl = styled.div`
  padding: ${(p) => (p.p ? `0 ${p.p}px` : '0 8px')};
  margin: ${(p) => (p.m ? `${p.m}px 0` : '0')};
  width: ${(p) => (p.lg ? `${p.lg}%` : 'auto')};
  order: ${(p) => p.order};

  @media ${device.mobile} {
    width: ${(p) => (p.sm ? `${p.sm}%` : 'auto')};
    ${(p) => p.pSm && `padding: 0 ${p.pSm}px`};
  }
`
