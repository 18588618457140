import React from 'react'
import { useNavigate } from 'react-router-dom'
import Button from 'ui-kit/components/Button'

import { useLang } from 'hooks/lang'
import routes from 'constants/routes'

import { NotFoundWrap, NotFoundContent, NotFoundTitle, NotFoundSubtitle, NotFoundLink } from './style'

const NotFound = () => {
  const { translate: t } = useLang()
  const navigate = useNavigate()
  return (
    <NotFoundWrap>
      <NotFoundContent>
        <NotFoundTitle>{t('NOT_FOUND.TITLE')}</NotFoundTitle>
        <NotFoundSubtitle>{t('NOT_FOUND.SUBTITLE')}</NotFoundSubtitle>
        <NotFoundLink>
          <Button variant="filled" text={t('NOT_FOUND.LINK')} onClick={() => navigate(routes.main)} />
        </NotFoundLink>
      </NotFoundContent>
    </NotFoundWrap>
  )
}

export default NotFound
