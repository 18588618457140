import styled from 'styled-components/macro'

import { colors } from 'constants/colors'

export const VeriffStepDescription = styled.div`
  color: ${colors.gray800};
`

export const VerificationResult = styled.div`
  display: flex;
  margin: 24px 0;
`

export const VerificationResultIcon = styled.div`
  width: 20px;
  height: 20px;
  margin-right: 10px;
  background-image: url(${(p) => p.icon});
`

export const VerificationResultText = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: ${(p) => p.color};
`
