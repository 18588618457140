import styled from 'styled-components/macro'
import { colors } from 'constants/colors'

import arrowDownGreen from './assets/arrow-down-green.svg'

export const GreenArrowEl = styled.div`
  color: ${colors.green500};
  font-weight: 500;
  font-size: 10px;
  line-height: 16px;

  &:before {
    content: '';
    width: 16px;
    height: 16px;
    display: inline-block;
    vertical-align: top;
    background-image: url(${arrowDownGreen});
    background-repeat: no-repeat;
    background-position: center;
  }
`
