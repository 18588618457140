import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import QRCode from 'qrcode'
import { Row, Col } from 'ui-kit/components/Grid'

import { checkUserAuthToken } from 'hooks/auth'
import { useLang } from 'hooks/lang'
import { errorCapture } from 'utils/errorCapture'

import SubLayout from 'layouts/SubLayout'
import TwofaWidget from 'widgets/TwofaWidget'

import routes from 'constants/routes'
import VerificationCodeStep from 'pages/VerificationProcess/TwoFactor/components/VerificationCodeStep'
import { resetTwoFASecret } from 'api/twofa'

export const TwoFaAuthApp = () => {
  const navigate = useNavigate()
  const { translate: t } = useLang()

  const initSecretState = { secret: null, url: null }

  const [loading, setLoading] = useState(false)
  const [newSecretData, setNewSecretData] = useState(initSecretState)
  const [qrCodeUrl, setQRCodeUrl] = useState(null)
  const [isUserClaimed, setUserClaimed] = useState(false)
  const [counter, setCounter] = useState(900)

  useEffect(() => {
    if (counter === 0) {
      setUserClaimed(false)
    } else {
      const timer = counter > 0 && setInterval(() => setCounter(counter - 1), 1000)
      return () => clearInterval(timer)
    }
  }, [counter])

  useEffect(() => {
    async function checkUserGrants() {
      const isTokenClaimed = await checkUserAuthToken({ claim: 'resetTwoFAUntil' })
      setUserClaimed(isTokenClaimed)
    }
    checkUserGrants()
  }, [])

  useEffect(() => {
    async function fetchTwoFactorSecret() {
      if (isUserClaimed) {
        setLoading(true)

        await resetTwoFASecret()
          .then((resp) => {
            if (resp.data) setNewSecretData({ secret: resp.data.secret, url: resp.data.url })
          })
          .catch((error) => {
            errorCapture(error)
          })

        setLoading(false)
      }
    }
    fetchTwoFactorSecret()
  }, [isUserClaimed])

  useEffect(() => {
    if (newSecretData.url) {
      QRCode.toDataURL(newSecretData.url)
        .then((url) => {
          setQRCodeUrl(url)
        })
        .catch((error) => {
          errorCapture(error)
        })
    }
  }, [newSecretData])

  const hideTwofaWidget = () => {
    setUserClaimed(true)
  }

  const handelSuccessConfirm = () => {
    setNewSecretData(initSecretState)
    navigate(routes.accountTwoFactor)
  }

  return (
    <SubLayout
      isLoading={loading}
      customRoute={routes.accountTwoFactor}
      customBackToText={t('ACCOUNT.TFA.BACK_TO_TFA')}
      hiddenNavigation
    >
      {isUserClaimed ? (
        <VerificationCodeStep
          secretData={newSecretData}
          qrCodeUrl={qrCodeUrl}
          onSetSecretData={handelSuccessConfirm}
          onSetPageLoading={setLoading}
          showCountdown
          countdownCounter={counter}
        />
      ) : (
        <Row justifyContent="center">
          <Col lg={50} sm={100}>
            <TwofaWidget
              onSuccess={hideTwofaWidget}
              onSetPageLoading={setLoading}
              accessType="resetTwoFA"
              subtitle={t('TFA_WIDGET.SUBTITLE.TFA')}
            />
          </Col>
        </Row>
      )}
    </SubLayout>
  )
}

export default TwoFaAuthApp
