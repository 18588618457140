import styled from 'styled-components/macro'
import { ContentTemplate } from 'pages/commonStyle'
import { colors } from 'constants/colors'
import { device } from 'constants/media'

import arrowIcon from 'assets/icons/arrow-left-gray.svg'
import closeIcon from 'assets/icons/cross-black.svg'

export const Wrapper = styled.div`
  height: inherit;
  background-color: ${(p) => (p.bgColor ? p.bgColor : colors.white)};

  @media ${device.mobile} {
    height: unset;
    background-color: ${colors.gray100};
    overflow-x: hidden;
  }
`

export const HeaderContent = styled(ContentTemplate)`
  @media ${device.mobile} {
    top: 18px;
    height: 0;
    z-index: 1;
  }
`
export const BackButton = styled.div`
  padding-left: 20px;
  background-image: url(${arrowIcon});
  background-repeat: no-repeat;
  background-position: 0 50%;
  cursor: pointer;
  display: inline-block;
  margin: 30px 0 40px;

  @media ${device.mobile} {
    margin: 0;
    width: 20px;
    height: 20px;
    background-image: url(${(p) => (p.mobileBackButtonIcon === 'arrow' ? arrowIcon : closeIcon)});
  }
`

export const Fader = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgb(255 255 255 / 65%);
  z-index: 2;
`

export const Content = styled(ContentTemplate)`
  @media ${device.mobile} {
    max-width: 100%;
    padding: 0 0 70px;
  }
`

export const TitleGrey = styled.h2`
  color: ${colors.gray400};
  font-size: 16px;
  font-weight: 400 !important;
  line-height: 20px;
  margin: 0 0 8px 0;
`

export const TitleBlack = styled.h1`
  font-weight: 500;
  font-size: 22px;
  line-height: 24px;
  margin: 0;
  margin-bottom: ${(p) => (p.mb ? `${p.mb}px` : '32px')};

  @media ${device.mobile} {
    font-weight: 400;
    font-size: 14px;
    text-align: center;
    margin-bottom: 20px;
  }
`

export const MarginWrap = styled.div`
  ${(p) => p.margin && `margin: ${p.margin}`};
`
