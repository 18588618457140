import React from 'react'
import { useNavigate } from 'react-router-dom'

import { useProfileContext } from 'context/profile'
import { setOnboardingFinished } from 'api/profile'

import BorrowCalc from 'pages/Loan/components/CreditCalc'

import routes from 'constants/routes'
import { errorCapture } from 'utils/errorCapture'

const OnboardingCalculator = () => {
  const navigate = useNavigate()
  const { uid } = useProfileContext()

  const finishedOnboarding = () => {
    setOnboardingFinished({ uid })
      .then(() => {
        navigate(routes.verification)
      })
      .catch((error) => {
        errorCapture(error)
      })
  }

  return <BorrowCalc dots={[]} onClickBack={() => navigate(routes.main)} onConfirm={finishedOnboarding} />
}

export default OnboardingCalculator
