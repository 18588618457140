import styled from 'styled-components/macro'

import { colors } from 'constants/colors'
import { device } from 'constants/media'
import info from 'assets/icons/info-transparent.svg'
import document from 'assets/icons/document.svg'

export const PolicySubtitle = styled.div`
  margin-bottom: 20px;
`

export const PolicyDocumentLink = styled.div`
  padding: 16px 0;
  cursor: pointer;
  display: flex;
  border-bottom: ${(p) => (p.withBorder ? `1px solid ${colors.gray100}` : 'none')};
`

export const PolicyDocumentIcon = styled.div`
  width: 20px;
  height: 20px;
  margin-right: 8px;
  background-image: url(${(p) => (p.type === 'privacy' ? document : info)});
`

export const PolicyCheckbox = styled.div`
  margin: 16px 0 30px;
`

export const PolicyButton = styled.div`
  @media ${device.mobile} {
    position: absolute;
    left: 16px;
    right: 16px;
    bottom: 0;
    margin-bottom: 16px;
  }
`
