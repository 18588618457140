import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Info from 'ui-kit/components/Info'
import Notification from 'ui-kit/components/Notification'
import Button from 'ui-kit/components/Button'
import { Row, Col } from 'ui-kit/components/Grid'
import Input from 'ui-kit/components/Input'

import { useDevice } from 'hooks/media'
import { useLang } from 'hooks/lang'
import { useVerification } from 'hooks/verification'
import { useProfileContext } from 'context/profile'
import { setAddressData } from 'api/address'
import { errorCapture } from 'utils/errorCapture'

import SubLayout from 'layouts/SubLayout'
import ContentMobile from 'components/ContentMobile'
import NotFound from 'components/NotFound'

import { TitleGrey, TitleBlack } from 'layouts/SubLayout/style'
import {
  InputWrap,
  TitleProof,
  UploadBlock,
  UploadIcon,
  UploadTitle,
  UploadDescription,
  UploadInput,
  UploadButtonWrap,
  UploadDocumentImg,
  UploadDocumentName,
} from './style'

import routes from 'constants/routes'
import statuses from 'constants/statuses'

const initFormState = {
  country: '',
  city: '',
  address: '',
  zipCode: '',
  file: '',
}

const AddressVerification = () => {
  const isMobile = useDevice()
  const navigate = useNavigate()
  const { uid, country, verification } = useProfileContext()
  const { isVerificationDenied } = useVerification()
  const { translate: t } = useLang()

  const [formData, setFormData] = useState(initFormState)
  const [finish, setFinish] = useState(false)
  const [file, setFile] = useState(null)
  const [isLoading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [uploadError, setUploadError] = useState(false)

  const isAccessAllowed = [statuses.notStarted.code, statuses.failed.code].includes(verification.address.code)
  const hasData =
    formData.country.trim().length > 0 &&
    formData.city.trim().length > 0 &&
    formData.address.trim().length > 0 &&
    formData.zipCode.trim().length > 0 &&
    formData.file.trim().length > 0 &&
    file !== null

  useEffect(() => {
    if (isVerificationDenied) navigate(routes.main)
  }, [])

  useEffect(() => {
    if (country) handleFormData('country', country.label)
  }, [country])

  useEffect(() => {
    if (finish && verification.address.code === statuses.pending.code) {
      navigate(routes.verification)
    }
  }, [finish, verification.address])

  const handleConfirm = () => {
    if (!uid) {
      setError(t('ADDRESS_VERIFICATION.NOTIFICATION.ERROR'))
    } else {
      setLoading(true)
      setAddressData({ uid, data: formData, file })
        .then(() => {
          setFinish(true)
        })
        .catch((error) => {
          errorCapture(error)
          setLoading(false)
        })
    }
  }

  const handleFormData = (field, value) => {
    setFormData((prev) => ({
      ...prev,
      [field]: value,
    }))
  }

  const onSelectFile = (event) => {
    if (event.target.files[0].size > 10000000) {
      setUploadError(true)
    } else {
      setUploadError(false)
      setFile(event.target.files[0])
      handleFormData('file', `${uid}/addressProof/${event.target.files[0].name}`)
    }
  }

  const removeFile = () => {
    setFile(null)
    handleFormData('file', '')
  }

  return (
    <>
      {isAccessAllowed ? (
        <SubLayout
          isLoading={isLoading}
          confirmDisabled={!hasData}
          onClickForward={handleConfirm}
          onClickBack={() => navigate(routes.main)}
        >
          <Row justifyContent="center">
            <Col lg={50} sm={100}>
              <ContentMobile>
                {!isMobile && <TitleGrey>{t('ADDRESS_VERIFICATION.TITLE_GREY')}</TitleGrey>}
                <TitleBlack>{t('ADDRESS_VERIFICATION.TITLE_BLACK')}</TitleBlack>
                <div>
                  <InputWrap>
                    <Input label={t('ADDRESS_VERIFICATION.COUNTRY.LABEL')} value={country?.label || ''} disabled />
                  </InputWrap>
                  <InputWrap>
                    <Input
                      label={t('ADDRESS_VERIFICATION.CITY.LABEL')}
                      placeholder={t('ADDRESS_VERIFICATION.CITY.PLACEHOLDER')}
                      value={formData.city}
                      onChange={(e) => handleFormData('city', e.target.value)}
                    />
                  </InputWrap>
                  <InputWrap>
                    <Input
                      label={t('ADDRESS_VERIFICATION.ADDRESS.LABEL')}
                      placeholder={t('ADDRESS_VERIFICATION.ADDRESS.PLACEHOLDER')}
                      value={formData.address}
                      onChange={(e) => handleFormData('address', e.target.value)}
                    />
                  </InputWrap>
                  <InputWrap>
                    <Input
                      label={t('ADDRESS_VERIFICATION.ZIP_CODE.LABEL')}
                      placeholder={t('ADDRESS_VERIFICATION.ZIP_CODE.PLACEHOLDER')}
                      value={formData.zipCode}
                      onChange={(e) => handleFormData('zipCode', e.target.value)}
                    />
                  </InputWrap>
                </div>
              </ContentMobile>
              <ContentMobile>
                <InputWrap>
                  <Row m={-4} alignItems="center">
                    <Col p={4}>
                      <TitleProof>{t('ADDRESS_VERIFICATION.PROOF.TITLE')}</TitleProof>
                    </Col>
                    <Col p={4}>
                      <Info text={t('ADDRESS_VERIFICATION.PROOF.HINT')} />
                    </Col>
                  </Row>
                </InputWrap>
                <UploadBlock>
                  <UploadIcon icon={file ? 'success' : 'document'} />
                  <UploadTitle>
                    {file
                      ? t('ADDRESS_VERIFICATION.DOCUMENT.TITLE.HAVE_FILE')
                      : t('ADDRESS_VERIFICATION.DOCUMENT.TITLE.NO_UPLOAD_FILE')}
                  </UploadTitle>
                  {file ? (
                    <>
                      <UploadDocumentImg />
                      <UploadDocumentName>{file.name}</UploadDocumentName>
                    </>
                  ) : (
                    <UploadDescription>{t('ADDRESS_VERIFICATION.DOCUMENT.DESCRIPTION')}</UploadDescription>
                  )}
                  <Row alignItems="center" justifyContent="center">
                    <Col>
                      <UploadButtonWrap>
                        <UploadInput
                          accept="image/png, image/jpeg, image/jpg, .pdf, image/heic"
                          type="file"
                          name="fileUpload"
                          onChange={onSelectFile}
                        />
                        <Button
                          variant="outlined"
                          text={
                            file
                              ? t('ADDRESS_VERIFICATION.DOCUMENT.BUTTON.HAVE_FILE')
                              : t('ADDRESS_VERIFICATION.DOCUMENT.BUTTON.NO_UPLOAD_FILE')
                          }
                          icon={file ? 'edit' : 'upload'}
                          padding="5px 16px"
                        />
                      </UploadButtonWrap>
                    </Col>
                    {file && (
                      <Col>
                        <Button
                          variant="outlined"
                          noText
                          icon="remove"
                          width="32px"
                          height="32px"
                          onClick={removeFile}
                          padding="0"
                        />
                      </Col>
                    )}
                  </Row>
                </UploadBlock>
                {uploadError && (
                  <Notification variant="red">{t('ADDRESS_VERIFICATION.NOTIFICATION.FILE_EXCEEDS')}</Notification>
                )}
                {error && <Notification variant="red">{error}</Notification>}
              </ContentMobile>
            </Col>
          </Row>
        </SubLayout>
      ) : (
        <NotFound />
      )}
    </>
  )
}

export default AddressVerification
