import styled from 'styled-components/macro'

import { colors } from 'constants/colors'
import { device } from 'constants/media'
import document from 'assets/icons/document.svg'
import download from 'assets/icons/download.svg'

export const FormHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media ${device.mobile} {
    margin: 32px 0 16px;
    width: 100%;
    padding: 0 8px;
  }
`

export const FormTitle = styled.div`
  font-weight: 500;
  font-size: 22px;
  line-height: 24px;

  @media ${device.mobile} {
    font-weight: 500;
    font-size: 18px;
  }
`

export const InputForm = styled.div`
  margin-bottom: 16px;
  opacity: 0.7;

  @media ${device.mobile} {
    margin: 8px 0;
  }
`

export const ButtonWrapper = styled.div`
  margin-right: ${(p) => (p.mr ? `-${p.mr}px` : '')};
`

export const AdditionalData = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 16px;
  padding: ${(p) => (p.isMobile ? '12px' : '12px 16px')};
  background-color: ${colors.white};
  border: 1px solid ${colors.gray150};
  border-radius: 8px;
  cursor: ${(p) => (p.disabled ? 'default' : 'pointer')};
  ${(p) => p.disabled && `opacity: 0.5`};
`

export const Icon = styled.div`
  width: ${(p) => (p.isMobile ? '34px' : '42px')};
  height: ${(p) => (p.isMobile ? '34px' : '42px')};
  background-color: ${colors.gray100};
  background-image: url(${document});
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 4px;
  margin-right: 12px;
`

export const DescriptionWrapper = styled.div`
  display: flex;
  flex-direction: row;
`

export const Description = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: fit-content;
`

export const DescriptionLabel = styled.div`
  color: ${colors.gray950};
`

export const DescriptionItem = styled.div`
  color: ${colors.gray600};
`

export const ActionButton = styled.div`
  width: ${(p) => (p.isMobile ? '24px' : '42px')};
  height: ${(p) => (p.isMobile ? '24px' : '42px')};
  background-image: url(${download});
  background-repeat: no-repeat;
  background-position: center;
`
