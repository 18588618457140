import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import getCalculation from 'calculation-loan-data'
import bn from 'bignumber.js'

import Info from 'ui-kit/components/Info'
import Button from 'ui-kit/components/Button'
import { Row, Col } from 'ui-kit/components/Grid'
import Input from 'ui-kit/components/Input'
import Notification from 'ui-kit/components/Notification'

import { useDevice } from 'hooks/media'
import { useLang } from 'hooks/lang'
import { useVerification } from 'hooks/verification'
import { fixBtcRate } from 'api/rates'
import { calculateCredit } from 'api/calculator'
import { useProfileContext } from 'context/profile'

import { colors } from 'constants/colors'
import routes from 'constants/routes'

import { formatCalculationData } from 'utils/formatCalculationData'
import { errorCapture } from 'utils/errorCapture'

import ContentMobile from 'components/ContentMobile'
import { TitleGrey, TitleBlack } from 'layouts/SubLayout/style'
import LoanAmountInput from '../LoanAmountInput'
import PositionSummary from '../PositionSummary'

import { MinCollateral, CalcResultTitle } from '../CreditCalc/style'
import { RecommendedValue, RecommendedBlock } from '../DepositCalc/style'
import SubLayout from 'layouts/SubLayout'
import Agreement from '../Aggreement'

const calculationDefault = {
  loanAmount: 0,
  collateralEUR: 0,
  liquidationPrice: 0,
  liquidationThreshold: 0,
  liquidationPriceChange: 0,
  ltv: 0,
  minCollateralBTC: 0,
  collateralBTC: 0,
  monthlyFee: 0,
}

const BorrowCalc = ({ dots, onClickBack, onConfirm }) => {
  const isMobile = useDevice()
  const navigate = useNavigate()
  const { translate: t } = useLang()
  const { isLoaded, currency, contractDraft, position, balances, isServiceAvailable } = useProfileContext()
  const { isVerificationComplete, isVerificationDenied } = useVerification()

  const [isLoading, setLoading] = useState(false)

  const [agreementIsChecked, setAgreementIsChecked] = useState(false)
  const [btcRate, setBtcRate] = useState(null)
  const [currentCreditAvailable, fixCurrentCreditAvailable] = useState(null)

  // User input
  const [inputEur, setInputEur] = useState(null) // Number
  const [inputBtc, setInputBtc] = useState(null) // String
  // Calculation based on "position + input" values
  const [calculation, setCalculation] = useState(calculationDefault)

  // Calculated borrow more
  const [calcEur, setCalcEur] = useState(null) // Number
  const [calcBtc, setCalcBtc] = useState(null) // Number
  const minCollateral = calcBtc

  const [serverCallId, setServerCallId] = useState(null)
  const [differenceCalc, setDifferenceCalc] = useState({})

  // HTML input values to render
  const inputEurVal = !inputEur ? calcEur : inputEur
  const inputBtcVal = !inputBtc ? calcBtc : inputBtc

  const suffix = currency === 'EUR' ? '€' : !currency ? '' : currency
  const isBtcCollateralLessMin = bn(inputBtcVal).lt(minCollateral)
  const isInputEurGreaterAvailable = bn(inputEur).gt(currentCreditAvailable)

  useEffect(() => {
    if ((isLoaded && !isServiceAvailable) || isVerificationDenied) navigate(routes.main)
    if (btcRate) return

    setLoading(true)
    fixBtcRate()
      .then(({ data }) => {
        const price = data.btcRate
        setBtcRate(price)
        setLoading(false)
      })
      .catch((error) => errorCapture(error))
  }, [btcRate])

  const fixPosition = (btcRate) => {
    const result = getCalculation({
      inputEur: position?.loanAmount,
      inputBtc: position?.collateralBTC,
      btcRate,
    })

    const btcAvailable = bn.max(
      0,
      bn(result?.collateralBTC)
        .minus(result?.minCollateralBTC)
        .minus(balances?.btcPending || 0)
        .toFixed(8, bn.ROUND_UP)
    )
    fixCurrentCreditAvailable(
      Number(bn(btcAvailable).times(result?.defaultLTV).times(btcRate).toFixed(2, bn.ROUND_DOWN))
    )
  }

  useEffect(() => {
    if (currentCreditAvailable) return

    if (position && btcRate && !currentCreditAvailable) {
      fixPosition(btcRate)
    }
  }, [position, btcRate, currentCreditAvailable])

  const calculateOnServer = ({ calcParams, frontCalc }) => {
    calculateCredit(calcParams)
      .then((res) => {
        const changes = Object.entries(res.data)
          .filter(([key, value]) => value !== frontCalc[key])
          .reduce((acc, cur) => {
            acc[cur[0]] = cur[1]
            return acc
          }, {})
        setDifferenceCalc(changes)
        const btcRateCorrection = res.data.btcRate
        if (btcRate !== btcRateCorrection) setBtcRate(btcRateCorrection)

        const timeoutId = setTimeout(() => setDifferenceCalc({}), 1000)
        setServerCallId(timeoutId)
      })
      .catch((error) => errorCapture(error))
  }

  const creditCalculationHandler = (calcParams) => {
    clearTimeout(serverCallId)

    const frontCalc = getCalculation(calcParams)
    setCalculation(frontCalc)
    setDifferenceCalc({})

    // Render calculated EUR value if user started with BTC input
    if (!inputEur) {
      setCalcEur(Number(bn.max(0, bn(frontCalc?.loanAmount || 0).minus(position?.loanAmount || 0)).toFixed(0)))
    }
    // Calculate min BTC collateral if BTC input is empty
    if (!inputBtc) {
      setCalcBtc(Number(bn.max(0, bn(frontCalc?.collateralBTC || 0).minus(position?.collateralBTC || 0)).toFixed(8)))
    }

    const timeoutId = setTimeout(
      () => calculateOnServer({ calcParams, frontCalc: formatCalculationData(frontCalc) }),
      2000
    )
    setServerCallId(timeoutId)
  }

  useEffect(() => {
    const haveInputValues = inputEur || inputBtc
    if (contractDraft?.confirmedAt || !btcRate || !haveInputValues) return

    creditCalculationHandler({
      inputEur: inputEur
        ? bn(inputEur)
            .plus(position?.loanAmount || 0)
            .toFixed(0)
        : null,
      inputBtc: inputBtc
        ? bn(inputBtc)
            .plus(position?.collateralBTC || 0)
            .toFixed(8)
        : isInputEurGreaterAvailable
        ? null // calculate BTC required
        : position?.collateralBTC, // existing collateral is enough
      btcRate,
    })
  }, [inputEur, inputBtc, btcRate])

  const handleInputEurChange = ({ value }) => {
    setInputEur(value)

    // Reset BTC input and calculated EUR, because EUR input is the priority
    setCalcEur(null)
    setInputBtc(null)

    // Reset summary view on EUR input clear
    if (!value) setCalculation(calculationDefault)
  }

  const handleInputBtcChange = (e) => {
    const inputValue = e.value
    setInputBtc(inputValue.slice(0, 1) === '.' ? '0.' : inputValue)

    // Reset summary view on BTC input clear
    if (!inputValue) setCalculation(calculationDefault)
  }

  const isCalcReadyMobile = (inputEur || calcEur) && isInputEurGreaterAvailable ? !isBtcCollateralLessMin : true
  const isCalcReady = isMobile ? isCalcReadyMobile : isCalcReadyMobile && agreementIsChecked

  return (
    <SubLayout
      testId="borrow-calc"
      onClickBack={onClickBack}
      dots={dots}
      // Local
      isLoading={isLoading}
      confirmDisabled={!isCalcReady}
      infoText={t(`CREDIT_LINE.CALC_STEP.TITLE_BLACK_HINT.OPEN_CREDIT`)}
      forwardText={t(`LOAN.NAV_BUTTON.${isMobile ? 'NEXT' : 'CONFIRM'}`)}
      onClickForward={() => {
        setLoading(true)
        onConfirm()
      }}
    >
      <Row justifyContent="center">
        <Col lg={50} sm={100}>
          <>{!isMobile && <TitleGrey>{t('LOAN.BORROW.TITLE_GREY')}</TitleGrey>}</>
          <ContentMobile>
            <Row alignItems="baseline">
              <Col sm={100}>
                <TitleBlack>{t('LOAN.BORROW.TITLE_BLACK')}</TitleBlack>
              </Col>
              {!isMobile && (
                <Col>
                  <Info text={t(`CREDIT_LINE.CALC_STEP.TITLE_BLACK_HINT.OPEN_CREDIT`)} />
                </Col>
              )}
            </Row>

            <LoanAmountInput
              suffix={suffix}
              onValueChange={handleInputEurChange}
              value={Number(inputEurVal) || ''}
              placeholder="1 000"
              thousandSeparator={' '}
              decimalScale={0}
            />

            <RecommendedBlock>
              <RecommendedValue>
                {t('LOAN.BORROW.AVAILABLE_VALUE')} {Math.floor(currentCreditAvailable)} €
              </RecommendedValue>
              {isInputEurGreaterAvailable ? (
                <Notification variant="yellow">{t('LOAN.BORROW.NOTIFICATION')}</Notification>
              ) : (
                <Button
                  variant="filled"
                  textColor={colors.gray950}
                  bgColor={colors.gray150}
                  text={t('LOAN.BORROW.AVAILABLE_BUTTON')}
                  padding={isMobile ? '4px 8px' : '6px 12px'}
                  fontSize={isMobile ? '12px' : '14px'}
                  onClick={() => setInputEur(Math.floor(currentCreditAvailable))}
                />
              )}
            </RecommendedBlock>
          </ContentMobile>

          {isInputEurGreaterAvailable && (
            <ContentMobile>
              <Input
                value={inputBtcVal || ''}
                label={t('CREDIT_LINE.BTC_COLLATERAL_LABEL')}
                placeholder="0.0"
                onChange={handleInputBtcChange}
                error={!inputEur && inputBtcVal ? false : isBtcCollateralLessMin}
                type="number"
                btcCollateral
                decimalScale={8}
                disableClearable
                focus={isBtcCollateralLessMin}
              />
              <MinCollateral
                data-testid="min-collateral"
                error={isBtcCollateralLessMin}
                onClick={() => setInputBtc(minCollateral)}
              >
                {t('CREDIT_LINE.MIN_COLLATERAL')} {minCollateral} BTC
              </MinCollateral>
            </ContentMobile>
          )}

          {isMobile && (
            <PositionSummary
              liveCalc={calculation}
              differenceCalc={differenceCalc}
              borrow={inputEur}
              collateralBtc={isInputEurGreaterAvailable ? inputBtcVal : null}
              borrowSummary
            />
          )}
          {isVerificationComplete && !isMobile && (
            <Agreement checked={agreementIsChecked} onChange={setAgreementIsChecked} />
          )}
        </Col>

        {!isMobile && (
          <Col lg={50}>
            <CalcResultTitle>{t('LOAN.BORROW.SUMMARY.TITLE')}</CalcResultTitle>
            <PositionSummary
              liveCalc={calculation}
              differenceCalc={differenceCalc}
              borrow={inputEur}
              collateralBtc={isInputEurGreaterAvailable ? inputBtcVal : null}
              borrowSummary
              calcStep
            />
          </Col>
        )}
      </Row>
    </SubLayout>
  )
}

export default BorrowCalc
