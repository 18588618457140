import React from 'react'
import Tag from 'ui-kit/components/Tag'

import { useLang } from 'hooks/lang'

import clockIcon from './assets/clock-icon.svg'

import { Block, Title, Subtitle, Note } from './style'

const Banner = ({ currentConfirmation, requiredConfirmations, btcPending, onClick }) => {
  const { translate: t } = useLang()

  return (
    <Block onClick={onClick}>
      {btcPending ? (
        <>
          <Title>{btcPending} BTC</Title>
          <Tag text="pending" bgColor="yellow" textColor="yellow" />
        </>
      ) : (
        <>
          <Title>
            {currentConfirmation}
            {'/'}
            {requiredConfirmations}
          </Title>
          <Subtitle>{t('CONFIRMATIONS.SUBTITLE')}</Subtitle>
        </>
      )}

      {!btcPending && (
        <Note>
          <Tag text={t('CONFIRMATIONS.TAG')} icon={clockIcon} />
        </Note>
      )}
    </Block>
  )
}

export default Banner
