import React, { useEffect, useState } from 'react'
import QRCode from 'qrcode'
import bn from 'bignumber.js'

import Info from 'ui-kit/components/Info'
import Notification from 'ui-kit/components/Notification'
import { Row, Col } from 'ui-kit/components/Grid'
import Input from 'ui-kit/components/Input'

import { useDevice } from 'hooks/media'
import { useLang } from 'hooks/lang'
import { useProfileContext } from 'context/profile'
import { assignDepositAddress } from 'api/profile'
import { confirmBtcSent } from 'api/credit'

import { errorCapture } from 'utils/errorCapture'

import ContentMobile from 'components/ContentMobile'

import { TitleGrey, TitleBlack, MarginWrap } from 'layouts/SubLayout/style'
import { TransferSubtitle, TransferBtc, QRCodeImg, TransferInput, TransferNotification, TransferTop } from './style'
import PositionSummary from '../PositionSummary'
import { CalcResultTitle } from 'pages/Loan/style'
import SubLayout from 'layouts/SubLayout'

const TransferStep = ({ dots, onClickBack, onConfirm, borrowMore, depositCollateral }) => {
  const isMobile = useDevice()
  const { translate: t } = useLang()
  const { btcDepositAddress, contractDraft, position } = useProfileContext()

  const [isLoading, setLoading] = useState(true)
  const [qrCodeUrl, setQRCodeUrl] = useState(null)

  useEffect(() => {
    setLoading(true)

    const result = btcDepositAddress ? getQRCode(btcDepositAddress) : assignDepositAddress()
    result
      .then(() => setLoading(false))
      .catch((error) => {
        errorCapture(error)
        setLoading(false)
      })
  }, [btcDepositAddress])

  const getQRCode = (address) => {
    const uri = `bitcoin:${address}?amount=${contractDraft?.collateralBTC}`

    return QRCode.toDataURL(uri)
      .then(setQRCodeUrl)
      .catch((error) => errorCapture(error))
  }

  const onConfirmTransfer = () => {
    setLoading(true)
    confirmBtcSent()
      .then(() => {
        onConfirm()
      })
      .catch((error) => errorCapture(error))
  }

  const transferAmount = borrowMore
    ? Number(bn(contractDraft?.collateralBTC).minus(position?.collateralBTC).toFixed(8))
    : contractDraft?.collateralBTC

  const getGreyTitle = () => {
    if (borrowMore) {
      return t('LOAN.BORROW.TITLE_BLACK')
    } else if (depositCollateral) {
      return t('LOAN.DEPOSIT.TITLE_BLACK')
    } else {
      return t(`CREDIT_LINE.TRANSFER_STEP.TITLE_GREY.OPEN_CREDIT`)
    }
  }

  return (
    <SubLayout
      onClickBack={onClickBack}
      dots={dots}
      // Local
      isLoading={isLoading}
      confirmDisabled={!qrCodeUrl}
      infoText={t(`CREDIT_LINE.TRANSFER_STEP.TITLE_BLACK_HINT.OPEN_CREDIT`)}
      forwardText={t(`CREDIT_LINE.BUTTON.CONFIRM`)}
      onClickForward={onConfirmTransfer}
    >
      <Row justifyContent="center">
        {!isLoading && !btcDepositAddress ? (
          <Col lg={50} sm={100}>
            {!isMobile && <TitleGrey>{getGreyTitle()}</TitleGrey>}
            <ContentMobile>
              <Row alignItems="baseline">
                <Col sm={100}>
                  <TitleBlack mb={18}>{t('CREDIT_LINE.TRANSFER_STEP.TITLE_BLACK')}</TitleBlack>
                </Col>
                {!isMobile && (
                  <Col>
                    <Info text={t(`CREDIT_LINE.TRANSFER_STEP.TITLE_BLACK_HINT.OPEN_CREDIT`)} />
                  </Col>
                )}
              </Row>
              <TransferNotification>
                <Notification variant="yellow">{t('CREDIT_LINE.TRANSFER_STEP.NOTIFICATION_NO_ADDRESS')}</Notification>
              </TransferNotification>
            </ContentMobile>
          </Col>
        ) : (
          <Col lg={50} sm={100}>
            {!isMobile && <TitleGrey>{getGreyTitle()}</TitleGrey>}
            <ContentMobile>
              <Row alignItems="baseline">
                <Col sm={100}>
                  <TitleBlack>{t('CREDIT_LINE.TRANSFER_STEP.TITLE_BLACK')}</TitleBlack>
                </Col>
                {!isMobile && (
                  <Col>
                    <Info text={t(`CREDIT_LINE.TRANSFER_STEP.TITLE_BLACK_HINT.OPEN_CREDIT`)} />
                  </Col>
                )}
              </Row>
              <MarginWrap margin="0 0 16px">
                <TransferTop>
                  <TransferSubtitle>{t('CREDIT_LINE.TRANSFER_STEP.SUBTITLE_1')}</TransferSubtitle>
                  <TransferBtc>
                    {transferAmount} {'BTC'}
                  </TransferBtc>
                </TransferTop>
              </MarginWrap>
              <TransferNotification>
                <Notification variant="yellow">{t('CREDIT_LINE.TRANSFER_STEP.NOTIFICATION')}</Notification>
              </TransferNotification>
            </ContentMobile>
            <ContentMobile>
              <MarginWrap margin="0 0 12px">
                <Row alignItems="center" m={-2}>
                  <Col p={2}>
                    <TransferSubtitle>{t('CREDIT_LINE.TRANSFER_STEP.SUBTITLE_2')}</TransferSubtitle>
                  </Col>
                  <Col p={2}>
                    <Info text={t('CREDIT_LINE.TRANSFER_STEP.SUBTITLE_2_HINT')} />
                  </Col>
                </Row>
              </MarginWrap>
              <TransferInput>
                <Input
                  label={t('CREDIT_LINE.TRANSFER_STEP.INPUT_LABEL')}
                  value={btcDepositAddress || ''}
                  readOnly
                  enableCopy
                  error={!isLoading && !btcDepositAddress}
                />
              </TransferInput>
              {!isMobile && (
                <>
                  <TransferSubtitle>{t('CREDIT_LINE.TRANSFER_STEP.SUBTITLE_3')}</TransferSubtitle>
                  {qrCodeUrl && <QRCodeImg src={qrCodeUrl} alt="qr-code" />}
                </>
              )}
            </ContentMobile>
          </Col>
        )}
        {!isMobile && (
          <Col lg={50}>
            <CalcResultTitle>{t('CREDIT_LINE.SUMMARY.TITLE')}</CalcResultTitle>
            <PositionSummary borrowSummary={borrowMore} />
          </Col>
        )}
      </Row>
    </SubLayout>
  )
}

export default TransferStep
