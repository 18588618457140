import styled from 'styled-components/macro'
import { colors } from 'constants/colors'
import { device } from 'constants/media'

export const ResultTitle = styled.div`
  padding-top: 16px;
`

export const ResultTableBlock = styled.div`
  margin-bottom: 16px;
  border: 1px solid ${colors.gray150};
  border-radius: 8px;

  @media ${device.mobile} {
    border: none;
    margin-bottom: 0;
  }
`

export const ResultTableRow = styled.div`
  padding: 12px 0;
  border-bottom: 1px solid ${colors.gray150};

  &:last-child {
    border-bottom: none;
  }
`

export const ResultTableTitleWrap = styled.div`
  display: flex;
  align-items: center;
`

export const ResultTableTitle = styled.div`
  color: ${(p) => (p.gray ? colors.gray400 : colors.gray600)};
  margin: 0 4px 0 16px;
`

export const ResultTableNum = styled.div`
  margin-right: 16px;
  background-color: ${(p) => (p.hasChanged ? colors.yellow100 : 'transparent')};
  color: ${(p) => (p.gray ? colors.gray400 : colors.gray600)};
`
