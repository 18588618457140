import React from 'react'
import { useNavigate } from 'react-router-dom'
import bn from 'bignumber.js'

import Button from 'ui-kit/components/Button'
import Tag from 'ui-kit/components/Tag'
import { Row, Col } from 'ui-kit/components/Grid'
import GreenArrow from 'ui-kit/components/GreenArrow'

import { useLang } from 'hooks/lang'
import { useDevice } from 'hooks/media'
import { useProfileContext } from 'context/profile'

import routes from 'constants/routes'

import {
  CreditGridWrap,
  CreditGridBlock,
  CreditGridHeading,
  CreditGridTitle,
  CreditGridUnderTitle,
  CreditGridAboveBtn,
  CreditGridBox,
  CreditImage,
} from './style'

const PositionInfo = () => {
  const { translate: t } = useLang()
  const navigate = useNavigate()
  const isMobile = useDevice()
  const { balances, position } = useProfileContext()

  if (!position) return null

  const getLtvTagColor = (ltv) => {
    if (bn(ltv).gt(0.78)) {
      return 'red'
    } else if (bn(ltv).gt(0.66) && bn(ltv).lt(0.78)) {
      return 'yellow'
    } else {
      return 'green'
    }
  }

  const isWithdrawAvailable = false // NOTE: temporary hardcode result of bn(position?.btcAvailable).gt(0)

  return (
    <CreditGridWrap data-testid="position">
      <Row m={-12}>
        <Col lg={66.67} sm={100} p={12} pSm={6} order={1}>
          <CreditGridBlock onClick={() => navigate(routes.borrow)}>
            <CreditImage />
            <CreditGridBox flexDirection="column">
              <div>
                <CreditGridHeading>{position?.creditAvailable}€</CreditGridHeading>
                <CreditGridTitle>{t('DASHBOARD.CREDIT_GRID.AVAILABLE_CREDIT')}</CreditGridTitle>
              </div>
              <div>
                <Button
                  variant="filled"
                  width={isMobile ? '100%' : 'auto'}
                  icon="borrow"
                  text={t('DASHBOARD.CREDIT_GRID.BUTTONS.BORROW')}
                  fontSize={!isMobile ? '16px' : undefined}
                  padding={isMobile && '7px 16px'}
                />
              </div>
            </CreditGridBox>
          </CreditGridBlock>
        </Col>
        <Col lg={33.33} sm={100} p={12} pSm={6} order={isMobile ? 5 : 2}>
          <CreditGridBlock height={isMobile ? 'fit-content' : '180px'} onClick={() => navigate(routes.deposit)}>
            <CreditGridBox>
              <CreditGridBox
                flexDirection={isMobile ? 'row' : 'column'}
                alignItem={isMobile ? 'flex-end' : 'normal'}
                mb={8}
              >
                <div>
                  <CreditGridTitle>{t('DASHBOARD.CREDIT_GRID.LIQUIDATION_PRICE')}</CreditGridTitle>
                  <Row m={-2} alignItems="center">
                    <Col p={2}>
                      <CreditGridUnderTitle>{position?.liquidationPrice}€</CreditGridUnderTitle>
                    </Col>
                    <Col p={2}>
                      <GreenArrow number={`${bn(position?.liquidationPriceChange).times(100).toFixed(0)}%`} />
                    </Col>
                  </Row>
                </div>

                <CreditGridBox flexDirection="row" alignItem={isMobile ? 'flex-end' : 'center'}>
                  <CreditGridAboveBtn size="12px" marginBottom={isMobile ? '4px' : '0px'}>
                    1 BTC ~ {position?.btcRate}€
                  </CreditGridAboveBtn>
                  <Tag
                    fontSize={isMobile ? '10px' : '12px'}
                    text={`LTV ${bn(position?.ltv).times(100).toFixed(0)}%`}
                    textColor={getLtvTagColor(position?.ltv)}
                    bgColor={getLtvTagColor(position?.ltv)}
                  />
                </CreditGridBox>
              </CreditGridBox>
              <div>
                <Button
                  variant="filled"
                  width="100%"
                  text={t('DASHBOARD.CREDIT_GRID.BUTTONS.DEPOSIT')}
                  fontSize={!isMobile ? '16px' : undefined}
                  padding={isMobile && '7px 16px'}
                />
              </div>
            </CreditGridBox>
          </CreditGridBlock>
        </Col>
        <Col lg={33.33} sm={50} p={12} pSm={6} order={isMobile ? 2 : 3}>
          <CreditGridBlock>
            <CreditGridBox flexDirection="column">
              <div>
                <CreditGridTitle>{t('DASHBOARD.CREDIT_GRID.BORROWED')}</CreditGridTitle>
                <CreditGridUnderTitle>{position?.loanAmount}€</CreditGridUnderTitle>
              </div>
              <div>
                <Button
                  variant="filled"
                  width="100%"
                  text={t('DASHBOARD.CREDIT_GRID.BUTTONS.REPAY')}
                  fontSize={!isMobile ? '16px' : undefined}
                  padding={isMobile && '7px 16px'}
                  disabled
                  onClick={() => {}}
                />
              </div>
            </CreditGridBox>
          </CreditGridBlock>
        </Col>
        <Col lg={33.33} sm={50} p={12} pSm={6} order={isMobile ? 3 : 4}>
          <CreditGridBlock>
            <CreditGridBox flexDirection="column">
              <div>
                <CreditGridTitle>{t('DASHBOARD.CREDIT_GRID.COLLATERAL')}</CreditGridTitle>
                <CreditGridUnderTitle>{Number(balances?.btcVerified)} BTC</CreditGridUnderTitle>
              </div>
              <div>
                <CreditGridAboveBtn size="10px" marginBottom={isMobile ? '8px' : '16px'}>
                  {t('DASHBOARD.CREDIT_GRID.AVAILABLE_COLLATERAL')} {position?.btcAvailable} BTC
                </CreditGridAboveBtn>
                <Button
                  variant="filled"
                  width="100%"
                  text={t('DASHBOARD.CREDIT_GRID.BUTTONS.WITHDRAW')}
                  fontSize={!isMobile ? '16px' : undefined}
                  padding={isMobile && '7px 16px'}
                  disabled={!isWithdrawAvailable}
                  onClick={() => {}} // NOTE: should navigate to routes.withdraw TODO: make a Withdraw feature
                />
              </div>
            </CreditGridBox>
          </CreditGridBlock>
        </Col>
        <Col lg={33.33} sm={100} p={12} pSm={6} order={isMobile ? 4 : 5}>
          <CreditGridBlock height={isMobile ? '62px' : '180px'}>
            <CreditGridBox flexDirection={isMobile ? 'row' : 'column'}>
              <div>
                <CreditGridTitle>{t('DASHBOARD.CREDIT_GRID.MAY_INTEREST_FEE')}</CreditGridTitle>
                <CreditGridUnderTitle>{position?.monthlyFee}€</CreditGridUnderTitle>
              </div>
              <div>
                <Button
                  variant="filled"
                  width="100%"
                  text={t('DASHBOARD.CREDIT_GRID.BUTTONS.PAY_INTEREST')}
                  fontSize={!isMobile ? '16px' : undefined}
                  padding={isMobile && '7px 16px'}
                  disabled
                  onClick={() => {}}
                />
              </div>
            </CreditGridBox>
          </CreditGridBlock>
        </Col>
      </Row>
    </CreditGridWrap>
  )
}

export default PositionInfo
