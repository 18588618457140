import { serverTimestamp, getDocs, collection, query, orderBy, limit, where, setDoc, doc } from 'firebase/firestore'
import { db } from 'configs/firebase'

export const submitIdentityData = async (uid, data) => {
  data.submittedAt = serverTimestamp()
  data.status = 'pending'

  const docRef = doc(db, `profiles/${uid}/identityData/${data.sessionId}`)
  await setDoc(docRef, data)
}

export const getIdentityData = async (uid) => {
  const docRef = query(
    collection(db, `profiles/${uid}/identityData`),
    orderBy('submittedAt', 'desc'),
    where('status', '==', 'success'),
    limit(1)
  )
  const docSnap = await getDocs(docRef)

  return docSnap.empty ? null : docSnap.docs[0].data()
}
