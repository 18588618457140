import React from 'react'
import Notification from 'ui-kit/components/Notification'
import Button from 'ui-kit/components/Button'
import NumericList from 'ui-kit/components/NumericList'

import { useDevice } from 'hooks/media'
import { useLang } from 'hooks/lang'
import ContentMobile from 'components/ContentMobile'

import statuses from 'constants/statuses'

import { TitleGrey, TitleBlack } from 'layouts/SubLayout/style'
import { VeriffStepDescription, VerificationResult, VerificationResultIcon, VerificationResultText } from './style'

const VeriffStep = ({ onStartSession, isFinish, hasError }) => {
  const isMobile = useDevice()
  const { translate: t } = useLang()

  return (
    <ContentMobile>
      {!isMobile && <TitleGrey>{t('IDENTITY_VERIFICATION.VERIFF.TITLE_GREY')}</TitleGrey>}
      <TitleBlack>{t('IDENTITY_VERIFICATION.VERIFF.TITLE_BLACK')}</TitleBlack>
      <VeriffStepDescription>{t('IDENTITY_VERIFICATION.VERIFF.DESCRIPTION')}</VeriffStepDescription>
      <NumericList
        title={t('IDENTITY_VERIFICATION.VERIFF.NUMERIC_LIST.TITLE')}
        text={t('IDENTITY_VERIFICATION.VERIFF.NUMERIC_LIST.POINTS')}
      />
      {isFinish ? (
        <VerificationResult>
          <VerificationResultIcon icon={statuses.success.icon} />
          <VerificationResultText color={statuses.success.color}>Verification Process</VerificationResultText>
        </VerificationResult>
      ) : (
        <Button
          disabled={hasError}
          text={t('IDENTITY_VERIFICATION.VERIFF.BUTTON')}
          variant="filled"
          onClick={onStartSession}
        />
      )}
      {hasError && <Notification variant="red">{t('IDENTITY_VERIFICATION.VERIFF.NOTIFICATION_ERROR')}</Notification>}
    </ContentMobile>
  )
}

export default VeriffStep
