import reviewIcon from 'assets/icons/statuses/process-pending.svg'
import successIcon from 'assets/icons/statuses/process-success.svg'
import failedIcon from 'assets/icons/statuses/process-failed.svg'
import { colors } from 'constants/colors'

const data = {
  pending: {
    code: 'pending',
    text: 'In review',
    icon: reviewIcon,
    color: colors.yellow500,
  },
  sent: {
    code: 'requested',
    text: 'Pending',
    icon: reviewIcon,
    color: colors.yellow500,
  },
  btcReceived: {
    code: 'btcReceived',
    text: 'Pending',
    icon: reviewIcon,
    color: colors.yellow500,
  },
  success: {
    code: 'success',
    text: 'Confirmed',
    icon: successIcon,
    color: colors.green500,
  },
  issued: {
    code: 'issued',
    text: 'Confirmed',
    icon: successIcon,
    color: colors.green500,
  },
  failed: {
    code: 'failed',
    text: 'Failed',
    icon: null,
    color: colors.red500,
  },
  denied: {
    code: 'denied',
    text: 'Denied',
    icon: failedIcon,
    color: colors.red500,
  },
  expired: {
    code: 'expired',
    text: 'Expired',
    icon: failedIcon,
    color: colors.red500,
  },
  notStarted: {
    code: 'notStarted',
    text: 'Not started',
    icon: null,
    color: null,
  },
  unknown: {
    code: 'unknown',
    text: 'Unknown status',
    icon: null,
    color: colors.blue500,
  },
  unavailable: {
    code: 'unavailable',
    text: 'Unavailable',
    icon: null,
    color: colors.gray500,
  },
}

export default data
