import React from 'react'

import { useDevice } from 'hooks/media'
import { useLang } from 'hooks/lang'

import NavBar from './components/NavBar'
import MailLink from './components/MailLink'

import { Wrapper, Content, MobileHeader, MobileTitle } from './style'

const MainLayout = ({ children, mobileTitle, activeNav }) => {
  const isMobile = useDevice()
  const { translate: t } = useLang()

  const emailTooltipText = `${t('NAVBAR.EMAIL_TOOLTIP')}  ${process.env.REACT_APP_CS_EMAIL}`

  return (
    <Wrapper>
      <Content>
        <NavBar activeNav={activeNav} />
        {isMobile && (
          <MobileHeader>
            <MobileTitle>{mobileTitle}</MobileTitle>
            <MailLink mailto={`mailto:${process.env.REACT_APP_CS_EMAIL}`} tooltipText={emailTooltipText} />
          </MobileHeader>
        )}
        {children}
      </Content>
    </Wrapper>
  )
}

export default MainLayout
