import React, { useState } from 'react'
import moment from 'moment'
import Info from 'ui-kit/components/Info'
import Notification from 'ui-kit/components/Notification'
import { Row, Col } from 'ui-kit/components/Grid'

import { errorCapture } from 'utils/errorCapture'
import { createCustomToken } from 'api/auth'
import { confirmTwoFASecret } from 'api/twofa'
import { authWithCustomToken } from 'hooks/auth'
import { useLang } from 'hooks/lang'
import { useDevice } from 'hooks/media'

import ContentMobile from 'components/ContentMobile'
import VerificationCode from './components/VerificationCode'
import Switcher from './components/Switcher'

import { TitleBlack, TitleGrey } from 'layouts/SubLayout/style'
import { VerificationCodeSubtitle, TextBlock } from './styles'

export const TwofaWidget = ({
  accessType,
  onInput = () => {},
  onSuccess,
  onSetPageLoading,
  onSetSecretData,
  titleGrey,
  titleBlack,
  hiddenTwofaText,
  hiddenTitle,
  isTwoFactorPage,
  twofaText,
  showCountdown,
  countdownCounter,
}) => {
  const isMobile = useDevice()
  const { translate: t } = useLang()

  const [isLoading, setLoading] = useState(false)
  const [isCodeIncorrect, setIsCodeIncorrect] = useState(false)
  const [activeMethod, setActiveMethod] = useState('app')

  const confirmTwofa = async (code) => {
    onSetLoading(true)
    setIsCodeIncorrect(false)

    const method = isTwoFactorPage
      ? confirmTwoFASecret({ code })
      : createCustomToken({ code, accessType, claimMethod: activeMethod })
    processTwofaCode(method, async (res) => {
      if (isTwoFactorPage) {
        onSetSecretData()
      } else {
        await authWithCustomToken({ token: res.data })
        onSuccess()
      }
    })
  }

  const processTwofaCode = (method, callback) => {
    method
      .then((res) => {
        res.data ? callback(res) : setIsCodeIncorrect(true)
        onSetLoading(false)
      })
      .catch((error) => {
        errorCapture(error)
      })
  }

  const onSetLoading = (val) => {
    setLoading(val)
    onSetPageLoading(val)
  }

  const onComplete = (code) => {
    if (code.length === 6) {
      onInput(code)
      confirmTwofa(code)
    }
  }

  const formatCountdown = () => moment.utc(countdownCounter * 1000).format('mm:ss')

  return (
    <>
      {!hiddenTitle && (
        <ContentMobile>
          {!isMobile && <TitleGrey>{titleGrey}</TitleGrey>}
          <TitleBlack>{titleBlack || t('TFA_WIDGET.TITLE_BLACK')}</TitleBlack>
          {!hiddenTwofaText && <TextBlock>{twofaText}</TextBlock>}
        </ContentMobile>
      )}

      {!isTwoFactorPage && (
        <ContentMobile>
          <Switcher activeMethod={activeMethod} handleChangeMethod={setActiveMethod} />
        </ContentMobile>
      )}

      <ContentMobile>
        <Row alignItems="center" m={-2}>
          <Col p={2}>{t('TFA_WIDGET.TITLE_CODE')}</Col>
          <Col p={2}>
            <Info text={t('TFA_WIDGET.HINT_CODE')} />
          </Col>
        </Row>
        <VerificationCode isCodeIncorrect={isCodeIncorrect} length={6} loading={isLoading} onComplete={onComplete} />
        <VerificationCodeSubtitle>
          {showCountdown
            ? `${t('TFA_WIDGET.SUBTITLE_CODE')}. ${t('TFA_WIDGET.COUNTDOWN.TEXT_1')}${formatCountdown()}${t(
                'TFA_WIDGET.COUNTDOWN.TEXT_2'
              )}`
            : t('TFA_WIDGET.SUBTITLE_CODE')}
        </VerificationCodeSubtitle>

        {isCodeIncorrect && <Notification variant="yellow">{t('TFA_WIDGET.ERROR_TEXT')}</Notification>}
      </ContentMobile>
    </>
  )
}
