import { TitleGrey } from 'layouts/SubLayout/style'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import Button from 'ui-kit/components/Button'

import { NotesBlock, NotesTitle, NotesText, Image } from './style'

const NotesPageTemplate = ({ title, titleGrey, texts, buttonText, link, img }) => {
  const navigate = useNavigate()

  return (
    <>
      <NotesBlock>
        <TitleGrey>{titleGrey}</TitleGrey>
        <NotesTitle>{title}</NotesTitle>
        {Array.isArray(texts) ? (
          <>
            {texts.map((text, index) => (
              <NotesText key={index}>{text}</NotesText>
            ))}
          </>
        ) : (
          <NotesText>{texts}</NotesText>
        )}
      </NotesBlock>
      <Button variant="filled" text={buttonText} onClick={() => navigate(link)} />
      <Image img={img} />
    </>
  )
}

export default NotesPageTemplate
