import React from 'react'

import { useDevice } from 'hooks/media'
import { useLang } from 'hooks/lang'

import {
  AuthPageWrap,
  AuthBgImage,
  AuthContent,
  AuthHeader,
  AuthHeaderLink,
  AuthHeaderMobile,
  AuthTitle,
} from './style'

const AuthPageLayout = ({ children, bgImageColor, title }) => {
  const isMobile = useDevice()
  const { translate: t } = useLang()

  const Logo = () => {
    return <AuthHeaderLink href="https://dascue.com">{t('LOGO')}</AuthHeaderLink>
  }

  return (
    <AuthPageWrap>
      {isMobile && (
        <AuthHeaderMobile>
          <Logo />
        </AuthHeaderMobile>
      )}
      <AuthBgImage color={bgImageColor} />
      <AuthContent>
        {!isMobile && (
          <AuthHeader>
            <Logo />
          </AuthHeader>
        )}
        <AuthTitle>{title}</AuthTitle>
        {children}
      </AuthContent>
    </AuthPageWrap>
  )
}

export default AuthPageLayout
