import styled from 'styled-components/macro'
import { colors } from 'constants/colors'
import { device } from 'constants/media'

export const FormSubtext = styled.div`
  font-size: 12px;
  line-height: 16px;
  color: ${colors.gray600};
  text-align: center;

  a {
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: ${colors.gray950};
    padding: 0 4px;
  }
`

export const FormButtonWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 32px 0 16px;
`

export const FormButtonSubtext = styled.div`
  color: ${colors.gray600};
  text-align: center;

  a {
    font-weight: 500;
    color: ${colors.gray950};
    padding: 0 4px;
  }
`

export const FormInput = styled.div`
  margin-bottom: 16px;
`

export const FormBottom = styled.div`
  @media ${device.mobile} {
    position: absolute;
    left: 16px;
    right: 16px;
    bottom: 0;
    margin-bottom: 16px;
  }
`
