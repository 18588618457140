import React from 'react'
import { useNavigate } from 'react-router-dom'
import { signOut } from 'firebase/auth'
import { Row, Col } from 'ui-kit/components/Grid'
import InfoBlock from 'ui-kit/components/InfoBlock'
import Notification from 'ui-kit/components/Notification'

import statuses from 'constants/statuses'
import routes from 'constants/routes'
import { colors } from 'constants/colors'
import { useDevice } from 'hooks/media'
import { useLang } from 'hooks/lang'
import { useVerification } from 'hooks/verification'
import { useAccount } from 'hooks/account'
import { auth } from 'configs/firebase'
import { errorCapture } from 'utils/errorCapture'

import logoutIcon from 'assets/icons/logout.svg'

import MainLayout from 'layouts/MainLayout'

import { ProfileVerification, ProfileH1, ProfileH2, ProfileInfoWrapper } from './style'
import { useProfileContext } from 'context/profile'

const Profile = () => {
  const isMobile = useDevice()
  const navigate = useNavigate()
  const { country, isServiceAvailable } = useProfileContext()
  const { data: verificationData, processingVerifications, isVerificationDenied } = useVerification()
  const accountData = useAccount()
  const { translate: t } = useLang()

  const successfulVerifications = accountData.filter((data) => data.status?.code === statuses.success.code)
  const isTwoFaNotSet = verificationData.some(
    (data) => data.id === '2fa' && data.status?.code !== statuses.success.code
  )
  const logoutData = {
    title: t('PROFILE.LOGOUT_TITLE'),
    subtitle: '',
    status: null,
    icon: logoutIcon,
    buttonText: null,
    route: null,
  }

  const logout = () => {
    navigate(routes.login)
    signOut(auth).catch((error) => errorCapture(error))
  }

  const onNavigate = (route) => {
    navigate(route)
  }

  return (
    <MainLayout activeNav="profile" mobileTitle={t('PROFILE.TITLE')}>
      {!isMobile && <ProfileH1>{t('PROFILE.TITLE')}</ProfileH1>}

      {isServiceAvailable !== undefined && !isServiceAvailable ? (
        <Notification variant="red">
          {t('DASHBOARD.NOTIFICATION.SERVICE_NOT_AVAILABLE')} <u>{country.label}</u>
        </Notification>
      ) : (
        <>
          {isVerificationDenied && (
            <Notification variant="red">{t('VERIFICATION_BLOCKS.DENIED_NOTIFICATION')}</Notification>
          )}

          <ProfileVerification>
            <Row justifyContent="start" m={-12}>
              {processingVerifications.map((data, index) => (
                <Col lg={50} sm={100} p={12} key={index}>
                  <InfoBlock
                    infoData={data}
                    mode={isMobile ? 'row' : 'card'}
                    border={!isMobile && colors.gray150}
                    marginBottom={!isMobile ? '24px' : '12px'}
                    boxShadow={isMobile}
                    cursor={!isVerificationDenied ? 'pointer' : 'default'}
                    handlerType={data.status?.icon ? 'statusIcon' : isMobile ? 'arrow' : 'button'}
                    showStatusInSubtitle={!!data.status}
                    onClickBlock={() => !isVerificationDenied && onNavigate(data.route)}
                    isBlockDisabled={isVerificationDenied && data.status?.code !== 'denied'}
                  />
                </Col>
              ))}
            </Row>
          </ProfileVerification>

          {successfulVerifications.length > 0 && (
            <>
              <ProfileH2>{t('PROFILE.SECTION_TITLE_1')}</ProfileH2>
              {isTwoFaNotSet && (
                <Notification variant="yellow">{t('VERIFICATION_BLOCKS.DATA_ACCESS_NOTIFICATION')}</Notification>
              )}
              <ProfileInfoWrapper>
                {successfulVerifications.map((data, index) => (
                  <InfoBlock
                    key={index}
                    infoData={data}
                    mode="row"
                    border={!isMobile && colors.gray150}
                    variant={isMobile && 'united'}
                    marginBottom={!isMobile && '8px'}
                    alignItems="center"
                    boxShadow={isMobile}
                    iconBgColor={isMobile && 'transparent'}
                    borderMiddle={isMobile && colors.gray100}
                    padding="16px"
                    cursor={!isTwoFaNotSet ? 'pointer' : 'default'}
                    handlerType="arrow"
                    onClickBlock={() => !isTwoFaNotSet && onNavigate(data.route)}
                    isBlockDisabled={isTwoFaNotSet}
                  />
                ))}
              </ProfileInfoWrapper>
            </>
          )}
        </>
      )}

      <ProfileH2>{t('PROFILE.SECTION_TITLE_2')}</ProfileH2>
      <ProfileInfoWrapper>
        <InfoBlock
          infoData={logoutData}
          mode="row"
          border={!isMobile && colors.gray150}
          variant={isMobile && 'united'}
          marginBottom={!isMobile && '8px'}
          alignItems="center"
          boxShadow={isMobile}
          iconBgColor={isMobile && 'transparent'}
          borderMiddle={isMobile && colors.gray100}
          padding="16px"
          onClickBlock={logout}
          titleColor={colors.red500}
          cursor="pointer"
          handlerType="arrow"
        />
      </ProfileInfoWrapper>
    </MainLayout>
  )
}

export default Profile
