import styled from 'styled-components/macro'
import { colors } from 'constants/colors'

import bgImg from './assets/confirm-bg.png'

export const Block = styled.div`
  background-color: ${colors.white};
  background-image: url(${bgImg});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 100% 0;
  padding: 16px;
  border-radius: 12px;
  position: relative;
  margin-bottom: 48px;
  cursor: ${(p) => (p.onClick ? 'pointer' : 'default')};
`

export const Title = styled.div`
  font-weight: 500;
  font-size: 24px;
  margin-bottom: 4px;
  font-family: 'Roboto Mono', monospace;
`

export const Subtitle = styled.div`
  font-weight: 500;
  font-size: 12px;
  text-transform: uppercase;
  color: ${colors.gray400};
`

export const Note = styled.div`
  position: absolute;
  right: 16px;
  top: 16px;
`
