import styled from 'styled-components/macro'
import { device } from 'constants/media'
import { colors } from 'constants/colors'

export const ContentMobileWrap = styled.div`
  @media ${device.mobile} {
    margin-bottom: 6px;
    background-color: ${colors.white};
    padding: ${(p) => (p.noPadding ? '0' : '16px 0')};
    border-top: 1px solid ${colors.gray150};
    border-bottom: 1px solid ${colors.gray150};
  }
`

export const ContentMobileEl = styled.div`
  @media ${device.mobile} {
    max-width: 375px;
    margin: auto;
    padding: ${(p) => (p.noPadding ? '0' : '0 16px')};
  }
`
