import React from 'react'

import { RadioWrap, RadioIcon, RadioText } from './style'

const Radio = ({ checked, disabled, onChange, label, children }) => {
  return (
    <RadioWrap disabled={disabled} onClick={disabled ? null : onChange}>
      <RadioIcon disabled={disabled} checked={checked} />
      <RadioText disabled={disabled}>{label || children}</RadioText>
    </RadioWrap>
  )
}

export default Radio
