import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Row, Col } from 'ui-kit/components/Grid'
import Info from 'ui-kit/components/Info'

import { useDevice } from 'hooks/media'
import { useLang } from 'hooks/lang'
import routes from 'constants/routes'

import Navigation from './components/Navigation'

import { Wrapper, HeaderContent, Fader, Content, BackButton } from './style'

const SubLayout = ({
  children,
  onClickForward,
  onClickBack,
  isLoading,
  confirmDisabled,
  infoText,
  hiddenNavigation,
  noButton,
  forwardText,
  dots,
  customRoute,
  customBackToText,
  bgColor,
  mobileBackButtonIcon = 'close',
  testId,
}) => {
  const isMobile = useDevice()
  const navigate = useNavigate()
  const { translate: t } = useLang()

  const handleBackTo = () => navigate(customRoute || routes.main)

  const buttonText = customBackToText || t('NAVIGATION.BACK_TO_DASHBOARD')

  return (
    <Wrapper data-testid={testId} bgColor={bgColor}>
      <HeaderContent>
        <Row justifyContent="space-between">
          <Col>
            <BackButton mobileBackButtonIcon={mobileBackButtonIcon} onClick={handleBackTo}>
              {!isMobile && buttonText}
            </BackButton>
          </Col>
          {isMobile && infoText && (
            <Col>
              <Info text={infoText} isTransparent />
            </Col>
          )}
        </Row>
      </HeaderContent>
      <Content>{children}</Content>
      {isLoading && <Fader />}
      {!hiddenNavigation && (
        <Navigation
          dots={dots}
          noButton={noButton}
          forwardText={forwardText || t('NAVIGATION.BUTTON.DEFAULT')}
          onClickForward={onClickForward}
          onClickBack={onClickBack}
          buttonDisabled={confirmDisabled || isLoading}
          isLoading={isLoading}
        />
      )}
    </Wrapper>
  )
}

export default SubLayout
