import styled from 'styled-components/macro'
import { colors } from 'constants/colors'
import { device } from 'constants/media'

import bgGreen from './assets/credit-line-green.png'

export const CreditGridWrap = styled.div`
  margin-bottom: 24px;
  @media ${device.mobile} {
    margin-bottom: 20px;
  }
`

export const CreditGridBlock = styled.div`
  background-color: ${colors.white};
  border: 1px solid ${colors.gray150};
  border-radius: 12px;
  padding: 16px;
  height: ${(p) => (p.height ? p.height : '180px')};
  margin-bottom: 24px;
  position: relative;
  cursor: ${(p) => (p.onClick ? 'pointer' : 'default')};

  @media ${device.mobile} {
    margin-bottom: 12px;
    padding: 12px;
    height: ${(p) => (p.height ? p.height : '138px')};
  }
`

export const CreditGridBox = styled.div`
  display: flex;
  flex-direction: ${(p) => (p.flexDirection ? p.flexDirection : 'column')};
  justify-content: ${(p) => (p.justifyContent ? p.justifyContent : 'space-between')};
  ${(p) => p.alignItem && `align-items: ${p.alignItem}`};
  height: ${(p) => (p.height ? p.height : '100%')};
  margin-bottom: ${(p) => (p.mb ? `${p.mb}px` : '0px')};
`

export const CreditGridHeading = styled.div`
  font-family: 'Roboto Mono', monospace;
  font-weight: 500;
  font-size: 24px;
  margin-bottom: 4px;
`

export const CreditGridTitle = styled.div`
  font-weight: 500;
  font-size: 12px;
  text-transform: uppercase;
  color: ${colors.gray400};
  margin-bottom: 4px;

  @media ${device.mobile} {
    font-size: 10px;
  }
`

export const CreditGridUnderTitle = styled.div`
  font-weight: 400;
  font-size: 16px;
  margin-right: 12px;
`

export const CreditGridAboveBtn = styled.div`
  font-weight: 500;
  font-size: ${(p) => p.size};
  text-transform: uppercase;
  color: ${colors.gray600};
  margin-bottom: ${(p) => p.marginBottom};
`

export const CreditImage = styled.div`
  background-image: url(${bgGreen});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 100% 0;
  width: 186px;
  height: 144px;
  position: absolute;
  right: 0;
  top: 0;

  @media ${device.mobile} {
    width: 108px;
    height: 65px;
  }
`
