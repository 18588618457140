import { collection, query, where, orderBy, limit, getDocs, startAfter } from 'firebase/firestore'
import { doc, updateDoc } from 'firebase/firestore'
import { db } from 'configs/firebase'

export const buildQuery = ({ uid, acknowledged, sortOrder, limitEvents, filter }) => {
  let eventRef = query(
    collection(db, `profiles/${uid}/eventLog`),
    where('acknowledged', '==', acknowledged),
    orderBy('createdAt', sortOrder)
  )

  if (limitEvents) eventRef = query(eventRef, limit(limitEvents))
  if (filter.length) eventRef = query(eventRef, where('type', 'in', filter))

  return eventRef
}

export const filterEventLogBy = async ({ uid, acknowledged = true, limitEvents, filter, sortOrder, lastVisible }) => {
  let eventRef = buildQuery({ uid, acknowledged, sortOrder, limitEvents, filter })
  if (lastVisible) eventRef = query(eventRef, startAfter(lastVisible))

  const eventSnaps = await getDocs(eventRef)
  return {
    events: eventSnaps.docs.map((snap) => ({ ...snap.data(), id: snap.id })),
    lastSnap: eventSnaps.docs[eventSnaps.docs.length - 1],
  }
}

export const makeEventAcknowledged = async ({ uid, id }) => {
  const eventRef = doc(db, `profiles/${uid}/eventLog`, id)
  await updateDoc(eventRef, { acknowledged: true })
}
