import styled from 'styled-components/macro'
import { colors } from 'constants/colors'

export const VerificationCodeSubtitle = styled.div`
  color: ${colors.gray500};
  font-size: 12px;
`

export const TextBlock = styled.div`
  color: ${colors.gray600};
`
