import React from 'react'
import ScaleLoader from 'react-spinners/ScaleLoader'

import { ButtonEl, ButtonIcon, ButtonText, ButtonSpinner } from './style'

const Button = ({
  type,
  disabled,
  text,
  onClick,
  width,
  height,
  isLoading,
  variant,
  icon,
  noText,
  cursor,
  textColor,
  bgColor,
  fontSize,
  padding,
  iconSize,
}) => {
  return (
    <ButtonEl
      icon={icon}
      variant={variant}
      noText={noText}
      onClick={onClick}
      cursor={cursor}
      textColor={textColor}
      width={width}
      height={height}
      type={type || 'button'}
      disabled={disabled}
      bgColor={bgColor}
      fontSize={fontSize}
      padding={padding}
    >
      {icon && <ButtonIcon noText={noText} icon={icon} iconSize={iconSize} />}
      {!noText && <ButtonText isLoading={isLoading}>{text}</ButtonText>}
      {isLoading && (
        <ButtonSpinner>
          <ScaleLoader width={2} height={15} margin={1} />
        </ButtonSpinner>
      )}
    </ButtonEl>
  )
}

export default Button
