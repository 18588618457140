import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import getCalculation from 'calculation-loan-data'

import Info from 'ui-kit/components/Info'
import { Row, Col } from 'ui-kit/components/Grid'
import Input from 'ui-kit/components/Input'

import { useDevice } from 'hooks/media'
import { useLang } from 'hooks/lang'
import { useVerification } from 'hooks/verification'
import { fixBtcRate } from 'api/rates'
import { calculateCredit } from 'api/calculator'
import { useProfileContext } from 'context/profile'
import routes from 'constants/routes'

import { formatCalculationData } from 'utils/formatCalculationData'
import { errorCapture } from 'utils/errorCapture'

import ContentMobile from 'components/ContentMobile'
import { TitleGrey, TitleBlack } from 'layouts/SubLayout/style'
import LoanAmountInput from '../LoanAmountInput'
import PositionSummary from '../PositionSummary'

import { MinCollateral, CalcResultTitle } from './style'
import SubLayout from 'layouts/SubLayout'
import Agreement from '../Aggreement'

const calculationDefault = {
  loanAmount: 0,
  collateralEUR: 0,
  liquidationPrice: 0,
  liquidationThreshold: 0,
  liquidationPriceChange: 0,
  ltv: 0,
  minCollateralBTC: 0,
  collateralBTC: 0,
  monthlyFee: 0,
}

const CreditCalc = ({ dots, onClickBack, onConfirm }) => {
  const isMobile = useDevice()
  const navigate = useNavigate()
  const { translate: t } = useLang()
  const { isLoaded, currency, contractDraft, isServiceAvailable } = useProfileContext()
  const { isVerificationComplete, isVerificationDenied } = useVerification()

  const [isLoading, setLoading] = useState(false)

  const [agreementIsChecked, setAgreementIsChecked] = useState(false)
  const [btcRate, setBtcRate] = useState(null)

  const [inputEur, setInputEur] = useState(contractDraft?.loanAmount)
  const [inputBtc, setInputBtc] = useState(contractDraft?.collateralBTC)

  useEffect(() => {
    // NOTE: this is necessary trick to wait loaded data, it is only for OnboardingCalculator
    if (!isVerificationComplete && contractDraft) {
      setInputEur(contractDraft?.loanAmount)
      setInputBtc(contractDraft?.collateralBTC)
    }
  }, [contractDraft])

  const [calculation, setCalculation] = useState(calculationDefault)
  const [differenceCalc, setDifferenceCalc] = useState({})

  const [loanAmount, setLoanAmount] = useState(null)
  const [collateralBTC, setCollateralBTC] = useState(null)

  const [serverCallId, setServerCallId] = useState(null)

  const inputEurVal = loanAmount === null ? inputEur : loanAmount
  const inputBtcVal = inputBtc === null ? collateralBTC : inputBtc

  const suffix = currency === 'EUR' ? '€' : !currency ? '' : currency
  const isSmallInputValue = inputEur === 0 || (!!inputEur && inputEur < 10)
  const isValuesAllowed = inputEur >= 10 || !!inputBtcVal
  const isBtcCollateralLessMin = inputBtcVal < calculation.minCollateralBTC

  useEffect(() => {
    if ((isLoaded && !isServiceAvailable) || isVerificationDenied) navigate(routes.main)
    if (btcRate) return

    setLoading(true)
    fixBtcRate()
      .then(({ data }) => {
        const price = data.btcRate
        setBtcRate(price)
        setLoading(false)
      })
      .catch((error) => errorCapture(error))
  }, [btcRate])

  const calculateOnServer = ({ calcParams, frontCalc }) => {
    calculateCredit(calcParams)
      .then((res) => {
        const changes = Object.entries(res.data)
          .filter(([key, value]) => value !== frontCalc[key])
          .reduce((acc, cur) => {
            acc[cur[0]] = cur[1]
            return acc
          }, {})
        setDifferenceCalc(changes)
        const btcRateCorrection = res.data.btcRate
        if (btcRate !== btcRateCorrection) setBtcRate(btcRateCorrection)

        const timeoutId = setTimeout(() => {
          setDifferenceCalc({})
          setCalculation({ ...changes })
        }, 1000)
        setServerCallId(timeoutId)
      })
      .catch((error) => errorCapture(error))
  }

  const creditCalculationHandler = ({ calcParams, isValueInput }) => {
    clearTimeout(serverCallId)

    const frontCalc = getCalculation({ ...calcParams, btcRate })
    if (!inputEur) setLoanAmount(frontCalc?.loanAmount)
    if (!inputBtc) setCollateralBTC(frontCalc.collateralBTC)

    if (isValueInput && btcRate) {
      setCalculation(frontCalc)
      setDifferenceCalc({})
    } else {
      setCalculation(calculationDefault)
    }

    const timeoutId = setTimeout(
      () => calculateOnServer({ calcParams, frontCalc: formatCalculationData(frontCalc) }),
      2000
    )
    setServerCallId(timeoutId)
  }

  const haveInputValue = Boolean(inputEur) || Boolean(inputBtc) || Boolean(loanAmount)

  useEffect(() => {
    if (!contractDraft?.confirmedAt && haveInputValue) {
      const calcParams = isValuesAllowed ? { inputEur, inputBtc } : { inputEur: 0, inputBtc: 0 }
      const isValueInput = true

      creditCalculationHandler({ calcParams, isValueInput })
    }
  }, [inputEur, inputBtc, btcRate])

  const handleInputEurChange = (value) => {
    const inputValue = value.floatValue
    setInputEur(inputValue)
    setInputBtc(null)

    if (!inputValue) {
      setLoanAmount(null)
      setInputBtc(null)
      setCollateralBTC(null)
      setCalculation(calculationDefault)
    }
  }

  const handleInputBtcChange = (e) => {
    const inputValue = e.value
    setInputBtc(inputValue.slice(0, 1) === '.' ? '0.' : inputValue)
    if (!inputValue) {
      setCalculation(calculationDefault)
    }
  }

  const onClickMin = () => {
    setInputBtc(calculation.minCollateralBTC)
  }

  const isOnboardingCalcReady = (inputEur || loanAmount) && isValuesAllowed && !isBtcCollateralLessMin
  const isCreditCalcReady = isMobile ? isOnboardingCalcReady : isOnboardingCalcReady && agreementIsChecked

  return (
    <SubLayout
      testId="borrow-calc"
      onClickBack={onClickBack}
      dots={dots}
      // Local
      isLoading={isLoading}
      confirmDisabled={isVerificationComplete ? !isCreditCalcReady : !isOnboardingCalcReady}
      infoText={t(`CREDIT_LINE.CALC_STEP.TITLE_BLACK_HINT.OPEN_CREDIT`)}
      forwardText={t(`CREDIT_LINE.BUTTON.${isMobile ? 'NEXT' : 'CONFIRM'}`)}
      onClickForward={() => {
        setLoading(true)
        onConfirm()
      }}
    >
      <Row justifyContent="center">
        <Col lg={50} sm={100}>
          <>{!isMobile && <TitleGrey>{t('CREDIT_LINE.CALC_STEP.TITLE_GREY')}</TitleGrey>}</>
          <ContentMobile>
            <Row alignItems="baseline">
              <Col sm={100}>
                <TitleBlack>{t(`CREDIT_LINE.CALC_STEP.TITLE_BLACK.OPEN_CREDIT`)}</TitleBlack>
              </Col>
              {!isMobile && (
                <Col>
                  <Info text={t(`CREDIT_LINE.CALC_STEP.TITLE_BLACK_HINT.OPEN_CREDIT`)} />
                </Col>
              )}
            </Row>

            <LoanAmountInput
              suffix={suffix}
              onValueChange={handleInputEurChange}
              value={inputEurVal || ''}
              placeholder="1 000"
              thousandSeparator={' '}
              decimalScale={0}
              isSmallInputValue={isSmallInputValue}
            />
          </ContentMobile>

          <ContentMobile>
            <Input
              value={inputBtcVal || ''}
              label={t('CREDIT_LINE.BTC_COLLATERAL_LABEL')}
              placeholder="0.0"
              onChange={handleInputBtcChange}
              error={!inputEur && inputBtcVal ? false : isBtcCollateralLessMin}
              type="number"
              btcCollateral
              decimalScale={8}
              disableClearable
              focus={isBtcCollateralLessMin}
            />
            <MinCollateral data-testid="min-collateral" error={isBtcCollateralLessMin} onClick={onClickMin}>
              {t('CREDIT_LINE.MIN_COLLATERAL')} {calculation?.minCollateralBTC} BTC
            </MinCollateral>
          </ContentMobile>

          {isMobile && <PositionSummary liveCalc={calculation} differenceCalc={differenceCalc} />}
          {isVerificationComplete && !isMobile && (
            <Agreement checked={agreementIsChecked} onChange={setAgreementIsChecked} />
          )}
        </Col>

        {!isMobile && (
          <Col lg={50}>
            <CalcResultTitle>{t('CREDIT_LINE.SUMMARY.TITLE')}</CalcResultTitle>
            <PositionSummary liveCalc={calculation} differenceCalc={differenceCalc} />
          </Col>
        )}
      </Row>
    </SubLayout>
  )
}

export default CreditCalc
