import React from 'react'
import { useNavigate } from 'react-router-dom'
import bn from 'bignumber.js'

import { useProfileContext } from 'context/profile'
import { useDevice } from 'hooks/media'
import { useLang } from 'hooks/lang'
import { useVerification } from 'hooks/verification'

import statuses from 'constants/statuses'
import routes from 'constants/routes'

import Card from 'ui-kit/components/Card'

const CTA = () => {
  const { translate: t } = useLang()
  const navigate = useNavigate()
  const isMobile = useDevice()
  const {
    data: verificationData,
    processingVerifications,
    isVerificationComplete,
    verificationFailed,
    verificationDenied,
  } = useVerification()
  const { isOnboardingFinished, contractDraft, balances, position } = useProfileContext()

  // verification
  const isVerificationInProgress = processingVerifications.length > 0 && !verificationFailed && !verificationDenied
  const haveFailedButNotDenied = verificationFailed && !verificationDenied
  const readyToGetLoan =
    isVerificationComplete && !contractDraft?.confirmedAt && !contractDraft?.collateralStatus && !position

  if (!isOnboardingFinished) {
    return (
      <Card
        color="blue"
        isMobile={isMobile}
        onClick={() => navigate(routes.calculator)}
        text={{
          title: t(`DASHBOARD.CARD.BLUE.TITLE`),
          subtitle: t(`DASHBOARD.CARD.BLUE.SUBTITLE`),
          button: t(`DASHBOARD.CARD.BLUE.BUTTON`),
        }}
      />
    )
  } else if (isVerificationInProgress) {
    return (
      <Card
        color="purple"
        isMobile={isMobile}
        onClick={() => navigate(routes.calculator)}
        text={{
          title: t(`DASHBOARD.CARD.PURPLE.TITLE`),
          subtitle: t(`DASHBOARD.CARD.PURPLE.SUBTITLE`),
          buttonLeft: t(`DASHBOARD.CARD.PURPLE.BUTTON_LEFT`),
          buttonRight: t(`DASHBOARD.CARD.PURPLE.BUTTON_RIGHT`),
        }}
        loanAmount={contractDraft?.loanAmount.toString()}
      />
    )
  } else if (haveFailedButNotDenied) {
    const failedSteps = verificationData.filter((data) => data.status.code === statuses.failed.code)
    return (
      <Card
        color="orange"
        isMobile={isMobile}
        onClick={() => navigate(failedSteps[0].route)}
        text={{
          title: t(`DASHBOARD.CARD.ORANGE.TITLE`),
          subtitle: t(`DASHBOARD.CARD.ORANGE.SUBTITLE`),
          button: t(`DASHBOARD.CARD.ORANGE.BUTTON`),
        }}
      />
    )
  } else if (verificationDenied) {
    return (
      <Card
        color="red"
        isMobile={isMobile}
        text={{
          title: t(`DASHBOARD.CARD.RED.TITLE`),
          subtitle: t(`DASHBOARD.CARD.RED.SUBTITLE`),
        }}
      />
    )
  } else if (readyToGetLoan) {
    return (
      <Card
        color="green"
        isMobile={isMobile}
        onClick={() => navigate(routes.credit)}
        text={{
          title: t(`DASHBOARD.CARD.GREEN.TITLE`),
          subtitle: t(`DASHBOARD.CARD.GREEN.SUBTITLE`),
          buttonLeft: t(`DASHBOARD.CARD.GREEN.BUTTON_LEFT`),
          buttonRight: t(`DASHBOARD.CARD.GREEN.BUTTON_RIGHT`),
        }}
        loanAmount={contractDraft?.loanAmount.toString()}
      />
    )
  } else if (!position && !contractDraft?.collateralStatus) {
    return (
      <Card
        color="orange_not_confirm_btc"
        isMobile={isMobile}
        mode={isMobile ? 'small' : undefined}
        onClick={() => navigate(routes.borrow)}
        text={{
          title: t(`DASHBOARD.CARD.ORANGE_NOT_CONFIRM_BTC.TITLE`),
          subtitle:
            t(`DASHBOARD.CARD.ORANGE_NOT_CONFIRM_BTC.SUBTITLE_1`) +
            contractDraft?.collateralBTC +
            t(`DASHBOARD.CARD.ORANGE_NOT_CONFIRM_BTC.SUBTITLE_2`),
          button: t(`DASHBOARD.CARD.ORANGE_NOT_CONFIRM_BTC.BUTTON`),
        }}
      />
    )
  } else if (!position && !contractDraft?.bankAccount) {
    return (
      <Card
        color="orange_not_confirm_bank"
        isMobile={isMobile}
        mode={isMobile ? 'small' : undefined}
        onClick={() => navigate(routes.borrow)}
        text={{
          title: t(`DASHBOARD.CARD.ORANGE_NOT_CONFIRM_BANK.TITLE`),
          subtitle: t(`DASHBOARD.CARD.ORANGE_NOT_CONFIRM_BANK.SUBTITLE`),
          button: t(`DASHBOARD.CARD.ORANGE_NOT_CONFIRM_BANK.BUTTON`),
        }}
      />
    )
  } else if (!position && contractDraft?.collateralStatus === 'failed.ltv') {
    // not enough collateral BTC
    const recommendedBtcCollateral = bn(contractDraft?.collateralBTC).minus(balances?.btcVerified).toFixed(8)
    return (
      <Card
        color="orange_not_enough"
        isMobile={isMobile}
        mode={isMobile ? 'small' : undefined}
        onClick={() => navigate(routes.deposit)}
        text={{
          title: t(`DASHBOARD.CARD.ORANGE_NOT_ENOUGH.TITLE`),
          subtitle:
            t(`DASHBOARD.CARD.ORANGE_NOT_ENOUGH.SUBTITLE_1`) +
            recommendedBtcCollateral +
            t(`DASHBOARD.CARD.ORANGE_NOT_ENOUGH.SUBTITLE_2`) +
            ` ${Number(balances?.btcVerified)} BTC.`,
          button: t(`DASHBOARD.CARD.ORANGE_NOT_ENOUGH}.BUTTON`),
        }}
      />
    )
  } else if (position?.isLtvHigh) {
    // position LTV is high
    return (
      <Card
        color="red_ltv_high"
        isMobile={isMobile}
        mode={isMobile ? 'small' : undefined}
        onClick={() => navigate(routes.deposit)}
        text={{
          title: t(`DASHBOARD.CARD.RED_LTV_HIGH.TITLE`),
          subtitle: t(`DASHBOARD.CARD.RED_LTV_HIGH.SUBTITLE`),
          button: t(`DASHBOARD.CARD.RED_LTV_HIGH.BUTTON`),
        }}
      />
    )
  } else {
    return null
  }
}

export default CTA
