const bn = require('bignumber.js')

//
// Credit line constants
//
// Recommended LTV
const defaultLTV = bn(0.66)
// Liquidation event LTV threshold
const liquidationThreshold = bn(0.9)
// Half way to liquidation
const warningThreshold = bn(liquidationThreshold)
  .minus(defaultLTV)
  .div(2)
  .plus(defaultLTV)
// Yearly interest rate
const interestRate = bn(0.1)

/**
 * As a user I want to
 *   1. enter loan amount in EUR (input Eur = X, inputBtc = undefined)
 *     - I expect to have BTC input filled in with minimum collateral
 *     - I should be able to change EUR amount
 *       - I expect BTC collateral input value to change accordingly
 *   ! - I should be able to change BTC input to set the amount of BTC I want to deposit
 *       - I expect EUR input stays the same
 *       - I expect to see minimum BTC collateral hint under the input
 *   2. enter BTC collateral (input Eur = undefined, inputBtc = Y)
 *     - I expect to have EUR input filled with the maximum EUR amount I can borrow (based on default LTV)
 *     - I should be able to change BTC amount
 *       - I expect EUR input to change value to the maximum available credit (based on default LTV)
 *   3. EUR input to be prioritized
 *     - I expect BTC collateral to be reset to minimum if I change EUR input
 * @param {{inputEur: number, inputBtc: number, btcRate: number}} data
 * @return {Object}
 */
function getCalculation({ inputEur, inputBtc, btcRate }) {
  // Normalize input values to make them finite big numbers
  const inputEurBN = bn(inputEur).gt(0) ? bn(inputEur) : bn(0)
  const inputBtcBN = bn(inputBtc).gt(0) ? bn(inputBtc) : bn(0)

  // Minimum BTC required to issue the requested EUR loan (zero if no EUR input)
  const minCollateralEUR = inputEurBN.div(defaultLTV)
  const minCollateralBTC = minCollateralEUR.div(btcRate).toFixed(8, bn.ROUND_UP)

  // Use minimum collateral for further calculation, if no BTC input
  const collateralBTC = inputBtcBN.gt(0)
    ? inputBtcBN.toFixed(8)
    : minCollateralBTC
  const collateralEUR = bn(collateralBTC)
    .times(btcRate)
    .toFixed(2, bn.ROUND_DOWN)

  // Calculate loan amount from BTC collateral, if no EUR input
  const loanAmount = bn(
    inputEurBN.gt(0) ? inputEur : bn(collateralEUR).times(defaultLTV)
  ).toFixed(0, bn.ROUND_DOWN)

  const ltv = bn(loanAmount).div(collateralEUR)
  const warningPrice = bn(loanAmount)
    .div(warningThreshold.times(collateralBTC))
    .toFixed(2, bn.ROUND_UP)
  const liquidationPrice = bn(loanAmount)
    .div(liquidationThreshold.times(collateralBTC))
    .toFixed(2, bn.ROUND_UP)
  const liquidationPriceChange = bn(1).minus(bn(liquidationPrice).div(btcRate))
  const monthlyFee = bn(loanAmount)
    .times(interestRate)
    .div(12)
    .toFixed(2, bn.ROUND_UP)

  return {
    defaultLTV: defaultLTV.toFixed(2),
    loanAmount: Number(loanAmount),
    collateralBTC: Number(collateralBTC),
    minCollateralBTC: Number(minCollateralBTC),
    ltv: ltv.isFinite() ? Number(ltv.toFixed(2)) : 0.0,
    collateralEUR: Number(collateralEUR),
    warningThreshold: Number(warningThreshold),
    warningPrice: bn(warningPrice).isFinite() ? Number(warningPrice) : null,
    isLtvHigh: bn(warningPrice).gt(btcRate),
    liquidationPrice: bn(liquidationPrice).isFinite()
      ? Number(liquidationPrice)
      : null,
    liquidationThreshold: Number(liquidationThreshold),
    liquidationPriceChange: liquidationPriceChange.isFinite()
      ? Number(bn(liquidationPriceChange).toFixed(2, bn.ROUND_UP))
      : null,
    monthlyFee: Number(monthlyFee),
    btcRate: bn(btcRate).toNumber(),
    interestRate: Number(interestRate),
  }
}
module.exports = getCalculation
