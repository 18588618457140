const chooseLocale = (lang) => {
  switch (lang) {
    case 'de':
      return 'de-DE'
    case 'pl':
      return 'pl-PL'

    default:
      return 'en-GB'
  }
}

export const formatNumberByLocale = ({ lang, currency, number }) => {
  const result = new Intl.NumberFormat(chooseLocale(lang), { style: 'currency', currency }).format(number)
  return result.replace(/\s/g, ' ')
}
