import styled from 'styled-components/macro'
import { colors } from 'constants/colors'

const bgChooser = (variant) => {
  switch (variant) {
    case 'green':
      return colors.green100
    case 'purple':
      return colors.purple100
    case 'yellow':
      return colors.yellow100
    case 'red':
      return colors.red50
    default:
      return ''
  }
}

const colorChooser = (variant) => {
  switch (variant) {
    case 'green':
      return colors.green800
    case 'purple':
      return colors.purple800
    case 'yellow':
      return colors.yellow800
    case 'red':
      return colors.red700
    default:
      return ''
  }
}

const borderColorChooser = (variant) => {
  switch (variant) {
    case 'green':
      return colors.green200
    case 'purple':
      return colors.purple200
    case 'yellow':
      return colors.yellow200
    case 'red':
      return colors.red200
    default:
      return ''
  }
}

export const NotificationEl = styled.div`
  margin: 16px 0px;
  padding: 12px;
  border: 1px solid;
  background-color: ${(p) => bgChooser(p.variant)};
  color: ${(p) => colorChooser(p.variant)};
  border-color: ${(p) => borderColorChooser(p.variant)};
  box-sizing: border-box;
  border-radius: 8px;
  font-size: 12px;
  line-height: 16px;
`
