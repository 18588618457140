import bn from 'bignumber.js'
import _ from 'lodash'

export const formatCalculationData = (calc) => {
  const ltvBN = bn(calc?.ltv)
  const liquidationPriceBN = bn(calc?.liquidationPrice)
  const liquidationPriceChangeNB = bn(calc?.liquidationPriceChange)
  const warningPriceBN = bn(calc?.warningPrice)
  return !_.isEmpty(calc)
    ? {
        btcRate: bn(calc?.btcRate).toFixed(2),
        loanAmount: bn(calc?.loanAmount).toFixed(2),
        collateralEUR: bn(calc?.collateralEUR).toFixed(2),
        collateralBTC: calc?.collateralBTC.toString(),
        minCollateralBTC: bn(calc?.minCollateralBTC).toFixed(8),
        ltv: ltvBN.gt(0) ? ltvBN.times(100).toFixed(0) : 'N/A',

        warningThreshold: bn(calc?.warningThreshold).times(100).toFixed(0),
        warningPrice: warningPriceBN.gt(0) ? warningPriceBN.toFixed(2) : 'N/A',
        isLtvHigh: calc?.isLtvHigh,

        liquidationPrice: liquidationPriceBN.gt(0) ? liquidationPriceBN.toFixed(2) : 'N/A',
        liquidationThreshold: bn(calc?.liquidationThreshold).times(100).toFixed(0),
        liquidationPriceChange: liquidationPriceChangeNB.gt(0)
          ? `${liquidationPriceChangeNB.times(100).toFixed(0)}%`
          : '',

        monthlyFee: bn(calc?.monthlyFee).toFixed(2),
        interestRate: bn(calc?.interestRate || 0.1)
          .times(100)
          .toFixed(0),
      }
    : {}
}
