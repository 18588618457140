import styled from 'styled-components/macro'
import { device } from 'constants/media'
import { colors } from 'constants/colors'
import { ContentTemplate } from 'pages/commonStyle'

export const DashboardWrapper = styled.div`
  height: inherit;
  background-color: ${colors.gray100};
`

export const DashboardHeader = styled.div`
  padding: 16px 0;
  margin-bottom: 24px;

  @media ${device.mobile} {
    margin-bottom: 4px;
  }
`

export const Logo = styled.div`
  font-weight: 500;
  font-size: 20px;
`

export const DashboardContent = styled(ContentTemplate)``

export const DashboardBlockTitle = styled.div`
  color: ${colors.gray400};
  font-weight: 500;
  margin-bottom: 16px;
  text-transform: uppercase;
`

export const DashboardVerification = styled.div`
  margin-bottom: 25px;

  @media ${device.mobile} {
    margin-bottom: 16px;
  }
`

export const EventLogTitle = styled.div`
  color: ${colors.gray400};
  font-weight: 500;
  margin-bottom: 16px;
  text-transform: uppercase;
`

export const EventLogWrapper = styled.div`
  margin-bottom: 25px;
`
