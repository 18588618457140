import React from 'react'

import { useLang } from 'hooks/lang'
import { useProfileContext } from 'context/profile'

import MainLayout from 'layouts/MainLayout'
import NotesPageTemplate from 'components/NotesPageTemplate'

import routes from 'constants/routes'

const DepositConfirmPage = () => {
  const { haveAllConfirms } = useProfileContext()
  const { translate: t } = useLang()

  if (!haveAllConfirms) return

  return (
    <MainLayout mobileTitle={t('LOAN.DEPOSIT.CONFIRM_STEP.TITLE_BLACK')}>
      <NotesPageTemplate
        title={t('LOAN.DEPOSIT.CONFIRM_STEP.TITLE_BLACK')}
        titleGrey={t('LOAN.DEPOSIT.CONFIRM_STEP.TITLE_GREY')}
        texts={t('LOAN.DEPOSIT.CONFIRM_STEP.TEXT_1')}
        buttonText={t('LOAN.DEPOSIT.CONFIRM_STEP.BUTTON')}
        link={routes.main}
        img="deposit"
      />
    </MainLayout>
  )
}

export default DepositConfirmPage
