import { collection, getDoc, doc, addDoc } from 'firebase/firestore'
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage'

import { db, storage } from 'configs/firebase'
import { errorCapture } from 'utils/errorCapture'

const addressDataCollection = (uid) => collection(db, `profiles/${uid}/addressData`)

export const setAddressData = async ({ uid, data, file }) => {
  try {
    await addDoc(addressDataCollection(uid), data)
    await uploadAddressProofFile({ uid, file })
  } catch (error) {
    errorCapture(error)
    throw error
  }
}

export const getAddressData = async (uid, id) => {
  const docSnap = await getDoc(doc(addressDataCollection(uid), id))
  return docSnap.empty ? null : docSnap.data()
}

export const uploadAddressProofFile = async ({ uid, file }) => {
  if (!file) return

  return new Promise(function (resolve, reject) {
    const metadata = {
      contentType: file.type,
    }
    const uploadFileRef = ref(storage, `${uid}/addressProof/${file.name}`)
    const uploadTask = uploadBytesResumable(uploadFileRef, file, metadata)

    uploadTask.on(
      'state_changed',
      (snapshot) => {
        // TODO: return progress to UI
        Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100)
        // console.info('Upload is ' + progress + '% done')
      },
      (error) => {
        errorCapture(error)
        reject()
      },
      () => {
        resolve()
      }
    )
  })
}

export const downloadAddressProofFile = async (filePath) => {
  const fileRef = ref(storage, filePath)

  return await getDownloadURL(fileRef)
    .then((url) => {
      return url
    })
    .catch((error) => {
      errorCapture(error)
    })
}
