import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Row, Col } from 'ui-kit/components/Grid'
import InfoBlock from 'ui-kit/components/InfoBlock'
import Notification from 'ui-kit/components/Notification'

import { useDevice } from 'hooks/media'
import { useLang } from 'hooks/lang'
import { useVerification } from 'hooks/verification'
import { colors } from 'constants/colors'
import routes from 'constants/routes'

import SubLayout from 'layouts/SubLayout'
import ContentMobile from 'components/ContentMobile'

import { TitleGrey, TitleBlack, MarginWrap } from 'layouts/SubLayout/style'
import { useEffect } from 'react'

const VerificationProcess = () => {
  const isMobile = useDevice()
  const navigate = useNavigate()
  const { translate: t } = useLang()
  const { data: verificationData, isVerificationComplete, isVerificationDenied } = useVerification()

  const onNavigate = (route) => {
    navigate(route)
  }

  useEffect(() => {
    if (isVerificationComplete) navigate(routes.main)
  }, [isVerificationComplete])

  return (
    <>
      {!isVerificationComplete && (
        <SubLayout noButton onClickBack={() => navigate(-1)}>
          <ContentMobile>
            {isVerificationDenied ? (
              <Notification variant="red">{t('VERIFICATION_BLOCKS.DENIED_NOTIFICATION')}</Notification>
            ) : (
              <>
                {!isMobile && <TitleGrey>{t('VERIFICATION_BLOCKS.TITLE_GREY')}</TitleGrey>}
                <TitleBlack>{t('VERIFICATION_BLOCKS.TITLE_BLACK')}</TitleBlack>
              </>
            )}

            <MarginWrap margin="0 0 16px">{t('VERIFICATION_BLOCKS.SUBTITLE')}</MarginWrap>
            <Row justifyContent="start" m={-12}>
              {verificationData.map((data, index) => (
                <Col lg={50} sm={100} p={12} key={index}>
                  <InfoBlock
                    mode={isMobile ? 'row' : 'card'}
                    infoData={data}
                    marginBottom={!isMobile ? '24px' : '12px'}
                    border={colors.gray150}
                    cursor={data.route && !isVerificationDenied ? 'pointer' : 'default'}
                    showStatusInSubtitle={!!data.status}
                    handlerType={data.status?.icon ? 'statusIcon' : isMobile ? 'arrow' : 'button'}
                    onClickBlock={() => data.route && !isVerificationDenied && onNavigate(data.route)}
                    isBlockDisabled={isVerificationDenied && data.status?.code !== 'denied'}
                  />
                </Col>
              ))}
            </Row>
          </ContentMobile>
        </SubLayout>
      )}
    </>
  )
}

export default VerificationProcess
