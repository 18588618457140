import React, { useState, useEffect } from 'react'
import { Row, Col } from 'ui-kit/components/Grid'

import { useLang } from 'hooks/lang'
import { checkUserAuthToken } from 'hooks/auth'
import { downloadAddressProofFile, getAddressData } from 'api/address'

import { useProfileContext } from 'context/profile'

import SubLayout from 'layouts/SubLayout'
import TwofaWidget from 'widgets/TwofaWidget'

import Form from '../components/Form'

import routes from 'constants/routes'

const Address = () => {
  const { uid, country, addressVerificationDocId } = useProfileContext()
  const { translate: t } = useLang()

  const initAddressData = {
    country: country?.label,
    city: '*************',
    address: '*************',
    zipCode: '*************',
  }

  const [isLoading, setLoading] = useState(false)
  const [addressData, setAddressData] = useState(initAddressData)
  const [addressProofFile, setAddressProofFile] = useState(null)
  const [isFormVisible, setFormVisible] = useState(true)
  const [isDataVisible, setDataVisible] = useState(false)

  useEffect(() => {
    if (Boolean(country?.label)) {
      setAddressData({
        ...initAddressData,
        country: country.label,
      })
    }
  }, [country?.label])

  useEffect(() => {
    const isInitData = addressData.city === initAddressData.city
    if (isDataVisible && isInitData) {
      getPersonalData()
    }
  }, [isDataVisible])

  const getPersonalData = async () => {
    const data = await getAddressData(uid, addressVerificationDocId)
    const downloadUrl = await downloadAddressProofFile(data.file)

    setAddressData({
      ...initAddressData,
      city: data.city,
      address: data.address,
      zipCode: data.zipCode,
    })

    setAddressProofFile({
      name: data.file.split('addressProof/')[1],
      url: downloadUrl,
    })
  }

  const toggleVisibility = async () => {
    const isTokenClaimed = await checkUserAuthToken({ claim: 'accessPersonalDataUntil' })
    if (!isTokenClaimed) {
      setFormVisible(false)
    } else {
      setDataVisible(!isDataVisible)
    }
  }

  const hideTwofaWidget = () => {
    setDataVisible(true)
    setFormVisible(true)
  }

  const formData = [
    {
      label: t('ACCOUNT.ADDRESS.FORM.COUNTRY'),
      value: addressData?.country,
    },
    {
      label: t('ACCOUNT.ADDRESS.FORM.CITY'),
      value: isDataVisible ? addressData?.city : initAddressData?.city,
    },
    {
      label: t('ACCOUNT.ADDRESS.FORM.ADDRESS'),
      value: isDataVisible ? addressData?.address : initAddressData?.address,
    },
    {
      label: t('ACCOUNT.ADDRESS.FORM.ZIP_CODE'),
      value: isDataVisible ? addressData?.zipCode : initAddressData?.zipCode,
    },
  ]

  return (
    <SubLayout
      isLoading={isLoading}
      hiddenNavigation
      customRoute={routes.profile}
      customBackToText={t('ACCOUNT.BACK_TO_ACCOUNT')}
    >
      {isFormVisible ? (
        <Form
          isAddressData
          data={formData}
          additionalData={addressProofFile || {}}
          formTitle={t('ACCOUNT.ADDRESS.TITLE')}
          buttonIcon={isDataVisible ? 'eye-closed' : 'eye'}
          buttonText={isDataVisible ? t('ACCOUNT.BUTTON_HIDE') : t('ACCOUNT.BUTTON_SHOW')}
          onToggleShow={toggleVisibility}
        />
      ) : (
        <Row justifyContent="center">
          <Col lg={50} sm={100}>
            <TwofaWidget
              onSuccess={hideTwofaWidget}
              onSetPageLoading={setLoading}
              accessType="personalData"
              twofaText={t('ACCOUNT.ADDRESS.TFA_TEXT')}
              titleGrey={t('ACCOUNT.ADDRESS.TFA_GREY_TITLE')}
            />
          </Col>
        </Row>
      )}
    </SubLayout>
  )
}

export default Address
