import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Info from 'ui-kit/components/Info'
import Notification from 'ui-kit/components/Notification'
import Checkbox from 'ui-kit/components/Checkbox'
import { Row, Col } from 'ui-kit/components/Grid'
import Input from 'ui-kit/components/Input'

import { useDevice } from 'hooks/media'
import { useLang } from 'hooks/lang'
import { useVerification } from 'hooks/verification'
import { useProfileContext } from 'context/profile'
import { errorCapture } from 'utils/errorCapture'

import { getBankData, confirmBank } from 'api/bankData'

import SubLayout from 'layouts/SubLayout'
import ContentMobile from 'components/ContentMobile'

import { TitleGrey, TitleBlack } from 'layouts/SubLayout/style'

import {
  TopBlock,
  BlockTitleWrap,
  BlockTitle,
  AmountToTransfer,
  InputsForm,
  InputWrap,
  SubFormText,
  CheckboxWrapper,
} from './style'

import routes from 'constants/routes'
import statuses from 'constants/statuses'

const initialData = {
  iban: '',
  bic: '',
  name: '',
  refCode: '',
}

const BankVerification = () => {
  const isMobile = useDevice()
  const navigate = useNavigate()
  const { translate: t } = useLang()
  const { verification, isServiceAvailable } = useProfileContext()
  const { isVerificationDenied } = useVerification()

  const [isLoading, setLoading] = useState(false)
  const [isChecked, setIsChecked] = useState(false)
  const [bankData, setBankData] = useState(initialData)

  const isAccessNotAllowed = [statuses.pending.code, statuses.failed.code, statuses.denied.code].includes(
    verification.bank.code
  )
  const formData = [
    {
      label: t('BANK_VERIFICATION.IBAN.LABEL'),
      value: bankData.iban,
    },
    {
      label: t('BANK_VERIFICATION.BIC.LABEL'),
      value: bankData.bic,
    },
    {
      label: t('BANK_VERIFICATION.BENEFICIARY_NAME.LABEL'),
      value: bankData.name,
    },
    {
      label: t('BANK_VERIFICATION.REFERENCE_CODE.LABEL'),
      value: bankData.refCode,
    },
  ]

  useEffect(() => {
    if (isVerificationDenied || !isServiceAvailable || isAccessNotAllowed) navigate(routes.main)

    setLoading(true)
    getBankData()
      .then((res) => {
        setBankData(res.data)
        setLoading(false)
      })
      .catch((error) => {
        errorCapture(error)
      })
  }, [])

  const handleConfirm = () => {
    setLoading(true)
    confirmBank()
      .then(() => {
        navigate(routes.verification)
        setLoading(false)
      })
      .catch((error) => {
        errorCapture(error)
      })
  }

  return (
    <SubLayout
      isLoading={isLoading}
      confirmDisabled={!isChecked}
      onClickForward={handleConfirm}
      onClickBack={() => navigate(routes.main)}
    >
      <Row justifyContent="center">
        <Col lg={50} sm={100}>
          <ContentMobile>
            {!isMobile && <TitleGrey>{t('BANK_VERIFICATION.TITLE_GREY')}</TitleGrey>}
            <TitleBlack>{t('BANK_VERIFICATION.TITLE_BLACK')}</TitleBlack>
            <TopBlock>
              <BlockTitle>{t('BANK_VERIFICATION.AMOUNT.TITLE')}</BlockTitle>
              <AmountToTransfer>{t('BANK_VERIFICATION.AMOUNT.PRICE')}</AmountToTransfer>
              <Notification variant="yellow">{t('BANK_VERIFICATION.NOTIFICATION')}</Notification>
            </TopBlock>
          </ContentMobile>

          <ContentMobile>
            <InputsForm>
              <BlockTitleWrap>
                <BlockTitle>{t('BANK_VERIFICATION.TRANSFER_INFO.TITLE')}</BlockTitle>
                <Info text={t('BANK_VERIFICATION.TRANSFER_INFO.HINT')} />
              </BlockTitleWrap>
              {formData.map((inputData, index) => (
                <InputWrap key={index}>
                  <Input label={inputData.label} value={inputData.value} disableClearable enableCopy readOnly />
                </InputWrap>
              ))}
              <SubFormText>{t('BANK_VERIFICATION.UNDER_FORM_DESCRIPTION')}</SubFormText>
            </InputsForm>
            <CheckboxWrapper>
              <Checkbox
                checked={isChecked}
                label={t('BANK_VERIFICATION.CHECKBOX')}
                onChange={() => setIsChecked(!isChecked)}
              />
            </CheckboxWrapper>
          </ContentMobile>
        </Col>
      </Row>
    </SubLayout>
  )
}

export default BankVerification
