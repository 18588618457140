import React from 'react'
import { ColEl } from './style'

const Col = ({ children, sm, lg, p, m, order, pSm }) => {
  return (
    <ColEl sm={sm} lg={lg} p={p} pSm={pSm} m={m} order={order}>
      {children}
    </ColEl>
  )
}

export default Col
