import React, { useEffect, useState } from 'react'
import { Row, Col } from 'ui-kit/components/Grid'

import { getBackupCodes } from 'api/twofa'
import { useLang } from 'hooks/lang'
import { checkUserAuthToken } from 'hooks/auth'

import SubLayout from 'layouts/SubLayout'
import TwofaWidget from 'widgets/TwofaWidget'
import BackupCodesStep from 'pages/VerificationProcess/TwoFactor/components/BackupCodesStep'

import { errorCapture } from 'utils/errorCapture'

import routes from 'constants/routes'

const BackupCodes = () => {
  const { translate: t } = useLang()

  const [loading, setLoading] = useState(false)
  const [backupCodes, setBackupCodes] = useState([])
  const [isUserClaimed, setUserClaimed] = useState(false)

  useEffect(() => {
    async function checkUserGrants() {
      return await checkUserAuthToken({ claim: 'resetTwoFAUntil' })
    }
    checkUserGrants()
      .then((res) => setUserClaimed(res))
      .catch()
  }, [])

  useEffect(() => {
    async function fetchBackupCodes() {
      if (isUserClaimed && !backupCodes.length) {
        setLoading(true)

        await getBackupCodes()
          .then((resp) => {
            if (resp.data) setBackupCodes(resp.data)
          })
          .catch((error) => {
            errorCapture(error)
          })

        setLoading(false)
      }
    }
    fetchBackupCodes()
  }, [isUserClaimed])

  const hideTwofaWidget = () => {
    setUserClaimed(true)
  }

  return (
    <SubLayout
      isLoading={loading}
      hiddenNavigation
      customRoute={routes.accountTwoFactor}
      customBackToText={t('ACCOUNT.TFA.BACK_TO_TFA')}
    >
      {isUserClaimed ? (
        <BackupCodesStep backupCodes={backupCodes} />
      ) : (
        <Row justifyContent="center">
          <Col lg={50} sm={100}>
            <TwofaWidget
              onSuccess={hideTwofaWidget}
              onSetPageLoading={setLoading}
              accessType="resetTwoFA"
              subtitle={t('TFA_WIDGET.SUBTITLE.TFA')}
            />
          </Col>
        </Row>
      )}
    </SubLayout>
  )
}

export default BackupCodes
