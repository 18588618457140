import { doc, updateDoc, serverTimestamp } from 'firebase/firestore'
import { db, functions } from 'configs/firebase'
import { httpsCallable } from 'firebase/functions'

export const setTermsTimestamp = async ({ uid }) => {
  const docRef = doc(db, 'profiles', uid)
  await updateDoc(docRef, { termsAcceptedAt: serverTimestamp() })
}

export const updateProfile = httpsCallable(functions, 'signup-updateProfile')
export const setOnboardingFinished = httpsCallable(functions, 'signup-setOnboardingFinished')
export const assignDepositAddress = httpsCallable(functions, 'deposit-assignDepositAddress')
