import styled from 'styled-components/macro'
import { colors } from 'constants/colors'

export const InfoSubtext = styled.div`
  font-size: 12px;
  line-height: 16px;
  color: ${colors.gray600};

  a {
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: ${colors.gray950};
  }
`

export const ResendLink = styled.div`
  display: inline;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: ${(p) => (p.variant === 'pending' ? colors.gray400 : colors.gray950)};

  span {
    cursor: pointer;
  }
`
