import { colors } from 'constants/colors'
import styled from 'styled-components/macro'

export const VerificationCodeWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 12px -5px;
`

export const VerificationInput = styled.input`
  background-image: none;
  background-color: ${colors.gray50};
  box-shadow: none;
  text-align: center;
  width: 48px;
  height: 48px;
  border-radius: 8px;
  margin: 0 5px;
  border: 1px solid ${(p) => (p.noValue ? colors.gray150 : colors.gray300)};

  &:focus {
    outline: none;
  }
`
