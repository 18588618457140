import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import getCalculation from 'calculation-loan-data'
import bn from 'bignumber.js'

import Info from 'ui-kit/components/Info'
import Button from 'ui-kit/components/Button'
import { Row, Col } from 'ui-kit/components/Grid'

import { useDevice } from 'hooks/media'
import { useLang } from 'hooks/lang'
import { useVerification } from 'hooks/verification'
import { fixBtcRate } from 'api/rates'
import { useProfileContext } from 'context/profile'
import { colors } from 'constants/colors'
import routes from 'constants/routes'

import { errorCapture } from 'utils/errorCapture'

import ContentMobile from 'components/ContentMobile'
import { TitleGrey, TitleBlack } from 'layouts/SubLayout/style'
import LoanAmountInput from '../LoanAmountInput'
import PositionSummary from '../PositionSummary'

import { CalcResultTitle } from 'pages/Loan/style'

import { RecommendedValue, RecommendedBlock } from './style'
import SubLayout from 'layouts/SubLayout'

const calculationDefault = {
  loanAmount: 0,
  collateralEUR: 0,
  liquidationPrice: 0,
  liquidationThreshold: 0,
  liquidationPriceChange: 0,
  ltv: 0,
  minCollateralBTC: 0,
  collateralBTC: 0,
  monthlyFee: 0,
}

const DepositCalc = ({ dots, onClickBack, onConfirm }) => {
  const isMobile = useDevice()
  const navigate = useNavigate()
  const { translate: t } = useLang()
  const { isVerificationDenied } = useVerification()
  const { isLoaded, balances, contractDraft, position, isServiceAvailable } = useProfileContext()

  const isNotEnoughCollateral = !position && contractDraft?.collateralStatus === 'failed.ltv'
  const showRecommendation = isNotEnoughCollateral || position?.isLtvHigh

  const [isLoading, setLoading] = useState(false)
  const [btcRate, setBtcRate] = useState(null)
  const [inputNewCollateral, setInputNewCollateral] = useState(null)
  const [calculation, setCalculation] = useState(calculationDefault)

  const recommendedBtcCollateral = isNotEnoughCollateral
    ? Number(bn(contractDraft?.collateralBTC).minus(balances?.btcVerified).toFixed(8, bn.ROUND_UP))
    : '0.1'

  useEffect(() => {
    if ((isLoaded && !isServiceAvailable) || isVerificationDenied) navigate(routes.main)
    if (btcRate) return

    setLoading(true)
    fixBtcRate()
      .then(({ data }) => {
        const price = data.btcRate
        setBtcRate(price)
        setLoading(false)
      })
      .catch((error) => errorCapture(error))
  }, [btcRate])

  const creditCalculationHandler = ({ calcParams, isValueInput }) => {
    const frontCalc = getCalculation({ ...calcParams, btcRate })

    if (isValueInput && btcRate) {
      setCalculation(frontCalc)
    } else {
      setCalculation(calculationDefault)
    }
  }

  useEffect(() => {
    const calcParams = {
      inputEur: contractDraft?.loanAmount,
      inputBtc: bn(balances?.btcVerified).plus(inputNewCollateral).toFixed(8),
    }
    const isValueInput = !!inputNewCollateral
    creditCalculationHandler({ calcParams, isValueInput })
  }, [inputNewCollateral, btcRate])

  const handleCollateralChange = ({ value }) => {
    const firstSymbol = value.slice(0, 1)
    const secondSymbol = value.split('')[1]
    const inputValue = firstSymbol === '.' || secondSymbol === '0' ? '0.' : value
    setInputNewCollateral(inputValue)
  }

  const isNotZeroInputValue = ['0', '0.'].includes(inputNewCollateral)
  const isReady =
    Boolean(inputNewCollateral) && !isNotZeroInputValue && bn(inputNewCollateral).gte(recommendedBtcCollateral)

  return (
    <SubLayout
      onClickBack={onClickBack}
      dots={dots}
      // Local
      isLoading={isLoading}
      confirmDisabled={!isReady}
      infoText={t('LOAN.DEPOSIT.TITLE_BLACK_HINT')}
      forwardText={t(`LOAN.NAV_BUTTON.${isMobile ? 'NEXT' : 'CONFIRM'}`)}
      onClickForward={() => {
        setLoading(true)
        onConfirm()
      }}
    >
      <Row justifyContent="center">
        <Col lg={50} sm={100}>
          <>{!isMobile && <TitleGrey>{t('LOAN.DEPOSIT.TITLE_GREY')}</TitleGrey>}</>
          <ContentMobile>
            <Row alignItems="baseline">
              <Col sm={100}>
                <TitleBlack>{t('LOAN.DEPOSIT.TITLE_BLACK')}</TitleBlack>
              </Col>
              {!isMobile && (
                <Col>
                  <Info text={t('LOAN.DEPOSIT.TITLE_BLACK_HINT')} />
                </Col>
              )}
            </Row>

            <LoanAmountInput
              suffix="BTC"
              onValueChange={handleCollateralChange}
              value={inputNewCollateral || ''}
              placeholder="0.123"
              allowLeadingZeros
              decimalScale={8}
            />

            {showRecommendation && (
              <RecommendedBlock>
                <RecommendedValue>
                  {t('LOAN.DEPOSIT.RECOMMENDED')} {recommendedBtcCollateral} BTC
                </RecommendedValue>
                <Button
                  variant="filled"
                  textColor={colors.gray950}
                  bgColor={colors.gray150}
                  text={t('LOAN.DEPOSIT.RECOMMENDED_BUTTON')}
                  padding={isMobile ? '4px 8px' : '6px 12px'}
                  fontSize={isMobile ? '12px' : '14px'}
                  onClick={() => setInputNewCollateral(recommendedBtcCollateral)}
                />
              </RecommendedBlock>
            )}
          </ContentMobile>

          {isMobile && (
            <PositionSummary depositSummary liveCalc={calculation} depositCollateralValue={inputNewCollateral} />
          )}
        </Col>

        {!isMobile && (
          <Col lg={50}>
            <CalcResultTitle>{t('LOAN.DEPOSIT.SUMMARY.TITLE')}</CalcResultTitle>
            <PositionSummary depositSummary liveCalc={calculation} depositCollateralValue={inputNewCollateral} />
          </Col>
        )}
      </Row>
    </SubLayout>
  )
}

export default DepositCalc
