import { createContext, useContext, useState } from 'react'

export const Lang = createContext({
  lang: '',
  setLang: (code) => {},
})

export const LangContextProvider = ({ children }) => {
  const [lang, setLang] = useState('en')

  return <Lang.Provider value={{ lang, setLang }}>{children}</Lang.Provider>
}

export const useLangContext = () => useContext(Lang)
