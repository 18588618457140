// NOTE: uncomment string below for see all types on backend
// import eventTypes from '../../../functions/handlers/events/eventTypes'

import personIcon from 'assets/icons/verification/person.svg'
import locationIcon from 'assets/icons/verification/location.svg'
import cardIcon from 'assets/icons/verification/card.svg'
import keyIcon from 'assets/icons/verification/key.svg'
import coinIcon from 'assets/icons/credit-coin.svg'
import roundIcon from 'assets/icons/rounds.svg'
import btcIcon from 'assets/icons/btc-icon.svg'

/**
 * @param {string} type of event, see all event types on backend here /functions/handlers/events/eventTypes
 * @returns {string} path to icon in /assets
 */
export const getEventIcon = (type) => {
  switch (type) {
    case 'addressVerification':
      return locationIcon
    case 'identityVerification':
      return personIcon
    case 'bankVerification':
      return cardIcon
    case 'twofaAppVerification':
    case 'twofaCodesVerification':
    case 'resetTwofa':
      return keyIcon
    case 'credit':
      return coinIcon
    case 'btcTransfer':
      return btcIcon
    case 'borrow':
      return roundIcon

    default:
      return null
  }
}
