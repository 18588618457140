import styled from 'styled-components/macro'
import { ContentTemplate } from 'pages/commonStyle'

export const NotFoundWrap = styled.div`
  padding-top: 50px;
`
export const NotFoundContent = styled(ContentTemplate)``

export const NotFoundTitle = styled.div`
  font-weight: 500;
  font-size: 22px;
  line-height: 24px;
  margin-bottom: 15px;
`
export const NotFoundSubtitle = styled.div`
  margin-bottom: 24px;
  color: #5e666e;
`
export const NotFoundLink = styled.div``
