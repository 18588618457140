import React from 'react'
import NumberFormat from 'ui-kit/components/NumberFormat'

import { CalcEurInput, HighlightCalcEurInput } from './style'

const LoanAmountInput = ({
  placeholder,
  suffix,
  onValueChange,
  onMouseUp,
  onFocus,
  value,
  decimalScale,
  allowLeadingZeros,
  thousandSeparator,
  isSmallInputValue,
}) => {
  return (
    <NumberFormat
      testId="loan-amount-input"
      customInput={isSmallInputValue ? HighlightCalcEurInput : CalcEurInput}
      placeholder={`${placeholder}${suffix}`}
      onValueChange={onValueChange}
      onFocus={onFocus}
      onMouseUp={onMouseUp}
      thousandSeparator={thousandSeparator}
      suffix={suffix}
      value={value}
      decimalScale={decimalScale}
      autoFocus
      allowNegative={false}
      allowLeadingZeros={allowLeadingZeros}
      pattern="[0-9]*"
    />
  )
}

export default LoanAmountInput
