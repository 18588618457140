import React, { useState, useEffect } from 'react'
import { useNavigate, useLocation, Link } from 'react-router-dom'
import { signInWithEmailLink, isSignInWithEmailLink } from 'firebase/auth'

import { auth } from 'configs/firebase'

import AuthPageLayout from 'layouts/AuthPageLayout'
import statuses from 'pages/AuthPage/constants'

import routes from 'constants/routes'
import { useLang } from 'hooks/lang'

import { sendMagicLink } from 'pages/AuthPage/utils/sendMagicLink'
import { getNotificationText } from 'pages/AuthPage/utils/getNotificationText'
import { errorCapture } from 'utils/errorCapture'

import { InfoSubtext, ResendLink } from './style'
import Notification from 'ui-kit/components/Notification'

const AuthInfo = ({ status: currentStatus }) => {
  const navigate = useNavigate()
  const location = useLocation()

  const emailStorageName = 'emailForSignIn'
  const emailStorage =
    window.localStorage.getItem(emailStorageName) || new URLSearchParams(location.search).get('email') || ''

  const [status, setStatus] = useState(currentStatus)
  const [counter, setCounter] = useState(30)
  const { translate: t } = useLang()

  const isSignIn = isSignInWithEmailLink(auth, window.location.href)

  const isInvalidCode = status === 'auth/invalid-action-code'
  const isInvalidEmail = status === 'auth/invalid-email'

  useEffect(() => {
    if (!isSignIn) return

    signInWithEmailLink(auth, emailStorage)
      .then((result) => {
        window.localStorage.removeItem(emailStorageName)
        setStatus(statuses.linkSent)

        navigate(routes.main, { replace: true })
      })
      .catch((error) => {
        errorCapture(error)
        setStatus(error)
      })
  }, [isSignIn, emailStorage])

  useEffect(() => {
    if (currentStatus === statuses.linkSent && counter > 0) {
      const timer = counter > 0 && setInterval(() => setCounter(counter - 1), 1000)
      return () => clearInterval(timer)
    }
  }, [status, counter])

  const resendLinkHandler = async () => {
    const cachedEmail = window.localStorage.getItem(emailStorageName) || ''
    const currentStatus = await sendMagicLink(cachedEmail)
    setStatus(currentStatus)
    setCounter(30)
  }

  return (
    <AuthPageLayout bgImageColor="green" title={t(`AUTHORIZATION.INFO_PAGE.TITLE.${getNotificationText(status)}`)}>
      <Notification variant={status === statuses.linkSent ? 'green' : 'yellow'}>
        {t(`AUTHORIZATION.INFO_PAGE.NOTIFICATION.${getNotificationText(status)}`)}
      </Notification>

      <InfoSubtext>
        {t('AUTHORIZATION.INFO_PAGE.UNDER_NOTIFICATION.TEXT_1')}{' '}
        <ResendLink variant={counter === 0 ? 'active' : 'pending'}>
          {counter === 0 ? (
            <span onClick={resendLinkHandler}>{t('AUTHORIZATION.INFO_PAGE.UNDER_NOTIFICATION.TEXT_2')}</span>
          ) : (
            `${t('AUTHORIZATION.INFO_PAGE.UNDER_NOTIFICATION.TEXT_2')} in ${counter}s`
          )}
        </ResendLink>
      </InfoSubtext>

      {(isInvalidEmail || isInvalidCode) && (
        <Link to={routes.login}>{t('AUTHORIZATION.INFO_PAGE.UNDER_NOTIFICATION.LINK')}</Link>
      )}
    </AuthPageLayout>
  )
}

export default AuthInfo
