import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Button from 'ui-kit/components/Button'
import { Row, Col } from 'ui-kit/components/Grid'
import InfoBlock from 'ui-kit/components/InfoBlock'

import { useLang } from 'hooks/lang'
import { useDevice } from 'hooks/media'
import { checkUserAuthToken } from 'hooks/auth'
import { resetBackupCodes } from 'api/twofa'

import SubLayout from 'layouts/SubLayout'
import TwofaWidget from 'widgets/TwofaWidget'

import routes from 'constants/routes'
import ContentMobile from 'components/ContentMobile'

import { TitleBlack } from 'layouts/SubLayout/style'
import { ButtonWrapper, Title } from './styles'
import { errorCapture } from 'utils/errorCapture'

import { colors } from 'constants/colors'
import folderIcon from 'assets/icons/folder.svg'
import lockIcon from 'assets/icons/lock.svg'

const TwoFactor = () => {
  const isMobile = useDevice()
  const navigate = useNavigate()
  const { translate: t } = useLang()

  const [loading, setLoading] = useState(false)
  const [isWidgetVisible, setWidgetVisible] = useState(false)

  const createNewCodes = async () => {
    setLoading(true)

    await resetBackupCodes()
      .then((resp) => {
        if (resp.data) navigate(routes.accountTwoFactorCodes)
      })
      .catch((error) => {
        errorCapture(error)
      })

    setLoading(false)
  }

  const generateNewCodesHandler = async () => {
    const isTokenClaimed = await checkUserAuthToken({ claim: 'resetTwoFAUntil' })
    isTokenClaimed ? createNewCodes() : setWidgetVisible(true)
  }

  const hideTwofaWidget = () => {
    setWidgetVisible(false)
    createNewCodes()
  }

  const GenerateBackupCodeButton = () => (
    <ButtonWrapper isMobile={isMobile}>
      <Button
        variant={isMobile ? 'filled' : 'outlined'}
        width={isMobile ? '100%' : 'fit-content'}
        icon="circle-arrows"
        text="Generate new back up codes"
        onClick={generateNewCodesHandler}
        fontSize={isMobile ? '16px' : undefined}
        padding={isMobile ? '9px 16px' : '5px 16px'}
      />
    </ButtonWrapper>
  )

  const onNavigate = (route) => {
    navigate(route)
  }

  const blocksData = [
    {
      icon: lockIcon,
      title: 'App authenticator',
      subtitle: 'Help protect your account by using a verification code',
      buttonIcon: 'circle-arrows',
      buttonText: 'Change',
      route: routes.accountTwoFactorApp,
    },
    {
      icon: folderIcon,
      title: 'Back up codes',
      subtitle: 'Lost access to your authenticator app? See your backup codes',
      buttonIcon: 'eye',
      buttonText: 'See back up codes',
      route: routes.accountTwoFactorCodes,
    },
  ]

  return (
    <>
      <SubLayout
        isLoading={loading}
        hiddenNavigation
        customRoute={isWidgetVisible ? routes.accountTwoFactor : routes.profile}
        customBackToText={isWidgetVisible ? t('ACCOUNT.TFA.BACK_TO_TFA') : t('ACCOUNT.BACK_TO_ACCOUNT')}
      >
        {!isWidgetVisible ? (
          <>
            {!isMobile && <TitleBlack>{t('ACCOUNT.TFA.TITLE')}</TitleBlack>}
            <ContentMobile>
              <Row justifyContent={isMobile ? 'center' : 'start'} m={'-8'}>
                {isMobile && <Title>{t('ACCOUNT.TFA.TITLE')}</Title>}
                {blocksData.map((data) => (
                  <Col lg={50} sm={100} p={8} key={data.title}>
                    <InfoBlock
                      mode={isMobile ? 'row' : 'card'}
                      infoData={data}
                      border={colors.gray300}
                      marginBottom={isMobile && '12px'}
                      onClickBlock={() => onNavigate(data.route)}
                      cursor="pointer"
                      handlerType="button"
                      buttonNoText={isMobile}
                      buttonIconSize={isMobile && '16px'}
                    />
                  </Col>
                ))}
              </Row>
            </ContentMobile>
            {!isMobile && <GenerateBackupCodeButton />}
          </>
        ) : (
          <Row justifyContent="center">
            <Col lg={50} sm={100}>
              <TwofaWidget
                onSuccess={hideTwofaWidget}
                onSetPageLoading={setLoading}
                accessType="resetTwoFA"
                twofaText={t('ACCOUNT.TFA.TFA_TEXT')}
                titleGrey={t('ACCOUNT.TFA.TFA_GREY_TITLE')}
              />
            </Col>
          </Row>
        )}
      </SubLayout>
      {isMobile && !isWidgetVisible && <GenerateBackupCodeButton />}
    </>
  )
}

export default TwoFactor
