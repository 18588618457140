import styled from 'styled-components/macro'

export const Link = styled.a`
  display: flex;
  text-decoration: none;

  :hover {
    cursor: pointer;
  }
`
