import styled from 'styled-components/macro'
import { colors } from 'constants/colors'

import arrowIcon from './assets/arrow-right-gray.svg'
import uploadIcon from './assets/upload.svg'
import trashIcon from './assets/trash.svg'
import eyeIcon from './assets/eye.svg'
import eyeClosedIcon from './assets/eye-closed.svg'
import plusIcon from './assets/plus.svg'
import plusWhiteIcon from './assets/plus-white.svg'
import editIcon from './assets/edit.svg'
import downloadIcon from './assets/download.svg'
import printIcon from './assets/print.svg'
import circleArrowsIcon from './assets/circle-arrows.svg'
import borrowIcon from './assets/borrow-icon.svg'
import roundIcon from './assets/round.svg'

const iconChooser = (icon) => {
  switch (icon) {
    case 'upload':
      return uploadIcon
    case 'remove':
      return trashIcon
    case 'eye':
      return eyeIcon
    case 'eye-closed':
      return eyeClosedIcon
    case 'plus':
      return plusIcon
    case 'plus-white':
      return plusWhiteIcon
    case 'edit':
      return editIcon
    case 'arrow':
      return arrowIcon
    case 'download':
      return downloadIcon
    case 'print':
      return printIcon
    case 'borrow':
      return borrowIcon
    case 'round':
      return roundIcon

    case 'circle-arrows':
      return circleArrowsIcon
    default:
      return ''
  }
}

const isFilled = (p) => p.variant === 'filled'
const isOutlined = (p) => p.variant === 'outlined'
const isText = (p) => p.variant === 'text'

export const ButtonEl = styled.button`
  width: ${(p) => (p.width ? p.width : 'auto')};
  height: ${(p) => (p.height ? p.height : 'auto')};
  padding: ${(p) => (p.padding ? p.padding : '10px 16px')};
  background-color: ${(p) =>
    p.bgColor ? p.bgColor : isOutlined(p) ? 'transparent' : isFilled(p) ? colors.gray950 : isText(p) && 'transparent'};
  border-radius: 4px;
  font-size: ${(p) => (p.fontSize ? p.fontSize : '14px')};
  color: ${(p) => (p.textColor ? p.textColor : isOutlined(p) ? colors.gray950 : isFilled(p) && colors.white)};
  cursor: ${(p) => (p.disabled ? 'default' : 'pointer')};
  border: ${(p) =>
    isText(p)
      ? 'none'
      : isOutlined(p)
      ? `1px solid ${colors.gray400}`
      : isFilled(p) && `1px solid ${p.bgColor || colors.gray950}`};
  position: relative;
  vertical-align: top;

  &:disabled {
    background: ${colors.gray150};
    border: 1px solid ${colors.gray150};
    color: ${colors.gray300};
    cursor: auto;
  }
`

export const ButtonIcon = styled.div`
  background-image: url(${(p) => iconChooser(p.icon)});
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: contain;
  width: ${(p) => (p.iconSize ? p.iconSize : '20px')};
  height: ${(p) => (p.iconSize ? p.iconSize : '20px')};
  display: inline-block;
  vertical-align: middle;
  margin-right: ${(p) => (p.noText ? '0' : '10px')};
`

export const ButtonText = styled.div`
  visibility: ${(p) => (p.isLoading ? 'hidden' : 'visible')};
  display: inline-block;
  vertical-align: middle;
  white-space: nowrap;
`

export const ButtonSpinner = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`
