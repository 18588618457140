import React, { useState } from 'react'

import Button from '../Button'
import AdditionalData from './components/AdditionalData'

import {
  InfoBlockWrap,
  InfoTitle,
  InfoSubtitle,
  InfoIcon,
  InfoStatus,
  InfoTextBlock,
  InfoMain,
  InfoButton,
  DropdownIcon,
  CrossIcon,
  ArrowIcon,
  DeleteIcon,
  StatusIcon,
  StatusText,
} from './style'

const InfoBlock = ({
  id,
  testId,
  mode,
  infoData,
  alignItems,
  variant,
  bgColor,
  border,
  borderMiddle,
  marginBottom,
  boxShadow,
  iconBgColor,
  padding,
  onClickBlock,
  onClickButton,
  titleColor,
  cursor,
  showStatusInSubtitle,
  buttonIconSize,
  buttonNoText,
  handlerType,
  additionalButtonText,
  additionalBlockMode,
  isBlockDisabled,
  noInfoIcon,
}) => {
  const { title, subtitle, buttonText, buttonIcon, status, icon, disabled, additionalInfo } = infoData
  const [isOpen, setIsOpen] = useState(false)
  const isDropdown = handlerType === 'dropdown'
  const isButton = handlerType === 'button' && buttonText
  const isCross = handlerType === 'cross'
  const isStatusText = handlerType === 'statusText'
  const isStatusIcon = handlerType === 'statusIcon'
  const isArrow = handlerType === 'arrow'
  const isDelete = handlerType === 'delete'

  const onClick = () => {
    if (onClickBlock) {
      onClickBlock()
    } else if (isDropdown) {
      setIsOpen(!isOpen)
    } else {
      return null
    }
  }

  return (
    <>
      <InfoBlockWrap
        id={id}
        data-testid={testId}
        mode={mode}
        onClick={onClick}
        alignItems={alignItems}
        variant={variant || (isOpen && 'united')}
        bgColor={bgColor}
        border={border}
        borderMiddle={borderMiddle}
        marginBottom={isOpen ? '0' : marginBottom}
        boxShadow={boxShadow}
        padding={padding}
        cursor={cursor}
      >
        <InfoMain mode={mode} alignItems={!subtitle && 'center'} disabled={disabled || isBlockDisabled}>
          {!noInfoIcon && <InfoIcon icon={icon} mode={mode} iconBgColor={iconBgColor} />}
          <InfoTextBlock mode={mode}>
            <InfoTitle id="block-title" titleColor={titleColor}>
              {title}
            </InfoTitle>
            {showStatusInSubtitle && (
              <>
                <InfoStatus data-testid="verification-status" color={status?.color}>
                  {status?.text}
                </InfoStatus>
                {' · '}
              </>
            )}
            {subtitle && <InfoSubtitle>{subtitle}</InfoSubtitle>}
          </InfoTextBlock>
        </InfoMain>
        <InfoButton mode={mode} disabled={disabled || isBlockDisabled}>
          {isButton && (
            <Button
              variant="outlined"
              text={buttonText}
              icon={buttonIcon}
              noText={buttonNoText}
              cursor={cursor}
              height={buttonNoText ? '24px' : undefined}
              width={buttonNoText ? '24px' : undefined}
              padding={buttonNoText ? '0' : '5px 16px'}
              iconSize={buttonIconSize}
              onClick={onClickButton}
            />
          )}
          {isDropdown && <DropdownIcon isOpen={isOpen} />}
          {isCross && <CrossIcon onClick={onClickButton} />}
          {isArrow && <ArrowIcon />}
          {isDelete && <DeleteIcon />}
          {isStatusIcon && <StatusIcon id={status?.code} data-testid="status-icon" icon={status?.icon} />}
          {isStatusText && (
            <StatusText id={status?.code} data-testid="status-text" color={status?.color}>
              {status?.text}
            </StatusText>
          )}
        </InfoButton>
      </InfoBlockWrap>
      {isOpen && (
        <AdditionalData
          border={border}
          marginBottom={marginBottom}
          bgColor={bgColor}
          data={additionalInfo}
          additionalButtonText={additionalButtonText}
          mode={additionalBlockMode}
        />
      )}
    </>
  )
}

export default InfoBlock
