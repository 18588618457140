import statuses from 'constants/statuses'
import { getEventIcon } from 'utils/getEventIcon'

import moment from 'moment'

export const prepareEventData = ({ data, t }) => {
  const statusData = statuses[data.status]
  const { data: additionalData } = data

  const isCreditPending = data.type === 'credit' && data.status === 'btcReceived'
  const isEventStatusPending = data.type === 'btcTransfer' || isCreditPending

  const getSubtitle = (type) => {
    switch (type) {
      case 'btcTransfer':
        return t('EVENTS.BTC_TRANSFER_SUBTITLE')

      case 'credit':
        return t('EVENTS.CREDIT_PENDING')

      default:
        return
    }
  }

  return {
    title: t(`EVENTS.${data.name}`),
    subtitle: isEventStatusPending
      ? getSubtitle(data.type)
      : moment.unix(data.createdAt.seconds).format('MMM Do, YYYY'),
    status: statusData,
    icon: getEventIcon(data.type),
    buttonText: statusData?.text,
    route: null,
    disabled: false,
    additionalInfo: additionalData && {
      eventType: data.type,
      ...additionalData,
    },
  }
}
