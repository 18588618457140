import styled from 'styled-components/macro'

import { colors } from 'constants/colors'
import { device } from 'constants/media'
import documentIcon from 'assets/icons/document.svg'
import successIcon from 'assets/icons/statuses/process-success.svg'
import previewPdfImg from 'assets/icons/preview-pdf.png'

export const InputWrap = styled.div`
  margin-bottom: 16px;

  @media ${device.mobile} {
    &:last-child {
      margin: 0;
    }
  }
`

export const TitleProof = styled.div`
  color: ${colors.gray800};
`

export const UploadBlock = styled.div`
  background-color: ${colors.gray50};
  border: 1px dashed ${colors.gray150};
  padding: 18px;
  text-align: center;
  border-radius: 8px;
`

export const UploadIcon = styled.div`
  background-image: url(${(p) => (p.icon === 'success' ? successIcon : p.icon === 'document' ? documentIcon : '')});
  width: 20px;
  height: 20px;
  margin: 0 auto 4px;
`

export const UploadTitle = styled.div`
  color: ${colors.gray500};
  font-weight: 500;
  font-size: 10px;
  text-transform: uppercase;
`

export const UploadDescription = styled.div`
  color: ${colors.gray400};
  margin-bottom: 15px;
`

export const UploadInput = styled.input`
  cursor: pointer;
  top: 0;
  right: 0;
  width: 184%;
  height: 100%;
  position: absolute;
  opacity: 0;
  z-index: 1;
`

export const UploadButtonWrap = styled.div`
  position: relative;
  overflow: hidden;
`

export const UploadDocumentImg = styled.div`
  width: 75px;
  height: 100px;
  margin: 12px 0 8px;
  background-image: url(${previewPdfImg});
  background-size: contain;
  background-repeat: no-repeat;
  margin: auto;
`

export const UploadDocumentName = styled.div`
  margin: -11px 0 12px;
`
