import statuses from 'pages/AuthPage/constants'

export const getNotificationText = (status) => {
  switch (status) {
    case 'auth/internal-error':
      return 'INTERNAL_ERROR'
    case 'auth/invalid-action-code':
      return 'INVALID_CODE'
    case 'auth/invalid-email':
      return 'INVALID_EMAIL'
    case statuses.error:
      return 'HAS_ERROR'

    case statuses.linkSent:
    default:
      return 'LINK_SEND'
  }
}
