import styled from 'styled-components/macro'
import { colors } from 'constants/colors'
import { device } from 'constants/media'

export const TransferSubtitle = styled.div`
  color: ${colors.gray800};
`

export const TransferBtc = styled.div`
  font-family: 'Roboto Mono', monospace;
  font-size: 24px;
  color: ${colors.gray800};
`

export const QRCodeImg = styled.img`
  width: 168px;
  height: 168px;
  margin-left: -11px;
`

export const TransferInput = styled.div`
  margin-bottom: 24px;

  @media ${device.mobile} {
    margin-bottom: 0;
  }
`

export const TransferNotification = styled.div`
  margin-bottom: 24px;

  @media ${device.mobile} {
    margin-bottom: 0;
  }
`

export const TransferTop = styled.div`
  @media ${device.mobile} {
    text-align: center;
  }
`
