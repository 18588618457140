import { useEffect, useState } from 'react'
import { doc, onSnapshot } from 'firebase/firestore'

import { db } from 'configs/firebase'
import { useAuth } from './auth'

export function useLoans() {
  const { uid } = useAuth()
  const [loanData, setLoanData] = useState({ isLoanDataLoaded: false })

  useEffect(() => {
    let unsubscribe = () => {}
    if (uid) {
      const docRef = doc(db, 'loans', uid)
      let loan = {}
      unsubscribe = onSnapshot(docRef, (doc) => {
        if (doc.exists()) {
          const data = doc.data()
          loan = {
            haveAllConfirms:
              !!data.contractDraft?.confirmedAt &&
              !!data.contractDraft?.collateralStatus &&
              !!data.contractDraft?.bankAccount,
            position: data.position
              ? {
                  btcRate: data.position?.btcRate,
                  collateralBTC: data.position?.collateralBTC,
                  collateralEUR: data.position?.collateralEUR,
                  creditAvailable: data.position?.creditAvailable,
                  liquidationPrice: data.position?.liquidationPrice,
                  liquidationThreshold: data.position?.liquidationThreshold,
                  liquidationPriceChange: data.position?.liquidationPriceChange,
                  loanAmount: data.position?.loanAmount,
                  ltv: data.position?.ltv,
                  isLtvHigh: data.position?.isLtvHigh,
                  monthlyFee: data.position?.monthlyFee,
                }
              : null,
            contractDraft: data.contractDraft
              ? {
                  collateralBTC: data.contractDraft?.collateralBTC,
                  collateralEUR: data.contractDraft?.collateralEUR,
                  creditAvailable: data.contractDraft?.creditAvailable,
                  ltv: data.contractDraft?.ltv,
                  liquidationPrice: data.contractDraft?.liquidationPrice,
                  liquidationThreshold: data.contractDraft?.liquidationThreshold,
                  liquidationPriceChange: data.contractDraft?.liquidationPriceChange,
                  interestRate: data.contractDraft?.interestRate,
                  monthlyFee: data.contractDraft?.monthlyFee,
                  btcRate: data.contractDraft?.btcRate,
                  loanAmount: data.contractDraft?.loanAmount,
                  isLtvHigh: data.contractDraft?.isLtvHigh,
                  confirmedAt: data.contractDraft?.confirmedAt,
                  collateralStatus: data.contractDraft?.collateralStatus,
                  bankAccount: data.contractDraft?.bankAccount,
                }
              : null,
          }

          setLoanData({ ...loan, isLoanDataLoaded: true })
        }
      })
    }

    return () => {
      unsubscribe()
    }
  }, [uid])

  return {
    ...loanData,
  }
}
