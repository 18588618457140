import { doc, updateDoc, serverTimestamp } from 'firebase/firestore'
import { db, functions } from 'configs/firebase'
import { httpsCallable } from 'firebase/functions'

export const setConfirmDownloadCodes = async ({ uid }) => {
  const docRef = doc(db, 'profiles', uid)
  await updateDoc(docRef, { backupCodesSavedAt: serverTimestamp() })
}

export const initTwoFASecret = httpsCallable(functions, 'twofa-initTwoFASecret')
export const initBackupCodes = httpsCallable(functions, 'twofa-initBackupCodes')

export const getBackupCodes = httpsCallable(functions, 'twofa-getBackupCodes')

export const resetBackupCodes = httpsCallable(functions, 'twofa-resetBackupCodes')
export const resetTwoFASecret = httpsCallable(functions, 'twofa-resetTwoFASecret')

export const confirmTwoFASecret = httpsCallable(functions, 'twofa-confirmTwoFASecret')
