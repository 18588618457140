import _ from 'lodash'

export const downloadTxtFile = ({ content, fileName }) => {
  const codes = _(content)
    .chunk(5)
    .map((row) => row.join('\t'))
    .join('\n\n')

  const element = document.createElement('a')
  const file = new Blob([codes], { type: 'text/plain;charset=utf-8' })
  element.href = URL.createObjectURL(file)
  element.download = fileName
  document.body.appendChild(element)
  element.click()
}
