import styled from 'styled-components/macro'
import { colors } from 'constants/colors'
import { device } from 'constants/media'

import arrowIcon from './assets/arrow-down-black.svg'
import crossIcon from './assets/cancel.svg'
import arrowBtnIcon from './assets/arrow-right-gray.svg'
import deleteIcon from 'assets/icons/delete.svg'

const isTransp = (p) => p.bgColor === 'transparent'
const isUnited = (p) => p.variant === 'united'
const isModeCard = (p) => p.mode === 'card'
const isModeRow = (p) => p.mode === 'row'
const isMobile = (p) => p.mode === 'mobile'

export const InfoBlockWrap = styled.div`
  background-color: ${(p) => (isTransp(p) ? 'transparent' : colors.white)};
  border-radius: 12px;
  cursor: ${(p) => (p.cursor ? p.cursor : 'default')};
  height: ${(p) => (isModeCard(p) ? '216px' : 'auto')};
  padding: ${(p) => (isModeCard(p) ? '16px' : p.padding ? p.padding : '12px 16px')};
  margin-bottom: ${(p) => (p.marginBottom ? p.marginBottom : '0')};
  display: flex;
  flex-direction: ${(p) => (isModeCard(p) ? 'column' : 'row')};
  align-items: ${(p) => (p.alignItems ? p.alignItems : 'unset')};
  justify-content: space-between;
  ${(p) => p.boxShadow && `box-shadow: 0px 1px 12px -4px rgb(21 31 41 / 3%), 0px 16px 20px -8px rgb(21 31 41 / 1%)`};
  ${(p) => p.border && `border: 1px solid ${p.border}`};
  ${(p) => isUnited(p) && p.border && `border-bottom: none`};
  ${(p) => isUnited(p) && p.borderMiddle && `border-bottom: 1px solid ${p.borderMiddle}`};
  ${(p) => isUnited(p) && `border-radius: 0`};

  &:first-child {
    ${(p) => isUnited(p) && `border-top-left-radius: 12px`};
    ${(p) => isUnited(p) && `border-top-right-radius: 12px`};
  }

  &:last-child {
    ${(p) => isUnited(p) && p.border && `border-bottom: 1px solid ${p.border}`};
    ${(p) => isUnited(p) && p.borderMiddle && `border-bottom: none`};
    ${(p) => isUnited(p) && `border-bottom-left-radius: 12px`};
    ${(p) => isUnited(p) && `border-bottom-right-radius: 12px`};
  }
`

export const InfoIcon = styled.div`
  width: ${(p) => (p.iconBgColor === 'transparent' ? '20px' : '42px')};
  max-width: ${(p) => (p.iconBgColor === 'transparent' ? '20px' : '42px')};
  height: ${(p) => (p.iconBgColor === 'transparent' ? '20px' : '42px')};
  background-color: ${(p) => (p.iconBgColor ? p.iconBgColor : colors.gray100)};
  background-image: url(${(p) => p.icon});
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 4px;
  margin-bottom: 12px;
  margin: ${(p) => (isModeRow(p) ? '0' : isModeCard(p) ? '0 0 12px' : 'unset')};
  flex: 2;
`

export const InfoTextBlock = styled.div`
  padding: ${(p) => (isModeRow(p) ? '0 12px' : 'unset')};
  flex: 10;

  @media ${device.mobile} {
    font-size: 12px;
  }
`

export const InfoTitle = styled.div`
  margin-bottom: 2px;
  ${(p) => p.titleColor && `color: ${p.titleColor}`};
`

export const InfoSubtitle = styled.div`
  color: ${colors.gray600};
  display: inline;
`

export const InfoStatus = styled.div`
  color: ${(p) => p.color};
  display: inline;
`

export const StatusIcon = styled.div`
  width: 20px;
  height: 20px;
  background-image: url(${(p) => p.icon});
`

export const InfoMain = styled.div`
  display: ${(p) => (isModeCard(p) ? 'block' : 'flex')};
  align-items: ${(p) => (p.alignItems ? p.alignItems : 'unset')};
  flex: 11;
  ${(p) => p.disabled && `opacity: 0.3`};
`

export const InfoButton = styled.div`
  ${(p) => p.disabled && `opacity: 0.3`};
  ${(p) => isModeRow(p) && `display: flex`};
  ${(p) => isModeRow(p) && `justify-content: end`};
  flex: 1;
`

export const DropdownIcon = styled.div`
  width: 20px;
  height: 20px;
  background-image: url(${arrowIcon});
  background-repeat: no-repeat;
  background-position: center;
  transform: rotate(${(p) => (p.isOpen ? '0deg' : '180deg')});
`

export const Additional = styled(InfoBlockWrap)`
  position: relative;
  flex-direction: column;
  align-items: flex-start;
`

export const AdditionalRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  flex-direction: ${(p) => (isMobile(p) ? 'column' : 'row')};
`

export const AdditionalCol = styled.div`
  ${(p) => isMobile(p) && `width: 100%`};
`

export const AdditionalTitle = styled.div`
  font-size: 14px;
  color: ${colors.gray500};
`

export const AdditionalSubtitle = styled.div`
  font-family: 'Roboto Mono', monospace;
  padding-left: ${(p) => (isMobile(p) ? '0' : '4px')};
`

export const AdditionalText = styled.div`
  display: flex;
  margin-bottom: 8px;
  ${(p) => isMobile(p) && `justify-content: space-between`};

  &:last-child {
    margin-bottom: 0;
  }
`

export const AdditionalInfoButton = styled.div`
  float: ${(p) => (isMobile(p) ? 'none' : 'right')};
  ${(p) => isMobile(p) && `margin-top: 10px`};
`

export const CrossIcon = styled.div`
  width: 20px;
  height: 20px;
  background-image: url(${crossIcon});
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
`

export const StatusText = styled.div`
  color: ${(p) => p.color};
`

export const ArrowIcon = styled.div`
  border-radius: 4px;
  border: 1px solid ${colors.gray300};
  width: 25px;
  height: 25px;
  background-image: url(${arrowBtnIcon});
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
`

export const DeleteIcon = styled.div`
  border-radius: 4px;
  border: 1px solid ${colors.gray300};
  width: 25px;
  height: 25px;
  background-image: url(${deleteIcon});
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
`
