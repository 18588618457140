import styled from 'styled-components/macro'
import { device } from 'constants/media'
import { colors } from 'constants/colors'
import imgHistory from 'assets/images/history.png'
import imgCredit from 'assets/images/credit.png'

const imageChooser = (variant) => {
  switch (variant) {
    case 'history':
      return imgHistory
    case 'credit':
      return imgCredit

    default:
      return ''
  }
}

export const PageWrapper = styled.div`
  max-width: 768px;
  padding: 32px 12px;
  margin: auto;
  position: relative;
  height: inherit;
  background-color: ${colors.gray100};

  @media ${device.mobile} {
    height: 100%;
    background-color: ${colors.white};
    overflow-x: hidden;
  }
`

export const TextBlock = styled.div`
  height: fit-content;
  width: 360px;
  margin-bottom: 16px;

  @media ${device.mobile} {
    width: 100%;
    text-align: center;
  }
`

export const Text = styled.div`
  font-size: 16px;
  line-height: 20px;
  color: ${colors.gray950};

  @media ${device.mobile} {
    font-size: 14px;
    line-height: 20px;
  }
`

export const Image = styled.div`
  margin: 32px 0 32px auto;
  width: 360px;
  height: 360px;
  position: relative;
  background-image: url(${(p) => imageChooser(p.img)});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;

  @media ${device.mobile} {
    margin: 48px auto;
    width: 100%;
    height: 373px;
  }
`
