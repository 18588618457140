import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { default as axios } from 'axios'
import { createVeriffFrame, MESSAGES } from '@veriff/incontext-sdk'
import { Row, Col } from 'ui-kit/components/Grid'

import { useLang } from 'hooks/lang'
import { useProfileContext } from 'context/profile'
import { useVerification } from 'hooks/verification'
import { submitIdentityData } from 'api/identity'
import { errorCapture } from 'utils/errorCapture'
import statuses from 'constants/statuses'

import SubLayout from 'layouts/SubLayout'
import NotFound from 'components/NotFound'
import PersonalDataForm from './components/PersonalDataForm'
import VeriffStep from './components/VeriffStep'

import routes from 'constants/routes'
import _ from 'lodash'

const initFormState = {
  firstName: '',
  lastName: '',
  birthDay: '',
  birthMonth: '',
  birthYear: '',
  fundsSource: [],
  occupation: '',
  isBeneficiary: false,
  isNotPEP: false,
}

const IdentityVerification = () => {
  const { uid, verification } = useProfileContext()
  const { isVerificationDenied } = useVerification()
  const navigate = useNavigate()
  const { translate: t } = useLang()

  const [veriffSessionStatus, setVeriffSessionStatus] = useState(null)
  const localFormData = window.localStorage.getItem('localFormData')
  const [formData, setFormData] = useState(JSON.parse(localFormData) || initFormState)
  const [activeStep, setActiveStep] = useState(0)

  const isSessionFinish = veriffSessionStatus === MESSAGES.FINISHED
  const isSessionHasError = veriffSessionStatus === 'ERROR'
  const isAccessAllowed = [statuses.notStarted.code, statuses.failed.code].includes(verification?.identity.code)
  const isDataFilled =
    formData.firstName.length > 0 &&
    formData.lastName.length > 0 &&
    Object.keys(formData.birthDay).length > 0 &&
    Object.keys(formData.birthMonth).length > 0 &&
    Object.keys(formData.birthYear).length > 0 &&
    Object.keys(formData.fundsSource).length > 0 &&
    Object.keys(formData.occupation).length > 0 &&
    formData.isBeneficiary &&
    formData.isNotPEP
  const requestData = {
    verification: {
      person: {
        firstName: formData.firstName,
        lastName: formData.lastName,
      },
      vendorData: uid,
      timestamp: new Date().toISOString(),
    },
  }

  useEffect(() => {
    if (isVerificationDenied) navigate(routes.main)

    if (!localFormData) {
      window.localStorage.setItem('localFormData', JSON.stringify(initFormState))
    }
  }, [])

  useEffect(() => {
    const userData = _.omit(formData, ['isBeneficiary', 'isNotPEP'])
    window.localStorage.setItem('localFormData', JSON.stringify(userData))
  }, [formData])

  const handleFormData = (field, value) => {
    setFormData((prev) => ({
      ...prev,
      [field]: value,
    }))
  }

  const submitPersonalDataHandler = async ({ sessionId }) => {
    const personalData = {
      firstName: formData.firstName,
      lastName: formData.lastName,
      birthDate: new Date(`${formData.birthDay.value} ${formData.birthMonth.value} ${formData.birthYear.value}`),
      sourceOfFunds: formData.fundsSource.map((value) => value.value),
      occupation: formData.occupation.value,
      isBeneficiary: formData.isBeneficiary,
      isNotPEP: formData.isNotPEP,
      sessionId,
      uid,
    }

    await submitIdentityData(uid, personalData)
  }

  // Create NEW veriff session
  const startVeriffSession = async () => {
    const API_URL = 'https://stationapi.veriff.com/v1/sessions/'
    const requestHeaders = {
      'Content-Type': 'application/json',
      'X-AUTH-CLIENT': process.env.REACT_APP_VERIFF_API_KEY,
    }
    axios
      .post(API_URL, requestData, {
        headers: requestHeaders,
      })
      .then(async (response) => {
        const { verification, status } = response.data

        if (status === 'success') {
          await submitPersonalDataHandler({ sessionId: verification.id })
          setVeriffSessionStatus(status)
          createVeriffFrame({
            url: verification.url,
            onEvent: function (msg) {
              switch (msg) {
                case MESSAGES.CANCELED:
                  setVeriffSessionStatus(msg)
                  break
                case MESSAGES.FINISHED:
                  setVeriffSessionStatus(msg)
                  window.localStorage.removeItem('localFormData')
                  break
                default:
                  return
              }
            },
          })
        }
      })
      .catch((error) => {
        setVeriffSessionStatus('error')
        errorCapture(error)
      })
  }

  const handleConfirm = () => {
    if (activeStep === 0) setActiveStep(activeStep + 1)
    if (activeStep === 1) navigate(routes.verification)
  }

  const onClickBack = () => {
    if (activeStep === 0) navigate(routes.main)
    if (activeStep > 0) setActiveStep(activeStep - 1)
  }

  const steps = [
    {
      component: <PersonalDataForm onFormChange={handleFormData} formData={formData} />,
      isActive: activeStep === 0,
    },
    {
      component: (
        <VeriffStep onStartSession={startVeriffSession} isFinish={isSessionFinish} hasError={isSessionHasError} />
      ),
      isActive: activeStep === 1,
    },
  ]

  return (
    <>
      {isAccessAllowed || isSessionFinish ? (
        <SubLayout
          confirmDisabled={activeStep === 1 ? !isSessionFinish : !isDataFilled}
          onClickForward={handleConfirm}
          onClickBack={onClickBack}
          forwardText={activeStep === 0 ? t('NAVIGATION.BUTTON.NEXT') : t('NAVIGATION.BUTTON.DONE')}
          dots={steps.map((step) => step.isActive)}
        >
          <Row justifyContent="center">
            <Col lg={50} sm={100}>
              {steps[activeStep].isActive && steps[activeStep].component}
            </Col>
          </Row>
        </SubLayout>
      ) : (
        <NotFound />
      )}
    </>
  )
}

export default IdentityVerification
