import styled from 'styled-components/macro'
import { colors } from 'constants/colors'

const textColor = (color) => {
  switch (color) {
    case 'green':
      return colors.green500
    case 'yellow':
      return colors.yellow500
    case 'red':
      return colors.red500
    default:
      return colors.gray500
  }
}

const bgColor = (color) => {
  switch (color) {
    case 'green':
      return colors.green50
    case 'yellow':
      return colors.yellow100
    case 'red':
      return colors.red50
    default:
      return colors.gray50
  }
}

export const TagEl = styled.div`
  ${(p) => p.icon && `background-image: url(${p.icon})`};
  background-color: ${(p) => bgColor(p.bgColor)};
  background-repeat: no-repeat;
  background-position: 5% 50%;
  font-weight: 500;
  font-size: ${(p) => p.fontSize};
  text-transform: uppercase;
  color: ${(p) => textColor(p.textColor)};
  padding: ${(p) => (p.icon ? '6px 12px 6px 30px' : '6px 12px')};
  border-radius: 100px;
  display: inline-block;
  width: fit-content;
  height: fit-content;
`
