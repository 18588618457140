import styled from 'styled-components/macro'

import { device } from 'constants/media'
import { colors } from 'constants/colors'

export const TopBlock = styled.div`
  display: flex;
  flex-direction: column;

  @media ${device.mobile} {
    align-items: center;
  }
`

export const BlockTitleWrap = styled.div`
  display: flex;
  align-items: center;
`

export const BlockTitle = styled.div`
  margin-right: 4px;
  color: ${colors.gray800};
`

export const AmountToTransfer = styled.div`
  margin: 8px 0;
  font-family: 'Roboto Mono', monospace;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: -0.04em;
  color: ${colors.gray800};
`

export const InputsForm = styled.div`
  padding: 16px 0;

  @media ${device.mobile} {
    padding: 0;
  }
`

export const InputWrap = styled.div`
  margin: 8px 0;

  @media ${device.mobile} {
    margin: 12px 0;

    &:last-child {
      margin: 0;
    }
  }
`

export const SubFormText = styled.div`
  color: ${colors.gray500};
`

export const CheckboxWrapper = styled.div`
  padding: 8px 0;

  @media ${device.mobile} {
    padding: 25px 0 12px;
  }
`
