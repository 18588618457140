import React, { useState, useRef, useEffect } from 'react'

import { VerificationCodeWrapper, VerificationInput } from './style'

const VerificationCode = ({ length, isLoading, onComplete, isCodeIncorrect }) => {
  const [code, setCode] = useState([...Array(length)].map(() => ''))
  const inputs = useRef([])

  const onInputChange = (num, index) => {
    if (/[^0-9]/.test(num)) return

    const newCode = [...code]
    newCode[index] = num
    setCode(newCode)

    if (index !== length - 1) {
      inputs.current[index + 1].focus()
    }

    if (newCode.every((num) => num !== '')) {
      onComplete(newCode.join(''))
    }
  }

  const passClipboardValue = (value) => {
    inputs.current[5].focus()
    setCode(value)

    if (value.every((num) => num !== '')) {
      onComplete(value.join(''))
    }
  }

  const onKeyUp = (event, index) => {
    if (event.keyCode === 8 && !code[index] && index !== 0) {
      const newCode = [...code]
      newCode[index - 1] = ''
      setCode(newCode)
      inputs.current[index - 1].focus()
    }
  }

  useEffect(() => {
    if (isCodeIncorrect) {
      setCode([...Array(length)].map(() => ''))
      inputs.current[0].focus()
    }
  }, [isCodeIncorrect])

  return (
    <VerificationCodeWrapper>
      {code.map((num, index) => (
        <VerificationInput
          key={index}
          type="text"
          inputMode="numeric"
          noValue={num.length === 0}
          value={num}
          autoFocus={!code[0].length && index === 0}
          readOnly={isLoading}
          onChange={(event) => {
            if (event.target.value.length === 6) {
              const pastedValue = event.target.value.split('')
              passClipboardValue(pastedValue)
            } else {
              onInputChange(event.target.value, index)
            }
          }}
          onKeyUp={(event) => onKeyUp(event, index)}
          ref={(ref) => inputs.current.push(ref)}
        />
      ))}
    </VerificationCodeWrapper>
  )
}

export default VerificationCode
