import { useEffect, useState } from 'react'
import { doc, onSnapshot } from 'firebase/firestore'
import bn from 'bignumber.js'

import { db } from 'configs/firebase'
import getCalculation from 'calculation-loan-data'

export function usePosition({ balances, position }) {
  const [livePosition, setLivePosition] = useState(null)

  useEffect(() => {
    let unsubscribe = () => {}
    if (!Boolean(position)) return unsubscribe()

    const btcRef = doc(db, 'rates', 'btceur')

    unsubscribe = onSnapshot(btcRef, (snap) => {
      if (snap.exists) {
        const { price: btcRate } = snap.data()
        const inputEur = position?.loanAmount
        const inputBtc = position?.collateralBTC || balances?.btcVerified

        const result = getCalculation({ inputEur, inputBtc, btcRate })
        const { collateralBTC, minCollateralBTC, defaultLTV } = result

        // Spare collateral available for either withdrawal or borrow more
        const btcAvailable = bn.max(
          0,
          bn(collateralBTC)
            .minus(minCollateralBTC)
            .minus(balances?.btcPending || 0)
            .toFixed(8, bn.ROUND_UP)
        )

        setLivePosition({
          ...result,
          paymentDay: position?.paymentDay,
          btcAvailable: Number(btcAvailable),
          creditAvailable: Number(bn(btcAvailable).times(defaultLTV).times(btcRate).toFixed(2, bn.ROUND_DOWN)),
        })
      }
    })

    return () => {
      unsubscribe()
    }
  }, [position])

  return livePosition
}
