import styled from 'styled-components/macro'
import { colors } from 'constants/colors'

export const SelectWrap = styled.div`
  position: relative;
`

export const SelectLabel = styled.div`
  color: ${colors.gray500};
  font-weight: 500;
  font-size: 10px;
  text-transform: uppercase;
  position: absolute;
  z-index: 1;
  left: 10px;
  top: 7px;
`
