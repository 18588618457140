import React from 'react'
import { SwitcherWrapper, SwitcherButtons, SwitcherButtonsItem } from './styles'

export const Switcher = ({ activeMethod, handleChangeMethod }) => {
  const toggleActiveMethod = (method) => {
    handleChangeMethod(method)
  }

  return (
    <SwitcherWrapper>
      <SwitcherButtons>
        <SwitcherButtonsItem active={activeMethod === 'app'} onClick={() => toggleActiveMethod('app')}>
          App authenticator
        </SwitcherButtonsItem>
        <SwitcherButtonsItem active={activeMethod === 'codes'} onClick={() => toggleActiveMethod('codes')}>
          Back up codes
        </SwitcherButtonsItem>
      </SwitcherButtons>
    </SwitcherWrapper>
  )
}
