import React, { useState, useEffect } from 'react'
import _ from 'lodash'
import Button from 'ui-kit/components/Button'
import InfoBlock from 'ui-kit/components/InfoBlock'

import { useProfileContext } from 'context/profile'
import { useLang } from 'hooks/lang'
import { useDevice } from 'hooks/media'

import { filterEventLogBy } from 'api/events'

import { errorCapture } from 'utils/errorCapture'
import { prepareEventData } from 'utils/prepareEventData'

import { colors } from 'constants/colors'
import routes from 'constants/routes'

import MainLayout from 'layouts/MainLayout'
import NotesPageTemplate from 'components/NotesPageTemplate'
import Filter from './Filter'

import { HistoryH2 } from './style'

const History = () => {
  const limitEvents = 20

  const { translate: t } = useLang()
  const isMobile = useDevice()
  const { uid } = useProfileContext()

  const [canLoadMore, setCanLoadMore] = useState(true)
  const [isPending, setPending] = useState(true)
  const [lastVisible, setLastVisible] = useState(null)
  const [selectedFilters, setSelectedFilters] = useState([]) // Array of String
  const [filteredEvents, setFilteredEvents] = useState([])
  const [isFilterApply, setApplyFilter] = useState(false)
  const [isOrderByDesc, setOrderByDesc] = useState(true)
  const sortOrder = isOrderByDesc ? 'desc' : 'asc'

  const loadEventLogChunk = (startAfter) => {
    // return immediately if we're "loading more" is pending
    if (isPending && lastVisible) return
    setPending(true)

    filterEventLogBy({
      uid,
      limitEvents,
      filter: selectedFilters,
      sortOrder,
      lastVisible: startAfter,
    })
      .then(({ events, lastSnap }) => {
        if (!events.length) setCanLoadMore(false)
        if (!lastVisible) {
          setFilteredEvents(events)
        } else {
          setFilteredEvents((prev) => [...prev, ...events])
        }
        setLastVisible(lastSnap)
      })
      .catch((e) => errorCapture(e))
      .finally(() => setPending(false))
  }

  // Call API function to get filtered event data
  useEffect(() => {
    if (isMobile && isFilterApply) {
      setLastVisible(null)
      setFilteredEvents([])
      loadEventLogChunk()
      setApplyFilter(false)
    }

    if (!isMobile) {
      setLastVisible(null)
      setFilteredEvents([])
      loadEventLogChunk()
    }
  }, [selectedFilters, isOrderByDesc, isFilterApply])

  // On button clink load next 5 events
  const loadMore = () => {
    if (lastVisible) loadEventLogChunk(lastVisible)
  }

  // Toggle filter state - checked or not
  const toggleFilter = (type) => {
    const newFilter = selectedFilters.includes(type)
      ? _.reject(selectedFilters, (el) => el === type)
      : [...selectedFilters, type]
    setSelectedFilters(newFilter)
  }

  const resetFilter = () => {
    toggleFilter(null)
  }

  const renderEvents = filteredEvents.length > 0 || isPending

  return (
    <MainLayout activeNav="history" mobileTitle={t('HISTORY.MOBILE_TITLE')}>
      <Filter
        onSelectCheckbox={toggleFilter}
        selected={selectedFilters}
        onApplyFilter={() => setApplyFilter(true)}
        resetFilter={resetFilter}
        setOrder={() => setOrderByDesc(!isOrderByDesc)}
      />
      {renderEvents ? (
        <>
          <HistoryH2>yesterday</HistoryH2>
          {filteredEvents.map((data) => {
            const eventData = prepareEventData({ data, t, isMobile })
            return (
              <div key={data.id}>
                <InfoBlock
                  infoData={eventData}
                  mode="row"
                  border={isMobile ? colors.gray200 : colors.gray150}
                  iconBgColor={colors.gray150}
                  marginBottom="8px"
                  alignItems="center"
                  padding="12px 16px"
                  cursor={!!eventData.additionalInfo ? 'pointer' : 'default'}
                  bgColor={isMobile && 'transparent'}
                  handlerType={eventData.additionalInfo && 'dropdown'}
                  showStatusInSubtitle={!!data.status}
                />
              </div>
            )
          })}
          {canLoadMore && (
            <Button variant="outlined" onClick={() => loadMore()} text="Load more" disabled={isPending} />
          )}
        </>
      ) : (
        <NotesPageTemplate
          title={t('HISTORY.TITLE')}
          texts={t('HISTORY.TEXT')}
          buttonText={t('HISTORY.BUTTON')}
          link={routes.calculator}
          img="history"
        />
      )}
    </MainLayout>
  )
}

export default History
