import React from 'react'
import bn from 'bignumber.js'

import { useLang } from 'hooks/lang'
import { useProfileContext } from 'context/profile'
import { useLangContext } from 'context/lang'

import MainLayout from 'layouts/MainLayout'
import NotesPageTemplate from 'components/NotesPageTemplate'

import routes from 'constants/routes'
import { formatNumberByLocale } from 'utils/formatNumberByLocale'

const BorrowConfirmPage = ({ skipTransferStep }) => {
  const { currency, contractDraft, position, haveAllConfirms } = useProfileContext()
  const { lang } = useLangContext()
  const { translate: t } = useLang()

  if (!haveAllConfirms && !skipTransferStep) return

  const maxAmountAllowed = bn(position?.loanAmount).plus(position?.creditAvailable).toFixed(0)
  const text_1 = bn(contractDraft?.loanAmount).gt(maxAmountAllowed)
    ? t('LOAN.BORROW.CONFIRM_STEP.TEXT_1_COLLATERAL')
    : t('LOAN.BORROW.CONFIRM_STEP.TEXT_1')
  const text_2 = t('LOAN.BORROW.CONFIRM_STEP.TEXT_2')

  const amount = formatNumberByLocale({
    lang,
    currency,
    number: Number(bn(contractDraft?.loanAmount).minus(position?.loanAmount).toFixed(0)),
  })

  // NOTE: To make texts an array with one string we join into one complete string and then
  // split by random symbol - &&
  const texts = text_1.concat(amount).concat(text_2).join(' ').split('&&')

  return (
    <MainLayout mobileTitle={t('LOAN.BORROW.CONFIRM_STEP.TITLE_BLACK')}>
      <NotesPageTemplate
        title={t('LOAN.BORROW.CONFIRM_STEP.TITLE_BLACK')}
        titleGrey={t('LOAN.BORROW.CONFIRM_STEP.TITLE_GREY')}
        texts={texts}
        buttonText={t('LOAN.BORROW.CONFIRM_STEP.BUTTON')}
        link={routes.main}
        img="borrow"
      />
    </MainLayout>
  )
}

export default BorrowConfirmPage
