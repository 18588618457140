import styled from 'styled-components/macro'
import { colors } from 'constants/colors'
import { device } from 'constants/media'

export const RecommendedBlock = styled.div`
  margin-bottom: 30px;

  @media ${device.mobile} {
    text-align: center;
    margin-bottom: 16px;
  }
`

export const RecommendedValue = styled.div`
  font-weight: 500;
  font-size: 12px;
  color: ${colors.gray500};
  text-transform: uppercase;
  margin-bottom: 16px;

  @media ${device.mobile} {
    margin-bottom: 16px;
    font-size: 10px;
  }
`
