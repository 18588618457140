import styled from 'styled-components/macro'

import { colors } from 'constants/colors'
import { device } from 'constants/media'

export const InputBlock = styled.div`
  padding: 12px 0;

  @media ${device.mobile} {
    padding: 0;
  }
`

export const InputNameWrapper = styled.div`
  @media ${device.mobile} {
    margin: 12px 0;
  }
`

export const DateBlockTitle = styled.div`
  color: ${colors.gray800};
`

export const DateBlockSubtitle = styled.div`
  margin: 4px 0 12px;
  font-size: 12px;
  line-height: 16px;
  color: ${colors.gray600};
`

export const CheckboxWrapper = styled.div`
  padding: 8px 0;
`
