import React from 'react'
import bn from 'bignumber.js'

import { useProfileContext } from 'context/profile'
import { useDevice } from 'hooks/media'
import { useLang } from 'hooks/lang'

import Info from 'ui-kit/components/Info'
import { Row, Col } from 'ui-kit/components/Grid'
import GreenArrow from 'ui-kit/components/GreenArrow'

import ContentMobile from 'components/ContentMobile'

import { TitleBlack } from 'layouts/SubLayout/style'
import {
  ResultTitle,
  ResultTableBlock,
  ResultTableRow,
  ResultTableTitleWrap,
  ResultTableTitle,
  ResultTableNum,
} from './style'

import { prepareOpenCreditData, prepareDepositCollateral, prepareBorrowMoreData } from './prepareSummaryData'
import getCalculation from 'calculation-loan-data'

const PositionSummary = ({
  liveCalc,
  differenceCalc,
  depositCollateralValue,
  borrow,
  collateralBtc,
  isMobileCalcConfirmStep,
  calcStep,
  depositSummary,
  borrowSummary,
}) => {
  const isMobile = useDevice()
  const { translate: t } = useLang()
  const { currency, contractDraft, position } = useProfileContext()

  const currencyIcon = currency === 'EUR' ? '€' : !currency ? '' : '$'

  // Get fixed BTC rate
  const btcRate = liveCalc?.btcRate || contractDraft?.btcRate

  // Calculation based on EUR and BTC input and fixed btc rate
  const positionAfter = liveCalc || contractDraft
  // Calculation based current position loanAmount and collateralBTC and fixed btc rate
  const positionBefore = getCalculation({
    inputEur: position?.loanAmount,
    inputBtc: position?.collateralBTC,
    btcRate,
  })

  let summaryData
  if (depositSummary) {
    summaryData = prepareDepositCollateral({
      depositCollateral: depositCollateralValue || 0,
      liveCalc,
      position: positionBefore,
      currencyIcon,
      t,
      compact: isMobile,
    })
  } else if (borrowSummary) {
    summaryData = prepareBorrowMoreData({
      borrow: calcStep ? borrow || 0 : bn(contractDraft?.loanAmount).minus(position?.loanAmount).toFixed(0),
      collateralBtc: calcStep
        ? collateralBtc || 0
        : Number(bn(contractDraft?.collateralBTC).minus(position?.collateralBTC).toFixed(8)),
      liveCalc: positionAfter,
      position: positionBefore,
      currencyIcon,
      t,
      compact: isMobile && !isMobileCalcConfirmStep,
    })
  } else {
    summaryData = prepareOpenCreditData({
      calculationData: positionAfter,
      differenceCalc,
      currencyIcon,
      t,
      compact: isMobile && !isMobileCalcConfirmStep,
    })
  }

  return (
    <div data-testid="summary">
      {summaryData.map((block, index) => (
        <ContentMobile noPadding={isMobile ? true : false} key={index}>
          {isMobileCalcConfirmStep && index === 0 && (
            <ResultTitle>
              <TitleBlack>{t('CREDIT_LINE.SUMMARY.TITLE')}</TitleBlack>
            </ResultTitle>
          )}
          <ResultTableBlock>
            {block.map((row) => (
              <ResultTableRow key={row.title} data-testid={row.testId}>
                <Row m={isMobile ? -16 : -8} justifyContent="space-between">
                  <Col>
                    <ResultTableTitleWrap>
                      <ResultTableTitle>{row.title}</ResultTableTitle>
                      {row.infoText && <Info text={row.infoText} />}
                    </ResultTableTitleWrap>
                  </Col>
                  <Col>
                    <Row m={-4} alignItems="center">
                      <Col p={4}>{row.greenValue && <GreenArrow number={row.greenValue} />}</Col>
                      <Col p={4}>
                        <ResultTableNum hasChanged={row.hasChanged}>
                          {row.value !== 'N/A' ? (
                            <>
                              {row.value}
                              {row.suffix === 'BTC' && ' '}
                              {row.suffix}
                            </>
                          ) : (
                            <>{row.value}</>
                          )}
                        </ResultTableNum>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                {row.titleGray && row.valueGray && (
                  <Row m={isMobile ? -16 : -8} justifyContent="space-between">
                    <Col>
                      <ResultTableTitle gray>{row.titleGray}</ResultTableTitle>
                    </Col>
                    <Col>
                      <ResultTableNum gray>
                        {row.valueGray === '' ? (
                          'N/A'
                        ) : (
                          <>
                            {row.valueGray}
                            {row.suffix === 'BTC' && ' '}
                            {row.suffix}
                          </>
                        )}
                      </ResultTableNum>
                    </Col>
                  </Row>
                )}
              </ResultTableRow>
            ))}
          </ResultTableBlock>
        </ContentMobile>
      ))}
    </div>
  )
}

export default PositionSummary
