import React, { useState, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { signInWithEmailLink, isSignInWithEmailLink } from 'firebase/auth'

import { auth } from 'configs/firebase'

import { useLang } from 'hooks/lang'

import { getNotificationText } from 'pages/AuthPage/utils/getNotificationText'
import statuses from 'pages/AuthPage/constants'

import Notification from 'ui-kit/components/Notification'
import AuthPageLayout from 'layouts/AuthPageLayout'

import routes from 'constants/routes'
import FormBlock from '../FormBlock'

import { NavigateText, NavigateLink } from './style'

const AuthConfirm = () => {
  const navigate = useNavigate()
  const location = useLocation()

  const emailStorageName = 'emailForSignIn'
  const emailStorage =
    window.localStorage.getItem(emailStorageName) || new URLSearchParams(location.search).get('email') || ''

  const [email, setEmail] = useState(emailStorage)
  const [status, setStatus] = useState('')
  const { translate: t } = useLang()

  const isSignIn = isSignInWithEmailLink(auth, window.location.href)

  useEffect(() => {
    if (!isSignIn) return

    signInWithEmailLink(auth, email)
      .then((result) => {
        window.localStorage.removeItem(emailStorageName)
        setStatus(statuses.linkSent)

        navigate(routes.main, { replace: true })
      })
      .catch((error) => {
        setStatus(error.code)
      })
  }, [isSignIn, email, navigate])

  const reSubmitEmail = (submittedEmail) => {
    setEmail(submittedEmail)
    setStatus('')
  }

  switch (status) {
    case 'auth/invalid-email':
      return (
        <FormBlock
          formLabel={t(`AUTHORIZATION.INFO_PAGE.TITLE.${getNotificationText(status)}`)}
          buttonText={t(`AUTHORIZATION.FORM.BUTTON.RE_SUBMIT_EMAIL`)}
          isResubmitEmail={true}
          errorText={t(`AUTHORIZATION.INFO_PAGE.NOTIFICATION.${getNotificationText(status)}`)}
          onSubmit={reSubmitEmail}
        />
      )

    case 'auth/invalid-action-code':
    case 'auth/internal-error':
      return (
        <AuthPageLayout bgImageColor="red" title={t(`AUTHORIZATION.INFO_PAGE.TITLE.${getNotificationText(status)}`)}>
          <Notification variant="red">
            {t(`AUTHORIZATION.INFO_PAGE.NOTIFICATION.${getNotificationText(status)}`)}
          </Notification>

          <NavigateText>
            {`Please try to `}
            <NavigateLink href={routes.login} onClick={() => setStatus('')}>
              {t('AUTHORIZATION.INFO_PAGE.UNDER_NOTIFICATION.LINK')}
            </NavigateLink>
          </NavigateText>
        </AuthPageLayout>
      )

    default:
      return null
  }
}

export default AuthConfirm
