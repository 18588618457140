import styled from 'styled-components/macro'
import { colors } from 'constants/colors'

export const NavigateText = styled.span`
  display: inline;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: ${colors.gray600};
`

export const NavigateLink = styled.a`
  display: inline;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  cursor: pointer;
  text-decoration: underline;
  color: ${colors.gray600};
`
