import React, { useRef } from 'react'
import { useReactToPrint } from 'react-to-print'
import Notification from 'ui-kit/components/Notification'
import Button from 'ui-kit/components/Button'
import Checkbox from 'ui-kit/components/Checkbox'
import { Row, Col } from 'ui-kit/components/Grid'

import { useDevice } from 'hooks/media'
import { useLang } from 'hooks/lang'

import ContentMobile from 'components/ContentMobile'

import { TitleGrey, TitleBlack } from 'layouts/SubLayout/style'

import { downloadTxtFile } from 'utils/downloadTxtFile'

import { BackupCodesButtonWrapper, BackupCodesWrapper, BackupCode, CheckboxWrapper } from '../style'

const BackupCodesStep = ({ backupCodes, handelDownloadConfirm, isConfirmed, checkboxIsVisible }) => {
  const isMobile = useDevice()
  const printRef = useRef()
  const { translate: t } = useLang()
  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  })

  return (
    <Row justifyContent="center">
      <Col lg={50} sm={100}>
        <ContentMobile>
          {!isMobile && <TitleGrey>{t('TFA_VERIFICATION.BACKUP_CODES.TITLE_GREY')}</TitleGrey>}
          <TitleBlack>{t('TFA_VERIFICATION.BACKUP_CODES.TITLE_BLACK')}</TitleBlack>
          <Notification variant="yellow">{t('TFA_VERIFICATION.BACKUP_CODES.DESCRIPTION')}</Notification>
          <BackupCodesWrapper ref={printRef}>
            {backupCodes.map((code) => (
              <BackupCode key={code}>{code}</BackupCode>
            ))}
          </BackupCodesWrapper>
          <BackupCodesButtonWrapper>
            <Button
              variant="outlined"
              icon="download"
              text={t('TFA_VERIFICATION.BACKUP_CODES.BUTTON_DOWNLOAD')}
              onClick={() => downloadTxtFile({ content: backupCodes, fileName: 'dascue_backup_codes.txt' })}
            />
            {!isMobile && (
              <Button
                variant="outlined"
                icon="print"
                text={t('TFA_VERIFICATION.BACKUP_CODES.BUTTON_PRINT')}
                onClick={handlePrint}
              />
            )}
          </BackupCodesButtonWrapper>
          {checkboxIsVisible && (
            <CheckboxWrapper>
              <Checkbox
                checked={isConfirmed}
                label={t('TFA_VERIFICATION.BACKUP_CODES.CHECKBOX')}
                onChange={handelDownloadConfirm}
              />
            </CheckboxWrapper>
          )}
        </ContentMobile>
      </Col>
    </Row>
  )
}

export default BackupCodesStep
