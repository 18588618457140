import { useEffect, useState } from 'react'
import { doc, onSnapshot } from 'firebase/firestore'

import { db } from 'configs/firebase'
import { useAuth } from './auth'
import statuses from 'constants/statuses'

export function useProfile() {
  const { uid, isLoggedIn } = useAuth()
  const [profile, setProfile] = useState({ isLoaded: false })

  useEffect(() => {
    let unsubscribe = () => {}
    if (uid) {
      const docRef = doc(db, 'profiles', uid)

      unsubscribe = onSnapshot(docRef, (doc) => {
        if (doc.exists()) {
          const data = doc.data()
          const identity = data.verification?.identity
          const address = data.verification?.address
          const bank = data.verification?.bank
          const twofa = data.verification?.twofa

          const getStatus = (field, isServiceAvailable) => {
            return field
              ? statuses[field] || statuses.unknown
              : isServiceAvailable !== undefined && !isServiceAvailable
              ? statuses.unavailable
              : statuses.notStarted
          }

          setProfile({
            ...data, // raw data from the profile doc
            isLoaded: true,
            country: {
              code: data.country?.code || '',
              label: data.country?.label || '',
            },
            currency: data.currency || '',
            isServiceAvailable: data.isServiceAvailable,
            isOnboardingFinished: Boolean(data.onboardingFinishedAt),
            isTermsAccepted: Boolean(data.termsAcceptedAt),
            backupCodesSavedAt: data.backupCodesSavedAt || null,
            uid: data.uid || null,
            verification: {
              identity: getStatus(identity),
              address: getStatus(address),
              bank: getStatus(bank, data.isServiceAvailable),
              twofa: getStatus(twofa),
            },
            btcDepositAddress: data.btcDepositAddress || null,
            refCode: data.refCode || null,
            unconfirmedTx:
              {
                amount: data.unconfirmedTx?.amount,
                confirmations: data.unconfirmedTx?.confirmations,
                totalConfirmations: data.unconfirmedTx?.totalConfirmations,
                txHash: data.unconfirmedTx?.txHash,
              } || {},
            balances: data.balances || {},
          })
        }
      })
    }

    return () => {
      unsubscribe()
    }
  }, [uid])

  return {
    ...profile,
    isLoggedIn,
  }
}
