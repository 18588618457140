import React from 'react'

import { NumericListWrapper, NumericListTitle, NumericListOl, NumericListLi } from './style'

const NumericList = ({ title, text }) => {
  return (
    <NumericListWrapper>
      <NumericListTitle>{title}</NumericListTitle>
      <NumericListOl>
        {Array.isArray(text) ? (
          <>
            {text.map((textItem, index) => (
              <NumericListLi key={index}>{textItem}</NumericListLi>
            ))}
          </>
        ) : (
          text
        )}
      </NumericListOl>
    </NumericListWrapper>
  )
}

export default NumericList
