import React, { useState } from 'react'
import { Link } from 'react-router-dom'

import { useLang } from 'hooks/lang'

import Checkbox from 'ui-kit/components/Checkbox'
import SubLayout from 'layouts/SubLayout'
import ContentMobile from 'components/ContentMobile'

import PositionSummary from '../PositionSummary'

import { LinkHighlights } from './style'

import routes from 'constants/routes'

const MobileResultsStep = ({ dots, onClickBack, onConfirm, borrowMore, depositCollateral }) => {
  const { translate: t } = useLang()

  const [agreementIsChecked, setAgreementIsChecked] = useState(false)

  return (
    <SubLayout
      onClickBack={onClickBack}
      dots={dots}
      // Local
      isLoading={false}
      confirmDisabled={!agreementIsChecked}
      infoText={t(`CREDIT_LINE.CALC_STEP.TITLE_BLACK_HINT.OPEN_CREDIT`)} // TODO need texts for Deposit and Borrow more
      forwardText={t(`CREDIT_LINE.BUTTON.CONFIRM`)}
      onClickForward={onConfirm}
    >
      <PositionSummary isMobileCalcConfirmStep borrowSummary={borrowMore} depositSummary={depositCollateral} />
      <ContentMobile noPadding>
        <Checkbox checked={agreementIsChecked} onChange={() => setAgreementIsChecked(!agreementIsChecked)}>
          {t('CREDIT_LINE.AGREEMENT_1')}
          <LinkHighlights>
            <Link to={routes.termsOfUse}>{'Terms and Conditions'}</Link>
          </LinkHighlights>
          {t('CREDIT_LINE.AGREEMENT_2')}
          <LinkHighlights>
            <Link to={routes.termsOfLoan}>{'General Terms of the Loan Agreement.'}</Link>
          </LinkHighlights>
        </Checkbox>
      </ContentMobile>
    </SubLayout>
  )
}

export default MobileResultsStep
