import styled from 'styled-components/macro'
import { colors } from 'constants/colors'

export const SelectBankWrap = styled.div`
  border: 1px solid;
  border-color: ${(p) => (p.checked ? colors.gray500 : colors.gray300)};
  cursor: pointer;
  padding: 8px 12px;
  border-radius: 8px;
  position: relative;
`

export const SelectBankTitle = styled.div``

export const SelectBankText = styled.div`
  color: ${colors.gray500};
`

export const SelectBankRadio = styled.div`
  position: absolute;
  right: 8px;
  top: 8px;
`
