import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import bn from 'bignumber.js'

import { useProfileContext } from 'context/profile'
import { useDevice } from 'hooks/media'
import routes from 'constants/routes'

import BorrowCalc from '../components/BorrowCalc'
import MobileResultsStep from '../components/MobileResultsStep'
import ConfirmCalculation from '../components/ConfirmCalcStep'
import TransferStep from '../components/TransferStep'
import BankStep from '../components/BankStep'
import BorrowConfirmPage from '../components/BorrowConfirmPage'

const Borrow = () => {
  const isMobile = useDevice()
  const navigate = useNavigate()
  const { contractDraft, position } = useProfileContext()

  const maxAmountAllowed = Number(bn(position?.loanAmount).plus(position?.creditAvailable).toFixed(0))
  const skipTransferStep = bn(contractDraft?.loanAmount).lte(maxAmountAllowed)

  const preDefineStep = () => {
    if (!contractDraft?.confirmedAt) {
      return 0
    } else if (!contractDraft?.collateralStatus && !contractDraft?.bankAccount) {
      return 3
    } else if (!contractDraft?.bankAccount) {
      return 4
    } else {
      return 5
    }
  }

  const [dots, setDots] = useState([])
  const [activeStep, setActiveStep] = useState(preDefineStep() || 0)

  useEffect(() => {
    // NOTE: If step has already confirmed so that
    // jump to next step which didn't confirm yet
    if (!steps[activeStep].isActive && activeStep < steps.length - 1) setActiveStep(activeStep + 1)
  }, [activeStep])

  const onClickBack = () => {
    switch (activeStep) {
      case 0:
        navigate(routes.main)
        break

      case 2:
        isMobile ? setActiveStep(1) : setActiveStep(0)
        break

      default:
        setActiveStep(activeStep - 1)
    }
  }

  const resultScreenMobile = {
    component: (
      <MobileResultsStep dots={dots} onClickBack={onClickBack} onConfirm={() => setActiveStep(2)} borrowMore />
    ),
    isActive: activeStep === 1,
  }

  const steps = [
    {
      component: <BorrowCalc dots={dots} onClickBack={onClickBack} onConfirm={() => setActiveStep(isMobile ? 1 : 2)} />,
      isActive: activeStep === 0,
    },
    ...(isMobile ? [resultScreenMobile] : [{}]),
    {
      component: (
        <ConfirmCalculation
          dots={dots}
          onClickBack={onClickBack}
          onConfirm={() => setActiveStep(skipTransferStep ? 4 : 3)}
          borrowMore
        />
      ),
      isActive: activeStep === 2,
    },
    ...(skipTransferStep
      ? [{}]
      : [
          {
            component: (
              <TransferStep dots={dots} onClickBack={onClickBack} onConfirm={() => setActiveStep(4)} borrowMore />
            ),
            isActive: activeStep === 3,
          },
        ]),

    {
      component: <BankStep dots={dots} onClickBack={onClickBack} onConfirm={() => setActiveStep(5)} borrowMore />,
      isActive: activeStep === 4,
    },
    {
      component: <BorrowConfirmPage skipTransferStep={skipTransferStep} />,
      isActive: activeStep === 5,
    },
  ]

  useEffect(() => {
    setDots(steps.map((step) => step.isActive))
  }, [activeStep])

  return steps[activeStep].isActive && steps[activeStep].component
}

export default Borrow
