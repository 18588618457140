import React from 'react'
import { usePopperTooltip } from 'react-popper-tooltip'
import 'react-popper-tooltip/dist/styles.css'

import { InfoIcon, TooltipContainer } from './style'

const Info = ({ text, isTransparent }) => {
  const { getArrowProps, getTooltipProps, setTooltipRef, setTriggerRef, visible } = usePopperTooltip()

  return (
    <>
      <InfoIcon isTransparent={isTransparent} ref={setTriggerRef} />
      {visible && (
        <TooltipContainer ref={setTooltipRef} {...getTooltipProps({ className: 'tooltip-container' })}>
          <div {...getArrowProps({ className: 'tooltip-arrow' })} />
          {text}
        </TooltipContainer>
      )}
    </>
  )
}

export default Info
