export const constants = {
  days: [
    { label: '01', value: '01' },
    { label: '02', value: '02' },
    { label: '03', value: '03' },
    { label: '04', value: '04' },
    { label: '05', value: '05' },
    { label: '06', value: '06' },
    { label: '07', value: '07' },
    { label: '08', value: '08' },
    { label: '09', value: '09' },
    { label: '10', value: '10' },
    { label: '11', value: '11' },
    { label: '12', value: '12' },
    { label: '13', value: '13' },
    { label: '14', value: '14' },
    { label: '15', value: '15' },
    { label: '16', value: '16' },
    { label: '17', value: '17' },
    { label: '18', value: '18' },
    { label: '19', value: '19' },
    { label: '20', value: '20' },
    { label: '21', value: '21' },
    { label: '22', value: '22' },
    { label: '23', value: '23' },
    { label: '24', value: '24' },
    { label: '25', value: '25' },
    { label: '26', value: '26' },
    { label: '27', value: '27' },
    { label: '28', value: '28' },
    { label: '29', value: '29' },
    { label: '30', value: '30' },
    { label: '31', value: '31' },
  ],
  months: [
    { label: 'January', value: 'January' },
    { label: 'February', value: 'February' },
    { label: 'March', value: 'March' },
    { label: 'May', value: 'May' },
    { label: 'June', value: 'June' },
    { label: 'July', value: 'July' },
    { label: 'August', value: 'August' },
    { label: 'September', value: 'September' },
    { label: 'October', value: 'October' },
    { label: 'November', value: 'November' },
    { label: 'December', value: 'December' },
  ],
  years: [
    { label: '1960', value: '1960' },
    { label: '1961', value: '1961' },
    { label: '1962', value: '1962' },
    { label: '1963', value: '1963' },
    { label: '1964', value: '1964' },
    { label: '1965', value: '1965' },
    { label: '1966', value: '1966' },
    { label: '1967', value: '1967' },
    { label: '1968', value: '1968' },
    { label: '1969', value: '1969' },
    { label: '1970', value: '1970' },
    { label: '1971', value: '1971' },
    { label: '1972', value: '1972' },
    { label: '1973', value: '1973' },
    { label: '1974', value: '1974' },
    { label: '1975', value: '1975' },
    { label: '1976', value: '1976' },
    { label: '1977', value: '1977' },
    { label: '1978', value: '1978' },
    { label: '1979', value: '1979' },
    { label: '1980', value: '1980' },
    { label: '1981', value: '1981' },
    { label: '1982', value: '1982' },
    { label: '1983', value: '1983' },
    { label: '1984', value: '1984' },
    { label: '1985', value: '1985' },
    { label: '1986', value: '1986' },
    { label: '1987', value: '1987' },
    { label: '1988', value: '1988' },
    { label: '1989', value: '1989' },
    { label: '1990', value: '1990' },
    { label: '1991', value: '1991' },
    { label: '1992', value: '1992' },
    { label: '1993', value: '1993' },
    { label: '1994', value: '1994' },
    { label: '1995', value: '1995' },
    { label: '1996', value: '1996' },
    { label: '1997', value: '1997' },
    { label: '1998', value: '1998' },
    { label: '1999', value: '1999' },
    { label: '2000', value: '2000' },
    { label: '2001', value: '2001' },
    { label: '2002', value: '2002' },
    { label: '2003', value: '2003' },
    { label: '2004', value: '2004' },
  ],
  fundsSource: [
    { label: 'Employment income', value: 'Employment income' },
    { label: 'Property sale', value: 'Property sale' },
    { label: 'Loan', value: 'Loan' },
    { label: 'Dividends item', value: 'Dividends item' },
    { label: 'Savings/deposits', value: 'Savings/deposits' },
  ],
  occupations: [
    { label: 'Entrepreneur', value: 'Entrepreneur' },
    { label: 'Senior/middle manager', value: 'Manager' },
    { label: 'Civil servant/specialist', value: 'Specialist' },
    { label: 'Attendant', value: 'Attendant' },
    { label: 'Student/pupil', value: 'Student' },
    { label: 'Not employed', value: 'Unemployed' },
    { label: 'Skilled worker', value: 'Labor' },
    { label: 'Retired', value: 'Retired' },
  ],
}
