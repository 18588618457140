import React from 'react'

import { TagEl } from './style'

const Tag = ({ text, bgColor, textColor, fontSize, icon }) => {
  return (
    <TagEl bgColor={bgColor} textColor={textColor} fontSize={fontSize} icon={icon}>
      {text}
    </TagEl>
  )
}

export default Tag
