import { doc, getDoc } from 'firebase/firestore'
import { db, functions } from 'configs/firebase'
import { httpsCallable } from 'firebase/functions'

export const getBtcRate = async () => {
  const ref = doc(db, 'rates', 'btceur')
  const snap = await getDoc(ref)
  return snap.get('price')
}

export const fixBtcRate = httpsCallable(functions, 'borrow-fixBtcRate')
