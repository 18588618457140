import React, { useRef } from 'react'
import { useNavigate } from 'react-router-dom'

import { PDFExport } from '@progress/kendo-react-pdf'

import { AuthTitle } from 'layouts/AuthPageLayout/style'
import {
  PolicyPage,
  PolicyPageImg,
  PolicyPageInnerWrapper,
  PolicyPageHeader,
  ButtonBack,
  SavePdfButton,
  PolicyDocument,
} from './style'

const PolicyAndTermsLayout = ({ children, pdfFileName, pageTitle }) => {
  const navigate = useNavigate()
  const pdfExportComponent = useRef(null)

  const saveAsPDF = () => {
    if (pdfExportComponent.current) {
      pdfExportComponent.current.save()
    }
  }

  return (
    <PolicyPage>
      <PolicyPageImg />
      <PolicyPageInnerWrapper>
        <PolicyPageHeader>
          <ButtonBack onClick={() => navigate(-1)} />
          <SavePdfButton onClick={saveAsPDF} />
        </PolicyPageHeader>
        <PolicyDocument>
          <PDFExport fileName={pdfFileName} paperSize="A4" margin="2cm" ref={pdfExportComponent}>
            <AuthTitle>{pageTitle}</AuthTitle>
            {children}
          </PDFExport>
        </PolicyDocument>
      </PolicyPageInnerWrapper>
    </PolicyPage>
  )
}

export default PolicyAndTermsLayout
