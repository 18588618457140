import { createContext, useContext } from 'react'
import { useProfile } from 'hooks/profile'
import { useLoans } from 'hooks/loans'
import { usePosition } from 'hooks/position'

const Profile = createContext({})

export const ProfileContextProvider = ({ children }) => {
  const profileData = useProfile()
  const loanData = useLoans()
  const livePosition = usePosition({ balances: profileData?.balances, position: loanData?.position })

  return <Profile.Provider value={{ ...profileData, ...loanData, position: livePosition }}>{children}</Profile.Provider>
}

export const useProfileContext = () => useContext(Profile)
