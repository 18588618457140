import { collection, getDocs } from 'firebase/firestore'
import { httpsCallable } from 'firebase/functions'
import { functions } from 'configs/firebase'

import { db } from 'configs/firebase'

const _ = require('lodash')

export const getUserBankAccount = async (uid) => {
  const collectionBankRef = collection(db, `profiles/${uid}/banks`)
  const accountsSnaps = await getDocs(collectionBankRef)

  const accounts = accountsSnaps.docs.map((account) => _.omit(account.data(), 'createdAt'))

  return accounts === 0 ? [] : accounts
}

export const getBankData = httpsCallable(functions, 'bank-getBankData')
export const confirmBank = httpsCallable(functions, 'bank-confirmBank')
