import styled from 'styled-components/macro'
import { colors } from 'constants/colors'
import radioUnchecked from './assets/radio-unchecked.svg'
import radioChecked from './assets/radio-checked.svg'
import radioCheckedDisabled from './assets/radio-checked-disabled.svg'
import radioUncheckedDisabled from './assets/radio-unchecked-disabled.svg'

export const RadioWrap = styled.div`
  cursor: ${(p) => (p.disabled ? 'default' : 'pointer')};
  display: flex;

  &:after {
    content: '';
    clear: both;
    display: block;
  }
`
export const RadioIcon = styled.div`
  width: 20px;
  height: 20px;
  margin-right: 8px;
  background-repeat: no-repeat;
  background-image: url(${(p) =>
    p.checked && !p.disabled
      ? radioChecked
      : p.disabled && !p.checked
      ? radioUncheckedDisabled
      : p.disabled && p.checked
      ? radioCheckedDisabled
      : radioUnchecked});
  float: left;
`

export const RadioText = styled.div`
  color: ${(p) => (p.disabled ? colors.gray300 : 'inherit')};
`
