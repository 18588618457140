import styled from 'styled-components/macro'

export const Title = styled.div`
  margin: 52px 8px 16px;
  width: 100%;

  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
`

export const ButtonWrapper = styled.div`
  width: ${(p) => (p.isMobile ? '100vw' : 'fit-content')};
  position: ${(p) => (p.isMobile ? 'absolute' : 'relative')};
  bottom: ${(p) => (p.isMobile ? '16px' : '')};
  padding: ${(p) => (p.isMobile ? '0 16px' : '')};
  margin: ${(p) => (p.isMobile ? '0' : '16px 0')};
`
