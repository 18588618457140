import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { useProfileContext } from 'context/profile'
import { useDevice } from 'hooks/media'
import routes from 'constants/routes'

import CreditCalc from '../components/CreditCalc'
import MobileResultsStep from '../components/MobileResultsStep'
import ConfirmCalculation from '../components/ConfirmCalcStep'
import TransferStep from '../components/TransferStep'
import BankStep from '../components/BankStep'

const CreditLine = () => {
  const isMobile = useDevice()
  const navigate = useNavigate()
  const { contractDraft, haveAllConfirms } = useProfileContext()

  useEffect(() => {
    if (haveAllConfirms) navigate(routes.main)
  }, [])

  const preDefineStep = () => {
    if (!contractDraft?.confirmedAt) {
      return 0
    } else if (!contractDraft?.collateralStatus) {
      return 3
    } else if (!contractDraft?.bankAccount) {
      return 4
    }
  }

  const [dots, setDots] = useState([])
  const [activeStep, setActiveStep] = useState(preDefineStep() || 0)

  useEffect(() => {
    // NOTE: If step has already confirmed so that
    // jump to next step which didn't confirm yet
    if (!steps[activeStep].isActive && activeStep < steps.length - 1) setActiveStep(activeStep + 1)
  }, [activeStep])

  const onClickBack = () => {
    switch (activeStep) {
      case 0:
        navigate(routes.main)
        break

      case 2:
        isMobile ? setActiveStep(1) : setActiveStep(0)
        break

      default:
        setActiveStep(activeStep - 1)
    }
  }

  const resultScreenMobile = {
    component: <MobileResultsStep dots={dots} onClickBack={onClickBack} onConfirm={() => setActiveStep(2)} />,
    isActive: activeStep === 1 && !contractDraft?.confirmedAt,
  }

  const steps = [
    {
      component: <CreditCalc dots={dots} onClickBack={onClickBack} onConfirm={() => setActiveStep(isMobile ? 1 : 2)} />,
      isActive: activeStep === 0 && !contractDraft?.confirmedAt,
    },
    ...(isMobile ? [resultScreenMobile] : [{}]),
    {
      component: <ConfirmCalculation dots={dots} onClickBack={onClickBack} onConfirm={() => setActiveStep(3)} />,
      isActive: activeStep === 2 && !contractDraft?.confirmedAt,
    },
    {
      component: <TransferStep dots={dots} onClickBack={onClickBack} onConfirm={() => setActiveStep(4)} />,
      isActive: activeStep === 3 && !contractDraft?.collateralStatus,
    },
    {
      component: (
        <BankStep dots={dots} onClickBack={onClickBack} onConfirm={() => navigate(routes.creditConfirmation)} />
      ),
      isActive: activeStep === 4 && !contractDraft?.bankAccount,
    },
  ]

  useEffect(() => {
    setDots(steps.map((step) => step.isActive))
  }, [activeStep])

  return steps[activeStep].isActive && steps[activeStep].component
}

export default CreditLine
