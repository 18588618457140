import React from 'react'

import eventTypes from 'constants/eventTypes'

import Button from '../../Button'

import {
  AdditionalText,
  AdditionalTitle,
  AdditionalSubtitle,
  AdditionalInfoButton,
  Additional,
  AdditionalRow,
  AdditionalCol,
} from '../style'

const AdditionalData = ({ data, border, marginBottom, bgColor, additionalButtonText, mode }) => {
  const isCredit = data.eventType === eventTypes.credit
  const isBorrow = data.eventType === eventTypes.borrow
  const isMobile = mode === 'mobile'

  return (
    <Additional variant="united" border={border} marginBottom={marginBottom} alignItems="center" bgColor={bgColor}>
      <AdditionalRow mode={mode}>
        <AdditionalCol mode={mode}>
          <AdditionalText mode={mode}>
            <AdditionalTitle>
              {isCredit && 'Loan amount'}
              {isBorrow && 'Amount borrowed'}
            </AdditionalTitle>
            {!isMobile && ':'}
            <AdditionalSubtitle mode={mode}>{`${data.loanAmount} €`}</AdditionalSubtitle>
          </AdditionalText>
          <AdditionalText mode={mode}>
            <AdditionalTitle>
              {isCredit && 'Collateral BTC'}
              {isBorrow && 'BTC collateral added'}
            </AdditionalTitle>
            {!isMobile && ':'}
            <AdditionalSubtitle mode={mode}>{`${data.collateralBTC} BTC`}</AdditionalSubtitle>
          </AdditionalText>
        </AdditionalCol>
        <AdditionalCol mode={mode}>
          {isBorrow && (
            <AdditionalInfoButton mode={mode}>
              <Button
                variant="outlined"
                text={additionalButtonText}
                icon="round"
                width={isMobile && '100%'}
                padding="5px 16px"
              />
            </AdditionalInfoButton>
          )}
        </AdditionalCol>
      </AdditionalRow>
    </Additional>
  )
}

export default AdditionalData
