import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Select from 'ui-kit/components/Select'
import { Row, Col } from 'ui-kit/components/Grid'

import routes from 'constants/routes'
import countries from 'constants/countries'
import { updateProfile } from 'api/profile'
import { useDevice } from 'hooks/media'
import { useLang } from 'hooks/lang'
import { useProfileContext } from 'context/profile'
import { errorCapture } from 'utils/errorCapture'

import SubLayout from 'layouts/SubLayout'
import ContentMobile from 'components/ContentMobile'

import { TitleGrey, TitleBlack } from 'layouts/SubLayout/style'

const SelectCountryCurrency = () => {
  const navigate = useNavigate()
  const isMobile = useDevice()
  const { country } = useProfileContext()
  const { translate: t } = useLang()
  const [isLoading, setLoading] = useState(false)
  const [profileData, setProfileData] = useState({ country: { code: '', label: '' } })
  const hasCountry = country.label.length !== 0
  const isCountrySelected = profileData.country.label.length !== 0

  const handleConfirm = async () => {
    if (!isCountrySelected) return

    setLoading(true)

    await updateProfile(profileData)
      .then(() => {
        setLoading(false)
        navigate(routes.main)
      })
      .catch((error) => {
        errorCapture(error)
      })
  }

  const onSelectCountry = (val) => {
    setProfileData({ country: { code: val.code, label: val.label } })
  }

  return (
    <SubLayout
      isLoading={isLoading}
      confirmDisabled={!isCountrySelected}
      onClickForward={handleConfirm}
      onClickBack={() => navigate(routes.main)}
    >
      <Row justifyContent="center">
        <Col lg={50} sm={100}>
          <ContentMobile>
            {!hasCountry && (
              <>
                {!isMobile && <TitleGrey>{t('SELECT_COUNTRY.TITLE_GREY')}</TitleGrey>}
                <TitleBlack>{t('SELECT_COUNTRY.TITLE_BLACK')}</TitleBlack>
                <Select
                  options={countries}
                  placeholder={t('SELECT_COUNTRY.PLACEHOLDER')}
                  label={t('SELECT_COUNTRY.LABEL')}
                  onChange={onSelectCountry}
                  disabled={isLoading}
                />
                {/* NOTES: hide this blocks for a while */}
                {/* {hasCountry && cryptoLoan && (
                  <Notification variant="yellow">
                    You can only open a stablecoin credit line. We will notify you to this email ritacafe@significa.co
                    when the euro credit line is available.
                  </Notification>
                )} */}
                {/* {isisServiceAvailable === false && (
                  <Notification variant="yellow">Sorry we can't provide service in this country</Notification>
                )} */}
              </>
            )}

            {/* NOTES: hide this block for a while */}
            {/* {hasCountry && !hasCurrency && (
              <>
                <TitleBlack>Select currency</TitleBlack>
                <Select
                  options={currencies}
                  placeholder="Select the stablecoin for your credit line"
                  label="Stablecoin"
                  onChange={onSelectCurrency}
                />
              </>
            )} */}
          </ContentMobile>
        </Col>
      </Row>
    </SubLayout>
  )
}

export default SelectCountryCurrency
