import styled from 'styled-components/macro'
import { colors } from 'constants/colors'
import { device } from 'constants/media'

import cross from './assets/cross-gray.svg'
import order from './assets/order-icon.svg'
import plus from './assets/plus-icon.svg'
import closeIcon from 'assets/icons/cross-black.svg'

export const FilterWrap = styled.div`
  margin-bottom: 45px;
  position: relative;
`

const iconChooser = (icon) => {
  switch (icon) {
    case 'cross':
      return cross
    case 'order':
      return order
    case 'plus':
      return plus

    default:
      return ''
  }
}

export const FilterBlock = styled.div`
  font-weight: 500;
  font-size: 12px;
  text-transform: uppercase;
  cursor: pointer;
  border: 1px ${(p) => p.border} ${colors.gray400};
  border-radius: 4px;
  padding: ${(p) => (p.noText ? '6px 7px' : '6px 12px')};
  margin-bottom: 10px;

  @media ${device.mobile} {
    margin-left: 0;
  }

  &:after {
    content: '';
    width: 16px;
    height: 16px;
    background-image: url(${(p) => iconChooser(p.icon)});
    background-repeat: no-repeat;
    background-position: center;
    display: inline-block;
    vertical-align: middle;
    margin-left: 5px;

    @media ${device.mobile} {
      margin-left: 0;
    }
  }
`

export const FilterDropdown = styled.div`
  position: absolute;
  top: 50px;
  left: 0;
  background: ${colors.white};
  border: 1px solid ${colors.gray50};
  box-shadow: 0 22px 34px -16px rgba(21, 31, 41, 0.05), 0 1px 4px -4px rgba(21, 31, 41, 0.03),
    0 16px 16px -8px rgba(21, 31, 41, 0.01);
  border-radius: 8px;
  padding: 16px 12px;
  width: 360px;
  max-height: 370px;
  overflow: auto;
  z-index: 1;

  @media ${device.mobile} {
    position: fixed;
    top: 0;
    max-height: 100%;
    width: 100%;
    height: 100%;
    z-index: 1;
  }
`

export const FilterDropdownViewport = styled.div`
  @media ${device.mobile} {
    padding: 0 12px;
    margin: auto;
    position: relative;
    max-width: 375px;
  }
`

export const FilterDropdownTitle = styled.div`
  font-weight: 500;
  margin-bottom: 16px;
  padding: 0 14px;

  @media ${device.mobile} {
    text-align: center;
    margin-bottom: 27px;
  }
`

export const FilterCloseIcon = styled.div`
  width: 20px;
  height: 20px;
  background-image: url(${closeIcon});
  position: absolute;
`

export const FilterDropdownSectionTitle = styled.div`
  font-weight: 500;
  margin-bottom: 16px;
  font-size: 12px;
  text-transform: uppercase;
  color: ${colors.gray500};
  margin-bottom: 8px;
  padding: 0 14px;
`

export const FilterDropdownSection = styled.div``

export const FilterDropdownCheckbox = styled.div`
  background: ${(p) => (p.checked ? colors.gray50 : 'transparent')};
  border-radius: 4px;
  padding: 10px 14px;
`

export const FilterBar = styled.div`
  background: ${colors.white};
  padding: 12px;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  margin: 0;
  z-index: 1;
  border: 1px solid ${colors.gray100};
`

export const FilterBarContent = styled.div`
  padding: 0 12px;
  margin: auto;
  position: relative;
  max-width: 375px;
`
