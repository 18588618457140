import Router from './pages/Router'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import { BrowserRouter } from 'react-router-dom'
import Div100vh from 'react-div-100vh'
import { useDevice } from 'hooks/media'
import { colors } from 'constants/colors'
import { ProfileContextProvider } from 'context/profile'
import { LangContextProvider } from 'context/lang'
import { GlobalStyle } from 'globalStyle'

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [new BrowserTracing()],
  maxBreadcrumbs: 50,
  tracesSampleRate: 1.0,
  enabled: process.env.NODE_ENV === 'production',
})

function App() {
  const isMobile = useDevice()

  return (
    <Sentry.ErrorBoundary>
      <LangContextProvider>
        <ProfileContextProvider>
          <GlobalStyle />
          <Div100vh
            style={{ overflowY: 'auto', backgroundColor: `${colors.gray100}`, paddingBottom: isMobile ? '65px' : '0' }}
          >
            <BrowserRouter>
              <Router />
            </BrowserRouter>
          </Div100vh>
        </ProfileContextProvider>
      </LangContextProvider>
    </Sentry.ErrorBoundary>
  )
}

export default App
