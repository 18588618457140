const data = {
  main: '/',
  login: '/login',
  signup: '/signup',
  // onboarding & docs
  privacyPolicy: '/privacy-policy',
  termsOfUse: '/terms-of-use',
  termsOfLoan: '/terms-of-loan',
  country: '/country',
  gameOver: 'service-unavailable',
  calculator: '/calculator',
  // verification
  verification: '/verification',
  identityStep: '/verification/identity',
  addressStep: '/verification/address',
  bankStep: '/verification/bank',
  twoFactor: '/verification/2fa',
  // credit line
  credit: '/credit',
  borrow: '/borrow',
  deposit: '/deposit',
  creditConfirmation: '/credit-confirmation',
  transactionInfo: '/transaction-info',
  // profile page
  profile: '/profile',
  accountIdentity: '/profile/account-identity',
  accountAddress: '/profile/account-address',
  accountBank: '/profile/account-bank',
  accountTwoFactor: '/profile/account-2fa',
  accountTwoFactorCodes: '/profile/account-2fa/codes',
  accountTwoFactorApp: '/profile/account-2fa/app',
  //history page
  history: '/history',
}
export default data
