import styled from 'styled-components/macro'
import { colors } from 'constants/colors'
import { device } from 'constants/media'

export const CalcEurInput = styled.input`
  font-family: 'Roboto Mono', monospace;
  font-weight: 500;
  font-size: 40px;
  width: 100%;
  border: none;
  margin-bottom: 12px;

  &::placeholder {
    color: ${colors.gray200};
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  @media ${device.mobile} {
    text-align: center;
  }
`

export const HighlightCalcEurInput = styled.input`
  font-family: 'Roboto Mono', monospace;
  font-weight: 500;
  font-size: 40px;
  color: ${colors.red500};
  width: 100%;
  border: none;
  margin-bottom: 12px;

  &::placeholder {
    color: ${colors.gray200};
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  @media ${device.mobile} {
    text-align: center;
  }
`
