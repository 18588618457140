import React from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'

import routes from 'constants/routes'
import { useProfileContext } from 'context/profile'

import { auth } from 'configs/firebase'
import { isSignInWithEmailLink } from 'firebase/auth'

import AuthPage from './AuthPage'
import PrivacyPolicy from './AuthPage/components/PolicyAndTerms/documents/PrivacyPolicy'
import TermsOfUse from './AuthPage/components/PolicyAndTerms/documents/TermsOfUse'
import PolicyAndTerms from './AuthPage/components/PolicyAndTerms'
import AuthConfirm from './AuthPage/components/AuthConfirm/AuthConfirm'
import Dashboard from './Dashboard'
import CreditLine from './Loan/CreditLine'
import Deposit from './Loan/Deposit'
import Borrow from './Loan/Borrow'
import Profile from './Profile'
import Identity from './Profile/Identity'
import Address from './Profile/Address'
import Bank from './Profile/Bank'
import AccountTwoFactor from './Profile/TwoFactor'
import TwoFaAuthApp from './Profile/TwoFactor/AuthApp'
import BackupCodes from './Profile/TwoFactor/BackupCodes'
import SelectCountryCurrency from './SelectCountryCurrency'
import VerificationProcess from './VerificationProcess'
import IdentityVerification from './VerificationProcess/IdentityVerification'
import AddressVerification from './VerificationProcess/AddressVerification'
import TwoFactor from './VerificationProcess/TwoFactor'
import BankVerification from './VerificationProcess/BankVerification'
import History from './History/History'
import CreditConfirmation from './CreditConfirmation'
import TransactionInfo from './TransactionInfo'
import ServiceUnavailablePage from './ServiceUnavailablePage'
import OnboardingCalculator from './OnboardingCalculator'

import { useVerification } from 'hooks/verification'
import TermsOfLoan from './AuthPage/components/PolicyAndTerms/documents/TermsOfLoan'

const Router = () => {
  const { isLoggedIn, country, isTermsAccepted, isServiceAvailable } = useProfileContext()
  const { isVerificationComplete } = useVerification()

  const isCountrySelect = country !== undefined && !!country?.code

  // TODO: create splash screen or spinner
  if (isLoggedIn === null) return null

  if (!isLoggedIn) return <Anonymous />
  if (isTermsAccepted !== undefined && !isTermsAccepted) return <TermsAcceptance />
  if (isTermsAccepted && country !== undefined && !country?.code) return <SelectCountry />
  if (isServiceAvailable !== undefined && !isServiceAvailable) return <GameOver />

  return <Authenticated isCountrySelect={isCountrySelect} isVerificationComplete={isVerificationComplete || null} />
}

const Anonymous = () => {
  if (isSignInWithEmailLink(auth, window.location.href)) {
    return (
      <Routes>
        <Route path="*" element={<AuthConfirm />} />
      </Routes>
    )
  }

  return (
    <Routes>
      <Route path={routes.privacyPolicy} element={<PrivacyPolicy />} />
      <Route path={routes.termsOfUse} element={<TermsOfUse />} />
      <Route path={routes.termsOfLoan} element={<TermsOfLoan />} />
      <Route path={routes.login} element={<AuthPage type="login" />} />
      <Route path={routes.signup} element={<AuthPage type="signup" />} />
      <Route path="*" element={<AuthPage type="login" />} />
    </Routes>
  )
}

const TermsAcceptance = () => {
  return (
    <Routes>
      <Route path="*" element={<PolicyAndTerms />} />
      <Route path={routes.privacyPolicy} element={<PrivacyPolicy />} />
      <Route path={routes.termsOfUse} element={<TermsOfUse />} />
    </Routes>
  )
}

const SelectCountry = () => {
  return (
    <Routes>
      <Route path="*" element={<Navigate to={routes.country} />} />
      <Route path={routes.country} element={<SelectCountryCurrency />} />
    </Routes>
  )
}

const GameOver = () => {
  return (
    <Routes>
      <Route path="*" element={<Navigate to={routes.gameOver} />} />
      <Route path={routes.gameOver} element={<ServiceUnavailablePage />} />
    </Routes>
  )
}

const Authenticated = ({ isCountrySelect, isVerificationComplete }) => {
  return (
    <Routes>
      {/* MAIN & LEGAL PAGES */}
      <Route path={routes.login} element={<Navigate to="/" />} />
      <Route path={routes.signup} element={<Navigate to="/" />} />
      {isCountrySelect && <Route path={routes.country} element={<Navigate to="/" />} />}
      <Route path={routes.privacyPolicy} element={<PrivacyPolicy />} />
      <Route path={routes.termsOfUse} element={<TermsOfUse />} />
      <Route path={routes.termsOfLoan} element={<TermsOfLoan />} />
      <Route path={routes.main} element={<Dashboard />} />
      {/* ONBOARDING CALC */}
      <Route path={routes.calculator} element={<OnboardingCalculator />} />
      {/* VERIFICATION */}
      <Route path={routes.verification} element={<VerificationProcess />} />
      <Route path={routes.identityStep} element={<IdentityVerification />} />
      <Route path={routes.addressStep} element={<AddressVerification />} />
      <Route path={routes.bankStep} element={<BankVerification />} />
      <Route path={routes.twoFactor} element={<TwoFactor />} />
      {/* BORROW */}
      {isVerificationComplete && <Route path={routes.credit} element={<CreditLine />} />}
      {isVerificationComplete && <Route path={routes.borrow} element={<Borrow />} />}
      {isVerificationComplete && <Route path={routes.deposit} element={<Deposit />} />}
      {isVerificationComplete && <Route path={routes.creditConfirmation} element={<CreditConfirmation />} />}
      {isVerificationComplete && <Route path={routes.transactionInfo} element={<TransactionInfo />} />}
      {/* PROFILE PAGE */}
      <Route path={routes.profile}>
        <Route index element={<Profile />} />
        <Route path={routes.accountIdentity} element={<Identity />} />
        <Route path={routes.accountAddress} element={<Address />} />
        <Route path={routes.accountBank} element={<Bank />} />
        <Route path={routes.accountTwoFactor}>
          <Route index element={<AccountTwoFactor />} />
          <Route path={routes.accountTwoFactorCodes} element={<BackupCodes />} />
          <Route path={routes.accountTwoFactorApp} element={<TwoFaAuthApp />} />
        </Route>
      </Route>
      {/* HISTORY PAGE */}
      <Route path={routes.history} element={<History />} />
    </Routes>
  )
}

export default Router
