const eventNameKeys = {
  addressVerification: 'ADDRESS_VERIFICATION',
  identityVerification: 'IDENTITY_VERIFICATION',
  bankVerification: 'BANK_VERIFICATION',
  twofaAppVerification: 'TFA_APP_VERIFICATION',
  twofaCodesVerification: 'TFA_CODES_VERIFICATION',
  resetTwofa: 'TFA_RESET_CODES',
  credit: 'CREDIT',
}

export const getEventNameByKey = (eventType) => {
  return eventNameKeys[eventType]
}
