import styled from 'styled-components/macro'
import { ContentTemplate } from 'pages/commonStyle'
import { colors } from 'constants/colors'

import arrowIcon from 'assets/icons/arrow-left-black.svg'

export const NavigationWrapper = styled.div`
  border-top: 1px solid ${colors.gray150};
  padding: 12px 0;
  background-color: ${colors.white};
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 1;
`

export const NavigationContent = styled(ContentTemplate)`
  display: flex;
  justify-content: space-between;
`

export const NavigationBack = styled.div`
  width: 40px;
  height: 40px;
  border: 1px solid ${colors.gray300};
  border-radius: 4px;
  background-image: url(${arrowIcon});
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
`

export const NavigationDots = styled.div`
  display: flex;
  align-items: center;
`

export const NavigationDotItem = styled.div`
  width: ${(p) => (p.active ? '12px' : '8px')};
  height: ${(p) => (p.active ? '12px' : '8px')};
  border-radius: 50%;
  background-color: ${(p) => (p.active ? colors.gray950 : colors.gray200)};
  margin: 0 4px;
`
