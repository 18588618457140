import { auth } from 'configs/firebase'
import { sendSignInLinkToEmail } from 'firebase/auth'
import { errorCapture } from 'utils/errorCapture'

import statuses from '../constants'

/**
 * @param {string} sendingEmail
 * @returns {Promise<string>} `status`
 */
export const sendMagicLink = async (sendingEmail) => {
  const emailStorageName = 'emailForSignIn'
  let status

  try {
    await sendSignInLinkToEmail(auth, sendingEmail, {
      url: window.location.href,
      handleCodeInApp: true, // This must be true.
    })
    window.localStorage.setItem(emailStorageName, sendingEmail)
    status = statuses.linkSent
  } catch (error) {
    errorCapture(error)
    status = statuses.error
  }

  return status
}
