import React from 'react'
import { useNavigate } from 'react-router-dom'

import bn from 'bignumber.js'

import { useProfileContext } from 'context/profile'
import Banner from './Banner'

import routes from 'constants/routes'

const Confirmations = () => {
  const { balances, unconfirmedTx, contractDraft } = useProfileContext()
  const navigate = useNavigate()

  const currentConfirmations = process.env.REACT_APP_BTC_CURRENT_CONFIRMATIONS || '0'
  const requiredConfirmations = process.env.REACT_APP_BTC_CONFIRMATIONS_REQUIRED || '10'

  if (unconfirmedTx?.txHash) {
    // Show unconfirmed transaction on the blockchain as a priority
    return (
      <Banner
        currentConfirmation={unconfirmedTx?.confirmations}
        requiredConfirmations={requiredConfirmations}
        onClick={() => navigate(routes.transactionInfo)}
      />
    )
  } else if (bn(balances?.btcPending).gt(0)) {
    // Transaction confirmed on blockchain an waiting AML review
    return <Banner btcPending={Number(balances?.btcPending)} />
  } else if (contractDraft?.collateralStatus === 'waiting') {
    // User confirmed BTC transfer, but we do not see it on the blockchain yet
    return <Banner currentConfirmation={currentConfirmations} requiredConfirmations={requiredConfirmations} />
  }
}

export default Confirmations
