import React, { useState } from 'react'
import { Link } from 'react-router-dom'

import { useLang } from 'hooks/lang'

import { sendMagicLink } from './utils/sendMagicLink'

import statuses from './constants'
import AuthInfo from './components/AuthInfo'
import FormBlock from './components/FormBlock'

import AuthPageLayout from 'layouts/AuthPageLayout'
import Notification from 'ui-kit/components/Notification'

import routes from 'constants/routes'

const AuthPage = ({ type }) => {
  const { translate: t } = useLang()
  const [status, setStatus] = useState('')
  const pageType = type.toUpperCase()

  const onSubmitForm = async (formEmail) => {
    const currentStatus = await sendMagicLink(formEmail)
    setStatus(currentStatus)
  }

  switch (status) {
    case statuses.error:
      return (
        <AuthPageLayout bgImageColor="red" title={status}>
          <Notification variant="red">
            {'Something went wrong, please '}
            <Link to={routes.login} onClick={() => setStatus('')}>
              {t('AUTHORIZATION.INFO_PAGE.UNDER_NOTIFICATION.LINK')}
            </Link>
          </Notification>
        </AuthPageLayout>
      )

    case statuses.linkSent:
      return <AuthInfo status={status} />

    default:
      return (
        <FormBlock
          formLabel={t(`AUTHORIZATION.FORM.${pageType}.TITLE`)}
          buttonText={t(`AUTHORIZATION.FORM.${pageType}.BUTTON`)}
          buttonSubtext={t(`AUTHORIZATION.FORM.${pageType}.UNDER_BUTTON`)}
          linkText={t(`AUTHORIZATION.FORM.${pageType}.LINK_TEXT`)}
          link={t(`AUTHORIZATION.FORM.${pageType}.LINK`)}
          onSubmit={onSubmitForm}
        />
      )
  }
}

export default AuthPage
