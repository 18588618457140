const data = [
  {
    code: 'AD',
    value: 'Andorra',
    label: 'Andorra',
  },
  {
    code: 'AP',
    value: 'Austria',
    label: 'Austria',
  },
  {
    code: 'BE',
    value: 'Belgium',
    label: 'Belgium',
  },
  {
    code: 'BG',
    value: 'Bulgaria',
    label: 'Bulgaria',
  },
  {
    code: 'HR',
    value: 'Croatia',
    label: 'Croatia',
  },
  {
    code: 'CY',
    value: 'Cyprus',
    label: 'Cyprus',
  },
  {
    code: 'CZ',
    value: 'Czech Republic',
    label: 'Czech Republic',
  },
  {
    code: 'DK',
    value: 'Denmark',
    label: 'Denmark',
  },
  {
    code: 'EE',
    value: 'Estonia',
    label: 'Estonia',
  },
  {
    code: 'FI',
    value: 'Finland',
    label: 'Finland',
  },
  {
    code: 'FR',
    value: 'France',
    label: 'France',
  },
  {
    code: 'DE',
    value: 'Germany',
    label: 'Germany',
  },
  {
    code: 'GR',
    value: 'Greece',
    label: 'Greece',
  },
  {
    code: 'HU',
    value: 'Hungary',
    label: 'Hungary',
  },
  {
    code: 'IS',
    value: 'Iceland',
    label: 'Iceland',
  },
  {
    code: 'IE',
    value: 'Ireland',
    label: 'Ireland',
  },
  {
    code: 'IT',
    value: 'Italy',
    label: 'Italy',
  },
  {
    code: 'LV',
    value: 'Latvia',
    label: 'Latvia',
  },
  {
    code: 'LI',
    value: 'Liechtenstein',
    label: 'Liechtenstein',
  },
  {
    code: 'LT',
    value: 'Lithuania',
    label: 'Lithuania',
  },
  {
    code: 'LU',
    value: 'Luxembourg',
    label: 'Luxembourg',
  },
  {
    code: 'MT',
    value: 'Malta',
    label: 'Malta',
  },
  {
    code: 'MC',
    value: 'Monaco',
    label: 'Monaco',
  },
  {
    code: 'NL',
    value: 'Netherlands',
    label: 'Netherlands',
  },
  {
    code: 'NO',
    value: 'Norway',
    label: 'Norway',
  },
  {
    code: 'PL',
    value: 'Poland',
    label: 'Poland',
  },
  {
    code: 'PT',
    value: 'Portugal',
    label: 'Portugal',
  },
  {
    code: 'RO',
    value: 'Romania',
    label: 'Romania',
  },
  {
    code: 'SM',
    value: 'San Marino',
    label: 'San Marino',
  },
  {
    code: 'SK',
    value: 'Slovakia',
    label: 'Slovakia',
  },
  {
    code: 'SI',
    value: 'Slovenia',
    label: 'Slovenia',
  },
  {
    code: 'ES',
    value: 'Spain',
    label: 'Spain',
  },
  {
    code: 'SE',
    value: 'Sweden',
    label: 'Sweden',
  },
  {
    code: 'CH',
    value: 'Switzerland',
    label: 'Switzerland',
  },
  {
    code: 'GB',
    value: 'United Kingdom',
    label: 'United Kingdom',
  },
  {
    code: 'VA',
    value: 'Vatican City',
    label: 'Vatican City',
  },
]
export default data
