import React, { useState } from 'react'
import { Link } from 'react-router-dom'

import { useLang } from 'hooks/lang'

import AuthPageLayout from 'layouts/AuthPageLayout'
import Notification from 'ui-kit/components/Notification'
import Button from 'ui-kit/components/Button'
import Input from 'ui-kit/components/Input'

import routes from 'constants/routes'

import { FormSubtext, FormButtonWrap, FormButtonSubtext, FormInput, FormBottom } from './style'

const emailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

const FormBlock = ({ formLabel, buttonText, buttonSubtext, linkText, link, onSubmit, isResubmitEmail, errorText }) => {
  const [email, setEmail] = useState('')
  const { translate: t } = useLang()
  const isValid = emailRegex.test(email)

  const submitOnEnter = (event) => {
    if (event.key === 'Enter' && isValid) {
      onSubmit(email)
    }
  }

  const submitOnClick = () => {
    onSubmit(email)
  }

  const onEmailChange = (event) => {
    setEmail(event.target.value)
  }

  return (
    <AuthPageLayout bgImageColor={isResubmitEmail ? 'yellow' : 'blue'} title={formLabel}>
      {isResubmitEmail && <Notification variant="yellow">{errorText}</Notification>}
      <div>
        <FormInput>
          <Input
            label={t('AUTHORIZATION.FORM.EMAIL.LABEL')}
            type="email"
            placeholder={t('AUTHORIZATION.FORM.EMAIL.PLACEHOLDER')}
            onChange={onEmailChange}
            onKeyDown={submitOnEnter}
            required
          />
        </FormInput>
        {!isResubmitEmail && (
          <FormSubtext>
            {t('AUTHORIZATION.FORM.POLICY.TEXT_1')}
            <Link to={routes.privacyPolicy}>{t('AUTHORIZATION.FORM.POLICY.PRIVACY')}</Link>
            {t('AUTHORIZATION.FORM.POLICY.TEXT_2')}
            <Link to={routes.termsOfUse}>{t('AUTHORIZATION.FORM.POLICY.TERMS')}</Link>
          </FormSubtext>
        )}
      </div>
      <FormBottom>
        <FormButtonWrap>
          <Button
            type="submit"
            variant="filled"
            disabled={!isValid}
            onClick={submitOnClick}
            text={buttonText}
            width="100%"
          />
        </FormButtonWrap>
        {!isResubmitEmail && (
          <FormButtonSubtext>
            {buttonSubtext}
            <Link to={link}>{linkText}</Link>
          </FormButtonSubtext>
        )}
      </FormBottom>
    </AuthPageLayout>
  )
}

export default FormBlock
