import { colors } from 'constants/colors'
import styled from 'styled-components/macro'

import infoIconGray from './assets/info-icon-gray.svg'
import infoIconTransparent from './assets/info-icon-transparent.svg'

export const InfoIcon = styled.div`
  width: ${(p) => (p.isTransparent ? '20px' : '16px')};
  height: ${(p) => (p.isTransparent ? '20px' : '16px')};
  background-image: url(${(p) => (p.isTransparent ? infoIconTransparent : infoIconGray)});
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover;
  cursor: pointer;
`

export const TooltipContainer = styled.div`
  background-color: ${colors.gray900};
  border-radius: 4px;
  font-size: 12px;
  color: ${colors.gray100};
  padding: 12px;
  z-index: 9999;
  min-width: auto;
  max-width: 400px;
  border: none;

  &[data-popper-placement*='bottom'] {
    .tooltip-arrow:after {
      border-color: transparent transparent ${colors.gray900} transparent;
    }
    .tooltip-arrow:before {
      border: none;
    }
  }
`
