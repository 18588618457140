import styled from 'styled-components/macro'
import { colors } from 'constants/colors'
import { device } from 'constants/media'

export const MinCollateral = styled.div`
  font-weight: 500;
  font-size: 10px;
  color: ${(p) => (p.error ? colors.red500 : colors.gray500)};
  text-transform: uppercase;
  margin: 8px 0 32px;
  cursor: pointer;

  @media ${device.mobile} {
    margin-bottom: 0;
  }
`
export const CalcResultTitle = styled.div`
  font-weight: 500;
  font-size: 16px;
  margin: 16px 0;
`
