import React, { useEffect, useState } from 'react'
import _ from 'lodash'

import SelectBank from 'ui-kit/components/SelectBank'

import { useProfileContext } from 'context/profile'
import { getUserBankAccount } from 'api/bankData'
import { useDevice } from 'hooks/media'
import { useLang } from 'hooks/lang'

import ContentMobile from 'components/ContentMobile'

import { TitleGrey, TitleBlack, MarginWrap } from 'layouts/SubLayout/style'
import { errorCapture } from 'utils/errorCapture'
import { confirmBankAccount } from 'api/credit'

import PositionSummary from '../PositionSummary'
import { CalcResultTitle } from 'pages/Loan/style'
import { Col, Row } from 'ui-kit/components/Grid'
import SubLayout from 'layouts/SubLayout'

const BankStep = ({ dots, onClickBack, onConfirm, borrowMore, depositCollateral }) => {
  const isMobile = useDevice()
  const { translate: t } = useLang()
  const { uid } = useProfileContext()

  const [isLoading, setLoading] = useState(true)
  const [checked, setChecked] = useState(null)
  const [bankAccounts, setBankAccounts] = useState([])

  useEffect(() => {
    if (uid) {
      getUserBankAccount(uid)
        .then((data) => {
          setBankAccounts(data)
          setLoading(false)
        })
        .catch((error) => errorCapture(error))
    }
  }, [uid])

  const onConfirmSelection = () => {
    setLoading(true)
    confirmBankAccount(bankAccounts[checked])
      .then(() => {
        onConfirm()
      })
      .catch((error) => errorCapture(error))
  }

  const selectAccountHandler = (index) => {
    setChecked(index)
  }

  const getGreyTitle = () => {
    if (borrowMore) {
      return t('LOAN.BORROW.TITLE_BLACK')
    } else if (depositCollateral) {
      return t('LOAN.DEPOSIT.TITLE_BLACK')
    } else {
      return t('CREDIT_LINE.BANK_STEP.TITLE_GREY')
    }
  }

  return (
    <SubLayout
      onClickBack={onClickBack}
      dots={dots}
      // Local
      isLoading={isLoading}
      confirmDisabled={_.isNil(checked)}
      infoText={t(`CREDIT_LINE.BANK_STEP.TITLE_BLACK_HINT.OPEN_CREDIT`)}
      forwardText={t(`CREDIT_LINE.BUTTON.CONFIRM`)}
      onClickForward={onConfirmSelection}
    >
      <Row justifyContent="center">
        <Col lg={50} sm={100}>
          <>{!isMobile && <TitleGrey>{getGreyTitle()}</TitleGrey>}</>
          <ContentMobile>
            <TitleBlack>{t('CREDIT_LINE.BANK_STEP.TITLE_BLACK')}</TitleBlack>
            {bankAccounts.length > 0 &&
              bankAccounts.map((account, index) => (
                <MarginWrap margin="0 0 8px" key={account.iban} onClick={() => selectAccountHandler(index)}>
                  <SelectBank checked={checked === index} title={account.name} text={`IBAN ${account.iban}`} />
                </MarginWrap>
              ))}
          </ContentMobile>
        </Col>
        {!isMobile && (
          <Col lg={50}>
            <CalcResultTitle>{t('CREDIT_LINE.SUMMARY.TITLE')}</CalcResultTitle>
            <PositionSummary borrowSummary={borrowMore} />
          </Col>
        )}
      </Row>
    </SubLayout>
  )
}

export default BankStep
