import React from 'react'
import SelectReact, { components } from 'react-select'

import { colors } from 'constants/colors'
import Checkbox from '../Checkbox'
import { SelectWrap, SelectLabel } from './style'

const colourStyles = {
  menu: (styles) => ({
    ...styles,
    borderRadius: '8px',
    padding: '8px',
    boxShadow: '0 4px 11px rgb(0 0 0 / 10%)',
    zIndex: 2,
  }),
  control: (styles, { isDisabled, isFocused }) => ({
    ...styles,
    backgroundColor: isDisabled ? colors.gray150 : colors.gray50,
    borderColor: isFocused ? colors.gray300 : colors.gray150,
    boxShadow: 'none',
    '&:hover': {
      borderColor: colors.gray300,
    },
    paddingTop: '14px',
    borderRadius: '8px',
  }),
  option: (styles, { isDisabled, isFocused, isSelected }) => ({
    ...styles,
    backgroundColor: isSelected ? colors.gray150 : isFocused ? colors.gray100 : undefined,
    color: isDisabled ? colors.gray300 : isSelected,
    cursor: isDisabled ? 'not-allowed' : 'default',
    borderRadius: '4px',

    ':active': {
      ...styles[':active'],
      backgroundColor: !isDisabled ? isSelected : undefined,
    },
  }),
  input: (styles) => ({ ...styles }),
  placeholder: (styles) => ({ ...styles, color: colors.gray400 }),
  singleValue: (styles) => ({ ...styles }),
}

const Select = ({ options, placeholder, label, onChange, disabled, isMulti, value }) => {
  return (
    <SelectWrap>
      <SelectLabel>{label}</SelectLabel>
      <SelectReact
        closeMenuOnSelect={!isMulti}
        hideSelectedOptions={!isMulti}
        isMulti={isMulti}
        options={options}
        placeholder={placeholder}
        styles={colourStyles}
        onChange={(value, action) => onChange(value, action)}
        isDisabled={disabled}
        defaultValue={value}
        components={{
          IndicatorSeparator: () => null,
          Option: (props) => {
            return (
              <components.Option {...props}>
                {isMulti ? <Checkbox checked={props.isSelected} label={props.label} /> : props.label}
              </components.Option>
            )
          },
        }}
      />
    </SelectWrap>
  )
}

export default Select
