import React from 'react'
import Button from 'ui-kit/components/Button'
import { Row, Col } from 'ui-kit/components/Grid'

import { useLang } from 'hooks/lang'
import { useDevice } from 'hooks/media'
import { colors } from 'constants/colors'

import {
  CreditGridWrap,
  CreditGridBlock,
  CreditGridTitle,
  CreditGridUnderTitle,
  CreditGridAboveBtn,
  CreditGridBox,
  CreditImage,
} from '../../CreditGrid/style'
import Tag from 'ui-kit/components/Tag'

const MinLoanInfo = ({ contractDraft, balances }) => {
  const { translate: t } = useLang()
  const isMobile = useDevice()

  return (
    <CreditGridWrap data-testid="loan-info">
      <Row m={-12}>
        <Col lg={66.67} sm={isMobile ? 50 : 100} p={12} pSm={6} order={1}>
          <CreditGridBlock height={isMobile ? 142 : 180}>
            <CreditImage />
            <CreditGridBox>
              <CreditGridBox justifyContent="flex-start">
                <CreditGridTitle>{t('DASHBOARD.CREDIT_GRID.BORROWED')}</CreditGridTitle>
                <CreditGridBox
                  flexDirection={isMobile ? 'column' : 'row'}
                  justifyContent="flex-start"
                  alignItem={isMobile ? 'normal' : 'center'}
                  height="fit-content"
                >
                  <CreditGridUnderTitle>{contractDraft?.loanAmount}€</CreditGridUnderTitle>
                  <Tag text="pending" textColor="yellow" fontSize={isMobile ? '8px' : '12px'} bgColor="yellow" />
                </CreditGridBox>
              </CreditGridBox>

              <Button
                variant="filled"
                width={isMobile ? '100%' : 'fit-content'}
                textColor={colors.gray950}
                bgColor={colors.gray150}
                text={t('DASHBOARD.CREDIT_GRID.BUTTONS.BORROW_MORE')}
                fontSize={!isMobile ? '16px' : undefined}
                padding={isMobile && '7px 16px'}
                disabled={true}
                onClick={() => {}}
              />
            </CreditGridBox>
          </CreditGridBlock>
        </Col>
        <Col lg={33.33} sm={isMobile ? 50 : 100} p={12} pSm={6} order={2}>
          <CreditGridBlock height={isMobile ? 142 : 180}>
            <CreditGridBox>
              <div>
                <CreditGridTitle>{t('DASHBOARD.CREDIT_GRID.COLLATERAL')}</CreditGridTitle>
                <CreditGridUnderTitle>{Number(balances?.btcVerified)} BTC</CreditGridUnderTitle>
                <CreditGridAboveBtn size="12px" marginBottom={isMobile ? '4px' : '8px'}>
                  1 BTC ~ {contractDraft?.btcRate}€
                </CreditGridAboveBtn>
              </div>

              <div>
                <Button
                  variant="filled"
                  width="100%"
                  textColor={colors.gray950}
                  bgColor={colors.gray150}
                  text={t('DASHBOARD.CREDIT_GRID.BUTTONS.WITHDRAW')}
                  fontSize={!isMobile ? '16px' : undefined}
                  padding={isMobile && '7px 16px'}
                  disabled={true}
                  onClick={() => {}}
                />
              </div>
            </CreditGridBox>
          </CreditGridBlock>
        </Col>
      </Row>
    </CreditGridWrap>
  )
}

export default MinLoanInfo
