import styled from 'styled-components/macro'

import { colors } from 'constants/colors'

export const BackupCodesText = styled.div`
  color: ${colors.gray600};
`

export const BackupCodesWrapper = styled.div`
  width: 100%;
  margin: 20px 0;
  display: grid;
  column-gap: 22px;
  grid-template-columns: auto auto auto auto auto;
`

export const BackupCode = styled.div`
  display: flex;
  justify-content: center;
  margin: 6px 0;
  color: ${colors.gray950};
`

export const BackupCodesButtonWrapper = styled.div`
  width: 221px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`
export const CheckboxWrapper = styled.div`
  margin: 8px 0;
  padding: 8px 0;
`

export const QRCodeWrapper = styled.div`
  margin: 8px 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

export const QRCodeTitle = styled.div`
  margin-top: 12px;
  color: ${colors.gray800};
`

export const QRCodeImg = styled.img`
  margin-left: -13px;
  width: 168px;
  height: 168px;
`
