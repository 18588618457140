import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { useDevice } from 'hooks/media'
import routes from 'constants/routes'

import DepositCalc from '../components/DepositCalc'
import MobileResultsStep from '../components/MobileResultsStep'
import TransferStep from '../components/TransferStep'
import DepositConfirmPage from '../components/DepositConfirmPage'

const Deposit = () => {
  const isMobile = useDevice()
  const navigate = useNavigate()

  const [dots, setDots] = useState([])

  const [activeStep, setActiveStep] = useState(0)

  useEffect(() => {
    // NOTE: If step has already confirmed so that
    // jump to next step which didn't confirm yet
    if (!steps[activeStep].isActive && activeStep < steps.length - 1) setActiveStep(activeStep + 1)
  }, [activeStep])

  const onClickBack = () => {
    switch (activeStep) {
      case 0:
        navigate(routes.main)
        break

      case 2:
        isMobile ? setActiveStep(1) : setActiveStep(0)
        break

      default:
        setActiveStep(activeStep - 1)
    }
  }

  const resultScreenMobile = {
    component: <MobileResultsStep dots={dots} onClickBack={onClickBack} onConfirm={() => setActiveStep(2)} />,
    isActive: activeStep === 1,
  }

  const steps = [
    {
      component: (
        <DepositCalc dots={dots} onClickBack={onClickBack} onConfirm={() => setActiveStep(isMobile ? 1 : 2)} />
      ),
      isActive: activeStep === 0,
    },
    ...(isMobile ? [resultScreenMobile] : [{}]),
    {
      component: (
        <TransferStep dots={dots} onClickBack={onClickBack} onConfirm={() => setActiveStep(3)} depositCollateral />
      ),
      isActive: activeStep === 2,
    },
    {
      component: <DepositConfirmPage />,
      isActive: activeStep === 3,
    },
  ]

  useEffect(() => {
    setDots(steps.map((step) => step.isActive))
  }, [activeStep])

  return steps[activeStep].isActive && steps[activeStep].component
}

export default Deposit
