import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import QRCode from 'qrcode'

import { useLang } from 'hooks/lang'
import { useVerification } from 'hooks/verification'
import { useProfileContext } from 'context/profile'
import { errorCapture } from 'utils/errorCapture'
import { initTwoFASecret, initBackupCodes, setConfirmDownloadCodes } from 'api/twofa'

import SubLayout from 'layouts/SubLayout'

import routes from 'constants/routes'
import statuses from 'constants/statuses'
import BackupCodesStep from './components/BackupCodesStep'
import VerificationCodeStep from './components/VerificationCodeStep'

const initSecretState = { secret: null, url: null }

const TwoFactor = () => {
  const navigate = useNavigate()
  const { uid, verification, backupCodesSavedAt } = useProfileContext()
  const { isVerificationDenied } = useVerification()
  const { translate: t } = useLang()

  const [isLoading, setLoading] = useState(false)
  const [secretData, setSecretData] = useState(initSecretState)
  const [backupCodes, setBackupCodes] = useState([])
  const [qrCodeUrl, setQRCodeUrl] = useState(null)
  const [activeStep, setActiveStep] = useState(!backupCodesSavedAt ? 0 : 1)
  const [isDownloadConfirmed, setDownloadConfirmed] = useState(false)

  const isAccessAllowed = [statuses.notStarted.code, statuses.failed.code].includes(verification.twofa.code)

  useEffect(() => {
    if (!isAccessAllowed || isVerificationDenied) navigate(routes.main)
    async function fetchBackupCodes() {
      if (activeStep === 0) {
        setLoading(true)

        await initBackupCodes()
          .then((resp) => {
            if (resp.data) setBackupCodes(resp.data.codes)
          })
          .catch((error) => {
            errorCapture(error)
          })

        setLoading(false)
      }
    }
    fetchBackupCodes()
  }, [])

  useEffect(() => {
    async function fetchTwoFactorSecret() {
      if (activeStep === 1) {
        setLoading(true)

        await initTwoFASecret()
          .then((resp) => {
            if (resp.data) setSecretData({ secret: resp.data.secret, url: resp.data.url })
          })
          .catch((error) => {
            errorCapture(error)
          })

        setLoading(false)
      }
    }
    fetchTwoFactorSecret()
  }, [activeStep])

  useEffect(() => {
    if (secretData.url) {
      QRCode.toDataURL(secretData.url)
        .then((url) => {
          setQRCodeUrl(url)
        })
        .catch((error) => {
          errorCapture(error)
        })
    }
  }, [secretData])

  const handleConfirm = () => {
    if (activeStep === 0) {
      setConfirmDownloadCodes({ uid })
        .then(() => {
          setActiveStep(activeStep + 1)
        })
        .catch((error) => {
          errorCapture(error)
        })
    }
  }

  const onSetSecretData = () => {
    setSecretData(initSecretState)
    navigate(routes.verification)
  }

  const onClickBack = () => {
    if (activeStep === 0) navigate(routes.main)
    if (activeStep > 0) {
      backupCodesSavedAt ? navigate(routes.main) : setActiveStep(activeStep - 1)
    }
  }

  const steps = [
    {
      component: (
        <BackupCodesStep
          backupCodes={backupCodes}
          handelDownloadConfirm={() => setDownloadConfirmed(!isDownloadConfirmed)}
          isConfirmed={isDownloadConfirmed}
          checkboxIsVisible
        />
      ),
      isActive: activeStep === 0,
    },
    {
      component: (
        <VerificationCodeStep
          secretData={secretData}
          qrCodeUrl={qrCodeUrl}
          onSetSecretData={onSetSecretData}
          onSetPageLoading={setLoading}
        />
      ),
      isActive: activeStep === 1,
    },
  ]

  const isCodesSaved = isDownloadConfirmed && backupCodes.length

  return (
    <SubLayout
      onClickForward={handleConfirm}
      confirmDisabled={!isCodesSaved}
      onClickBack={onClickBack}
      forwardText={activeStep === 0 ? t('NAVIGATION.BUTTON.NEXT') : t('NAVIGATION.BUTTON.DEFAULT')}
      dots={steps.map((step) => step.isActive)}
      isLoading={isLoading}
    >
      {steps[activeStep].isActive && steps[activeStep].component}
    </SubLayout>
  )
}

export default TwoFactor
