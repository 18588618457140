import styled from 'styled-components/macro'

import purpleBg from 'assets/images/auth-img-purple.png'
import btnBack from 'assets/icons/arrow-left-black.svg'
import saveAsPdf from 'assets/icons/download.svg'
import { device } from 'constants/media'

export const PolicyPage = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;

  @media ${device.mobile} {
    flex-direction: column;
  }
`

export const PolicyPageImg = styled.div`
  width: 50%;
  background-image: url(${purpleBg});
  background-repeat: no-repeat;
  background-size: cover;
  background-position-x: right;

  @media print {
    display: none;
  }

  @media ${device.mobile} {
    display: none;
  }
`

export const PolicyPageInnerWrapper = styled.div`
  width: 50%;
  height: 100vh;
  padding: 32px 32px 0 32px;
  display: flex;
  flex-direction: column;

  @media print {
    width: 100%;
  }

  @media ${device.mobile} {
    width: 100%;
    padding: 16px;
    height: initial;
  }
`

export const PolicyPageHeader = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
`

export const ButtonBack = styled.div`
  width: 20px;
  height: 20px;
  background-image: url(${btnBack});
  background-size: cover;
  background-repeat: no-repeat;
  cursor: pointer;
  margin-bottom: 20px;
  margin-left: -3px;

  @media print {
    display: none;
  }
`

export const SavePdfButton = styled.div`
  width: 34px;
  height: 34px;
  background-image: url(${saveAsPdf});
  background-size: cover;
  background-repeat: no-repeat;
  cursor: pointer;

  @media print {
    display: none;
  }
`

export const PolicyDocument = styled.div`
  overflow-y: scroll;
`
