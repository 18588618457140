const eventTypes = {
  address: 'addressVerification',
  identity: 'identityVerification',
  bank: 'bankVerification',
  twofaConfirmApp: 'twofaAppVerification',
  twofaInitBackupCodes: 'twofaCodesVerification',
  resetTwofa: 'resetTwofa',
  credit: 'credit',
}

export default eventTypes
