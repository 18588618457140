import styled from 'styled-components/macro'
import { device } from 'constants/media'
import { colors } from 'constants/colors'
import imgHistory from 'assets/images/history.png'
import imgCredit from 'assets/images/credit.png'
import imgBorrow from 'assets/images/borrow.svg'
import imgDeposit from 'assets/images/deposit.svg'

const imageChooser = (variant) => {
  switch (variant) {
    case 'history':
      return imgHistory
    case 'credit':
      return imgCredit
    case 'deposit':
      return imgDeposit
    case 'borrow':
      return imgBorrow

    default:
      return ''
  }
}

export const NotesBlock = styled.div`
  height: fit-content;
  width: 360px;
  margin-bottom: 16px;
`
export const NotesTitle = styled.div`
  margin-bottom: 24px;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: ${colors.gray950};

  @media ${device.mobile} {
    margin-bottom: 2px;
    font-size: 14px;
    line-height: 20px;
  }
`

export const NotesText = styled.div`
  font-weight: 400;
  font-style: normal;
  font-size: 14px;
  line-height: 20px;
  color: ${colors.gray950};

  @media ${device.mobile} {
    font-size: 14px;
    line-height: 20px;
  }
`

export const Image = styled.div`
  margin: 32px 0 32px auto;
  width: 360px;
  height: 360px;
  position: relative;
  background-image: url(${(p) => imageChooser(p.img)});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;

  @media ${device.mobile} {
    margin: 48px auto;
    width: 100%;
    height: 373px;
  }
`
