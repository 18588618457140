import React from 'react'
import { usePopperTooltip } from 'react-popper-tooltip'
import 'react-popper-tooltip/dist/styles.css'

import { colors } from 'constants/colors'

import { Link } from './style'
import { TooltipContainer } from 'ui-kit/components/Info/style'

const MailLink = ({ mailto, tooltipText }) => {
  const { getArrowProps, getTooltipProps, setTooltipRef, setTriggerRef, visible } = usePopperTooltip()
  const newMessage = null

  const MessageIcon = ({ badgeColor }) => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="6.5" cy="8.25" r="1" fill="#5E666E" />
      <circle cx="10" cy="8.25" r="1" fill="#5E666E" />
      <circle cx="13.5" cy="8.25" r="1" fill="#5E666E" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.6501 7.72096V12.6663C18.6501 13.3102 18.3943 13.9277 17.939 14.383C17.4837 14.8383 16.8662 15.0941 16.2223 15.0941H6.38408C6.02604 15.0941 5.68266 15.2363 5.42949 15.4895L2.45972 18.4592C2.27382 18.6451 1.99424 18.7007 1.75135 18.6001C1.50847 18.4995 1.3501 18.2625 1.3501 17.9996V3.77739C1.3501 3.1335 1.60588 2.51599 2.06118 2.06069C2.51647 1.60539 3.13399 1.34961 3.77788 1.34961H12.2787C12.135 1.76106 12.0435 2.19705 12.0122 2.64961H3.77788C3.47877 2.64961 3.19192 2.76843 2.98042 2.97993C2.76892 3.19143 2.6501 3.47828 2.6501 3.77739V16.4304L4.51025 14.5702C5.00722 14.0732 5.68126 13.7941 6.38408 13.7941H16.2223C16.5214 13.7941 16.8083 13.6752 17.0198 13.4637C17.2313 13.2522 17.3501 12.9654 17.3501 12.6663V7.98755C17.8027 7.95626 18.2386 7.86474 18.6501 7.72096Z"
        fill="#5E666E"
      />
      <circle cx="17" cy="3" r="3" fill={badgeColor} />
    </svg>
  )

  return (
    <Link
      to="#"
      onClick={(e) => {
        window.location.href = mailto
        e.preventDefault()
      }}
      ref={setTriggerRef}
    >
      <MessageIcon badgeColor={newMessage ? colors.red400 : colors.gray500} />
      {visible && (
        <TooltipContainer ref={setTooltipRef} {...getTooltipProps({ className: 'tooltip-container' })}>
          <div {...getArrowProps({ className: 'tooltip-arrow' })} />
          {tooltipText}
        </TooltipContainer>
      )}
    </Link>
  )
}

export default MailLink
