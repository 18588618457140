import { useEffect, useState } from 'react'

export function useDevice() {
  const [isMobile, setIsMobile] = useState(false)

  const getDevice = () => {
    if (window.innerWidth <= '767') {
      setIsMobile(true)
    } else {
      setIsMobile(false)
    }
  }

  useEffect(() => {
    getDevice()
    window.addEventListener('resize', getDevice)
    return () => {
      window.removeEventListener('resize', getDevice)
    }
  }, [window.innerWidth])

  return isMobile
}
