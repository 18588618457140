import React, { Fragment } from 'react'
import Button from 'ui-kit/components/Button'

import { NavigationWrapper, NavigationContent, NavigationBack, NavigationDots, NavigationDotItem } from './style'

const Navigation = ({ onClickForward, onClickBack, buttonDisabled, isLoading, noButton, dots, forwardText }) => {
  return (
    <NavigationWrapper>
      <NavigationContent>
        <NavigationBack onClick={onClickBack} />
        {dots && (
          <NavigationDots>
            {dots.map((dot, index) => (
              // NOTE: dot can be undefined if steps array has empty object.
              // And object can be empty if its appearance is conditional, for example on mobile
              <Fragment key={index}>{dot !== undefined && <NavigationDotItem active={dot} />}</Fragment>
            ))}
          </NavigationDots>
        )}
        {!noButton && (
          <Button
            variant="filled"
            text={forwardText}
            disabled={buttonDisabled}
            onClick={onClickForward}
            isLoading={isLoading}
          />
        )}
      </NavigationContent>
    </NavigationWrapper>
  )
}

export default Navigation
