import styled from 'styled-components/macro'
import { colors } from 'constants/colors'
import { device } from 'constants/media'
import redBg from 'assets/images/auth-img-red.png'
import blueBg from 'assets/images/auth-img-blue.png'
import greenBg from 'assets/images/auth-img-green.png'
import yellowBg from 'assets/images/auth-img-yellow.png'
import purpleBg from 'assets/images/auth-img-purple.png'

export const AuthPageWrap = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  overflow-x: hidden;

  @media ${device.mobile} {
    flex-direction: column;
  }
`

const bgChooser = (color) => {
  switch (color) {
    case 'red':
      return redBg
    case 'blue':
      return blueBg
    case 'green':
      return greenBg
    case 'yellow':
      return yellowBg
    case 'purple':
      return purpleBg
    default:
      return blueBg
  }
}

export const AuthBgImage = styled.div`
  width: 100%;
  height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  background-image: url(${(p) => bgChooser(p.color)});

  @media ${device.mobile} {
    width: 100%;
    height: 200px;
  }
`

export const AuthTitle = styled.div`
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: ${colors.gray950};
  margin-bottom: 16px;
`

export const AuthContent = styled.div`
  padding: 32px;
  min-width: 424px;

  @media ${device.mobile} {
    width: 100%;
    min-width: 100%;
    padding: 16px;
  }
`

export const AuthHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 0 32px;
`

export const AuthHeaderMobile = styled.div`
  margin: 12px 16px;
`

export const AuthHeaderLink = styled.a`
  margin: 0;
  font-weight: 500;
  font-size: 20px;
  line-height: 32px;
  color: ${colors.gray950};
`
