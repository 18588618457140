import React from 'react'

import { useDevice } from 'hooks/media'
import { useLang } from 'hooks/lang'

import { TitleGrey, TitleBlack } from 'layouts/SubLayout/style'
import { Image, PageWrapper, Text, TextBlock } from './style'

const ServiceUnavailablePage = () => {
  const isMobile = useDevice()
  const { translate: t } = useLang()

  return (
    <PageWrapper>
      {!isMobile && <TitleGrey>{t('UNAVAILABLE_PAGE.TITLE_GREY')}</TitleGrey>}
      <TitleBlack>{t('UNAVAILABLE_PAGE.TITLE_BLACK')}</TitleBlack>
      <TextBlock>
        <Text>{t('UNAVAILABLE_PAGE.TEXT')}</Text>
      </TextBlock>
      <Image img="history" />
    </PageWrapper>
  )
}

export default ServiceUnavailablePage
