import styled from 'styled-components/macro'
import { colors } from 'constants/colors'
import copyIcon from './assets/copy.svg'
import clearIcon from './assets/clear.svg'

export const InputWrap = styled.div`
  position: relative;
  background: ${(p) => (p.error ? colors.red50 : colors.gray50)};
  border: 1px solid ${(p) => (p.error ? colors.red500 : colors.gray150)};
  border-radius: 8px;
  padding: 8px 12px;
`

export const Label = styled.div`
  font-size: 10px;
  text-transform: uppercase;
  color: ${(p) => (p.disabled ? colors.gray300 : colors.gray500)};
  font-weight: 500;
  padding-bottom: 4px;
`

export const InputEl = styled.input`
  width: 100%;
  font-size: 14px;
  color: ${(p) => (p.disabled ? colors.gray300 : colors.gray950)};
  font-weight: 400;
  background: ${(p) => (p.error ? colors.red50 : colors.gray50)};
  border: none;
  padding: 0;
  padding-right: 25px;

  &::placeholder {
    color: ${(p) => (p.disabled ? colors.gray300 : colors.gray400)};
  }
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &:focus {
    outline: none;
  }
  &::-ms-clear {
    display: none;
  }
  &[type='number'] {
    -moz-appearance: textfield;
  }
`

export const FancyInput = styled.input`
  width: 100%;
  font-size: 14px;
  color: ${colors.gray950};
  font-weight: 400;
  background: ${colors.gray50};
  border: none;
  padding: 0;
  padding-right: 25px;

  &::placeholder {
    color: ${colors.gray400};
  }
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &:focus {
    outline: none;
  }
  &::-ms-clear {
    display: none;
  }
  &[type='number'] {
    -moz-appearance: textfield;
  }
`

export const FancyInputWithError = styled.input`
  width: 100%;
  font-size: 14px;
  color: ${colors.red700};
  font-weight: 400;
  background: ${colors.red50};
  border: none;
  padding: 0;
  padding-right: 25px;

  &::placeholder {
    color: ${colors.gray400};
  }
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &:focus {
    outline: none;
  }
  &::-ms-clear {
    display: none;
  }
  &[type='number'] {
    -moz-appearance: textfield;
  }
`

export const InputBox = styled.div`
  position: relative;
`

const InputIcon = styled.div`
  background-image: url(${copyIcon});
  background-repeat: no-repeat;
  background-position: 100% 50%;
  cursor: pointer;
  width: 20px;
  height: 20px;
  position: absolute;
  right: 0;
`

export const InputCopy = styled(InputIcon)`
  background-image: url(${copyIcon});
  top: 0;
`

export const InputClear = styled(InputIcon)`
  background-image: url(${clearIcon});
  top: -8px;
`
