import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { useProfileContext } from 'context/profile'
import { useDevice } from 'hooks/media'
import { useLang } from 'hooks/lang'

import { getUserBankAccount } from 'api/bankData'

import SubLayout from 'layouts/SubLayout'
import { Row, Col } from 'ui-kit/components/Grid'
import InfoBlock from 'ui-kit/components/InfoBlock'
import ContentMobile from 'components/ContentMobile'

import { errorCapture } from 'utils/errorCapture'

import { PageTitle, ListWrapper, ButtonWrapper } from './styles'

import routes from 'constants/routes'
import { colors } from 'constants/colors'
import MainLayout from 'layouts/MainLayout'
import Button from 'ui-kit/components/Button'

const Bank = () => {
  const isMobile = useDevice()
  const navigate = useNavigate()
  const { uid } = useProfileContext()
  const { translate: t } = useLang()

  const [isLoading, setLoading] = useState(false)
  const [bankAccounts, setBankAccounts] = useState([])

  useEffect(() => {
    getUserBankAccount(uid)
      .then((res) => {
        if (res.data) setBankAccounts(res.data)
        setLoading(true)
      })
      .catch((error) => errorCapture(error))
  }, [])

  const prepareAccountInfo = (info) => {
    return {
      title: info.bank,
      subtitle: `IBAN ${info.iban}`,
      status: null,
      icon: null,
      buttonText: null,
      buttonIcon: null,
      route: null,
      disabled: false,
    }
  }

  const PageContent = () => {
    return (
      <>
        <PageTitle>{t('ACCOUNT.BANK.TITLE')}</PageTitle>
        <ListWrapper>
          {bankAccounts.length > 0 &&
            bankAccounts.map((account) => (
              <InfoBlock
                key={account.iban}
                mode="row"
                infoData={prepareAccountInfo(account)}
                border={colors.gray150}
                marginBottom={!isMobile ? '8px' : '12px'}
                alignItems="center"
                boxShadow={isMobile}
                iconBgColor={isMobile && 'transparent'}
                borderMiddle={isMobile && colors.gray100}
                padding="16px 16px 16px 4px"
                cursor="pointer"
                handlerType="delete"
                noInfoIcon
              />
            ))}
        </ListWrapper>
      </>
    )
  }

  const GenerateBackupCodeButton = () => (
    <ButtonWrapper isMobile={isMobile}>
      <Button
        text={t('ACCOUNT.BANK.BUTTON')}
        width={isMobile ? '100%' : 'fit-content'}
        bgColor={colors.gray700}
        textColor={colors.white}
        icon="plus-white"
        padding="6px 16px"
        onClick={() => navigate(routes.bankStep)}
      />
    </ButtonWrapper>
  )

  const MobilePage = () => (
    <>
      <SubLayout
        isLoading={!isLoading}
        hiddenNavigation
        customRoute={routes.profile}
        customBackToText={t('ACCOUNT.BACK_TO_ACCOUNT')}
        bgColor={colors.gray100}
        mobileBackButtonIcon="arrow"
      >
        <ContentMobile>
          <Row justifyContent={isMobile ? 'center' : 'start'} m={'-8'}>
            <Col lg={50} sm={100} p={8}>
              <PageContent />
            </Col>
          </Row>
        </ContentMobile>
      </SubLayout>
      <GenerateBackupCodeButton />
    </>
  )

  const DesktopPage = () => (
    <MainLayout activeNav="profile">
      <SubLayout
        isLoading={!isLoading}
        hiddenNavigation
        customRoute={routes.profile}
        customBackToText={t('ACCOUNT.BACK_TO_ACCOUNT')}
        bgColor={colors.gray100}
        mobileBackButtonIcon="arrow"
      >
        <PageContent />
        <GenerateBackupCodeButton />
      </SubLayout>
    </MainLayout>
  )

  return <>{isMobile ? <MobilePage /> : <DesktopPage />}</>
}

export default Bank
