import React, { useState, useRef, useEffect } from 'react'
import _ from 'lodash'
import Button from 'ui-kit/components/Button'
import Checkbox from 'ui-kit/components/Checkbox'
import { Row, Col } from 'ui-kit/components/Grid'

import { useLang } from 'hooks/lang'
import { useDevice } from 'hooks/media'

import eventTypes from 'constants/eventTypes'
import { getEventNameByKey } from 'utils/getEventNameByKey'

import {
  FilterWrap,
  FilterBlock,
  FilterDropdown,
  FilterDropdownViewport,
  FilterDropdownTitle,
  FilterDropdownSectionTitle,
  FilterDropdownSection,
  FilterDropdownCheckbox,
  FilterBar,
  FilterBarContent,
  FilterCloseIcon,
} from './style'

const Filter = ({ onSelectCheckbox, selected, onApplyFilter, resetFilter, setOrder }) => {
  const { translate: t } = useLang()
  const isMobile = useDevice()
  const [filterIsVisible, setFilterVisible] = useState(false)
  const ref = useRef(null)

  const filterList = _.values(eventTypes).map((type) => ({
    type,
    name: getEventNameByKey(type),
  }))

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setFilterVisible(false)
      }
    }
    document.addEventListener('click', handleClickOutside, true)
    return () => {
      document.removeEventListener('click', handleClickOutside, true)
    }
  }, [])

  const onShowFilter = () => {
    setFilterVisible(true)
  }

  const onClickApply = () => {
    setFilterVisible(false)
    onApplyFilter()
  }

  return (
    <FilterWrap>
      <Row justifyContent="space-between">
        <Col lg={83} sm={86}>
          <Row m={-5}>
            <Col p={5}>
              <FilterBlock border="dashed" iconPosition="left" icon="plus" noText={isMobile} onClick={onShowFilter}>
                {!isMobile && 'Filter'}
              </FilterBlock>
            </Col>
            {filterList
              .filter((f) => selected.includes(f.type))
              .map((event, index) => (
                <Col p={5} key={index}>
                  <FilterBlock
                    border="solid"
                    iconPosition="right"
                    icon="cross"
                    onClick={() => {
                      onSelectCheckbox(event.type)
                      if (isMobile) onApplyFilter()
                    }}
                  >
                    {t(`EVENTS.${event.name}`)}
                  </FilterBlock>
                </Col>
              ))}
          </Row>
        </Col>
        <Col lg={17} sm={14}>
          <FilterBlock border="solid" iconPosition="right" icon="order" noText={isMobile} onClick={setOrder}>
            {!isMobile && 'Order by'}
          </FilterBlock>
        </Col>
      </Row>
      {filterIsVisible && (
        <FilterDropdown ref={ref}>
          <FilterDropdownViewport>
            <FilterDropdownTitle>
              {isMobile && <FilterCloseIcon onClick={() => setFilterVisible(false)} />}
              {t('FILTER.DROPDOWN_TITLE')}
            </FilterDropdownTitle>
            <FilterDropdownSectionTitle>{t('FILTER.DROPDOWN_SECTION_TITLE_1')}</FilterDropdownSectionTitle>
            <FilterDropdownSection>
              {filterList.map((filter) => (
                <FilterDropdownCheckbox key={filter.type} checked={filter.checked}>
                  <Checkbox
                    checked={selected.includes(filter.type)}
                    label={t(`EVENTS.${filter.name}`)}
                    onChange={() => onSelectCheckbox(filter.type)}
                  />
                </FilterDropdownCheckbox>
              ))}
            </FilterDropdownSection>
          </FilterDropdownViewport>
          {isMobile && (
            <FilterBar>
              <FilterBarContent>
                <Row justifyContent="space-between">
                  <Col>
                    <Button variant="outlined" text={t('FILTER.RESET_BUTTON')} onClick={resetFilter} fontSize="16px" />
                  </Col>
                  <Col>
                    <Button variant="filled" text={t('FILTER.APPLY_BUTTON')} onClick={onClickApply} fontSize="16px" />
                  </Col>
                </Row>
              </FilterBarContent>
            </FilterBar>
          )}
        </FilterDropdown>
      )}
    </FilterWrap>
  )
}

export default Filter
