import styled from 'styled-components/macro'
import { colors } from 'constants/colors'

export const NumericListWrapper = styled.div`
  margin: 0 0 24px;
`
export const NumericListTitle = styled.div`
  margin-bottom: 12px;
  color: ${colors.gray800};
`

export const NumericListOl = styled.ol`
  list-style: none;
  counter-reset: my-counter;
  padding: 0;
`

export const NumericListLi = styled.li`
  counter-increment: my-counter;
  margin: 8px 0;
  padding-left: 28px;
  position: relative;

  &:before {
    content: counter(my-counter);
    border-radius: 50%;
    font-weight: 500;
    font-size: 10px;
    text-transform: uppercase;
    color: ${colors.gray800};
    background-color: ${colors.gray50};
    border: 1px solid ${colors.gray150};
    position: absolute;
    left: 0;
    top: -1px;
    width: 20px;
    text-align: center;
    padding: 3px 0;
  }
`
